import { AxiosError } from 'axios'
import { action } from 'store/store.utils'
import {
  SET_PRODUCT_CALENDAR_ERROR,
  SET_PRODUCT_CALENDAR_REQUEST,
  SET_PRODUCT_CALENDAR_SUCCESS,
  GET_PRODUCT_CALENDAR_ERROR,
  GET_PRODUCT_CALENDAR_REQUEST,
  GET_PRODUCT_CALENDAR_SUCCESS,
  DELETE_PRODUCT_CALENDAR_REQUEST,
  DELETE_PRODUCT_CALENDAR_ERROR,
  DELETE_PRODUCT_CALENDAR_SUCCESS
} from './SettingsTimeOfRequest.constants'

export const getProductCalendarRequest = () => action(GET_PRODUCT_CALENDAR_REQUEST)
export const getProductCalendarSuccess = (payload: []) =>
  action(GET_PRODUCT_CALENDAR_SUCCESS, payload)
export const getProductCalendarError = (payload: AxiosError) =>
  action(GET_PRODUCT_CALENDAR_ERROR, payload)

export const setProductCalendarRequest = () => action(SET_PRODUCT_CALENDAR_REQUEST)
export const setProductCalendarSuccess = (payload: []) =>
  action(SET_PRODUCT_CALENDAR_SUCCESS, payload)
export const setProductCalendarError = (payload: AxiosError) =>
  action(SET_PRODUCT_CALENDAR_ERROR, payload)

export const deleteProductCalendarRequest = () => action(DELETE_PRODUCT_CALENDAR_REQUEST)
export const deleteProductCalendarSuccess = (id: number) =>
  action(DELETE_PRODUCT_CALENDAR_SUCCESS, { id })
export type TDeleteProductCalendarSuccess = ReturnType<typeof deleteProductCalendarSuccess>

export const deleteProductCalendarError = (payload: AxiosError) =>
  action(DELETE_PRODUCT_CALENDAR_ERROR, payload)
