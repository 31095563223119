import React from 'react'
import { Column } from 'react-table'
import { TUserData } from './Users.model'
import { TableSortHeader, Options, CellRoles } from 'components/UiKit/Table'
import { BalanceCell } from '../../../components/UiKit/Table/components/BalanceCell'
import { TWords } from 'elements/SideBar/SideBar.config'

type TGetColumnsOptions = {
  onPointsEdit?: (id: number) => void
  onEdit?: (id: number) => void
  onTimeEdit?: (id: number) => void
  onRemove?: (id: number) => void
  onRestore?: (id: number) => void
}
type TGetColumns = (words: TWords, options: TGetColumnsOptions) => Array<Column<TUserData>>
export const getColumns: TGetColumns = (
  words,
  { onPointsEdit, onEdit, onTimeEdit, onRemove, onRestore }
) => [
  {
    Header: ({ column }) => <TableSortHeader id={column.id}>ID</TableSortHeader>,
    accessor: 'id',
    width: 50
  },
  {
    Header: ({ column }) => <TableSortHeader id={column.id}>{words['roles.user']}</TableSortHeader>,
    accessor: 'fullName',
    width: 200
  },
  {
    Header: words['admin.users.table.Edit.roles'],
    id: 'roles',
    accessor: ({ roles }) => <CellRoles roles={roles} />
  },
  {
    Header: words['admin.users.table.email'],
    accessor: 'email',
    width: 300
  },
  {
    Header: words['admin.users.table.Edit.position'],
    accessor: 'position'
  },
  // todo- needed to select { Header: 'Должность', id: 'userPosition', accessor: ({ userPosition }) => userPosition && userPosition.name},
  {
    Header: ({ column }) => (
      <TableSortHeader id={column.id}>{words['admin.users.table.balance']}</TableSortHeader>
    ),
    accessor: 'balance',
    Cell: ({ row }) => {
      return <BalanceCell content={row.original.balance} />
    },
    width: 80
  },
  {
    id: 'actions',
    accessor: ({ id }) => (
      <Options
        dotted={!!(onEdit || onRemove || onTimeEdit)}
        onPointsEdit={onPointsEdit && (() => onPointsEdit(id))}
        onEdit={onEdit && (() => onEdit(id))}
        onTimeEdit={onTimeEdit && (() => onTimeEdit(id))}
        onRemove={onRemove && (() => onRemove(id))}
        onRestore={onRestore && (() => onRestore(id))}
      />
    ),
    width: 50
  }
]
