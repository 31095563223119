import { connect } from 'react-redux'
import { Information } from './Information'
import { bindActionCreators, Dispatch } from 'redux'
import {
  fetchInformation,
  deleteInformation,
  createInformation,
  updateInformation
} from './Information.thunx'
import { withRouter } from 'react-router'
import { TState } from 'store'

export const mapStateToProps = (state: TState) => ({
  // data: state.client.faq
  data: state.client.info
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { fetchInformation, deleteInformation, createInformation, updateInformation },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Information))
