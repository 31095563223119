import { TFieldConfigRow } from 'components/UiKit/FieldFactory/FieldFactory.model'
import { TWords } from 'elements/SideBar/SideBar.config'
import styles from './EditProfile.module.scss'

const profileFieldsConfig = (words: TWords, options: any): TFieldConfigRow[] => {
  const { positions, rank } = options
  const row: TFieldConfigRow = {
    items: []
  }

  if (positions)
    row.items.push({
      name: 'position',
      label: words['user.profile.career.position'],
      required: true,
      inputWrapperClassName: styles['description-position'],
      component: () => {
        return {
          type: 'select',
          props: {
            placeholder: '',
            options: positions
          }
        }
      }
    })

  if (rank)
    row.items.push({
      name: 'rank',
      label: words['user.profile.career.level'],
      required: true,
      inputWrapperClassName: styles['description-level'],
      component: () => {
        return {
          type: 'select',
          props: {
            placeholder: '',
            options: rank
          }
        }
      }
    })

  return [row]
}

export default profileFieldsConfig
