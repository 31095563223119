import { IAction } from 'models/index'
import { AxiosError } from 'axios'
import {
  DELETE_GOOD_REQUEST,
  DELETE_GOOD_ERROR,
  RESTORE_GOOD_ERROR,
  RESTORE_GOOD_REQUEST
} from './Shop.constants'

export type TStoreGoodsState = {
  data: any[]
  loading: boolean
  error: string | AxiosError
}

export const initialState: TStoreGoodsState = {
  data: [],
  error: '',
  loading: false
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case DELETE_GOOD_REQUEST:
    case RESTORE_GOOD_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case RESTORE_GOOD_ERROR:
    case DELETE_GOOD_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    }

    default:
      return state
  }
}
