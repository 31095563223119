import React, { FC, useMemo } from 'react'
import styles from './Basket.module.scss'
import { Options, Table } from 'components/UiKit/Table'
import { Icons } from '../../../components/Icons'
import { ICartItem, TBasketComponentProps, TDetails } from './Basket.model'
import { Counter } from 'components/Counter/counter'
import { BasketName } from './components/BasketName/BasketName'
import { Checkout } from './components/Checkout/Checkout'
import { Column } from 'react-table'
import { ERowSize } from 'globalConfigs'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export const BasketComponent: FC<TBasketComponentProps> = ({
  data,
  addToCart,
  removeFromCart,
  totalQuantity,
  totalPrice,
  createRequestPurchaseOfGoods,
  history,
  balance,
  openThankForPurchase,
  setOpenThankForPurchase
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const columns: Array<Column<ICartItem>> = [
    {
      Header: words['user.profile.table.goodsService'],
      id: 'title',
      accessor: d => <BasketName photo={d.photo} name={d.name} category={d.category} />,
      minWidth: 300
    },
    {
      Header: words['user.profile.table.amount'],
      id: 'quantity',
      accessor: d => (
        <Counter
          addToCart={addToCart}
          removeFromCart={removeFromCart}
          element={d}
          name={'basket'}
        />
      ),
      width: 100
    },
    {
      Header: words['user.profile.table.price'],
      id: 'cost',
      accessor: d => <> {d.total} </>,
      width: 100
    },
    {
      Header: '',
      id: 'actions',
      accessor: d => <Options dotted={false} onRemove={() => removeFromCart(d, 'all')} />,
      maxWidth: 150
    }
  ]

  const columnsMemoized = useMemo(() => columns, [])

  const goodsData: TDetails[] = useMemo(
    () =>
      data.map(item => ({
        amount: Number(item.quantity),
        goodsId: Number(item.id)
      })),
    [data]
  )

  return (
    <div className={styles.container}>
      <section>
        <div className={styles.header}>
          <h1>{words['user.basket.yourBasket']}</h1>
        </div>
      </section>
      <section>
        <div className={styles['block-go-back']}>
          <button
            type="button"
            className={styles['go-back']}
            onClick={event => {
              event.preventDefault()
              history.goBack()
            }}
          >
            <Icons icon="backArrowIcon" />
            <span className={styles['go-back-text']}>{words['back']}</span>
          </button>
        </div>
      </section>
      <div className={styles['container-flex']}>
        <Table
          columns={columnsMemoized}
          data={data}
          total={totalQuantity}
          getData={() => ({})}
          noData={words['noData']}
          rowSize={ERowSize.MIDDLE}
        />
        <Checkout
          totalQuantity={totalQuantity}
          totalPrice={totalPrice}
          goodsData={goodsData}
          onPurchaseOfGoods={createRequestPurchaseOfGoods}
          emptyTrash={removeFromCart}
          balance={balance}
          openThankForPurchase={openThankForPurchase}
          setOpenThankForPurchase={setOpenThankForPurchase}
        />
      </div>
    </div>
  )
}
