import { connect } from 'react-redux'
import SideBar from './SideBar'
import { bindActionCreators, Dispatch } from 'redux'

export const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(SideBar)
