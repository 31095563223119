import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { GoodsItemDetails as GoodsItemDetailsComponent } from './GoodsItemDetails'
import { TState } from 'store'
import { createReview, getGood } from './GoodsItemDetails.thunx'
import { addToCart } from 'screens/client/Basket/Basket.actions'
import { removeReview } from '../../admin/GoodsItem/GoodsItem.thunx'
import { clearGoodData } from './GoodsItemDetails.actions'

export const mapStateToProps = (state: TState) => ({
  good: state.client.good.data
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getGood,
      addToCart,
      createReview,
      removeReview,
      clearGoodData
    },
    dispatch
  )

export const GoodsItemDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(GoodsItemDetailsComponent)
