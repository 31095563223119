import { IAction } from 'models'

export const IS_OPEN_FILTER: string = 'IS_OPEN_FILTER'
export const IS_DISPLAY_ALL_FILTER_FIELDS = 'IS_DISPLAY_ALL_FILTER_FIELDS'

export type TInitialState = {
  openFilter: boolean
  multilineFilterFields: {
    componentName: string
    status: boolean
  }
}
export const initialState: TInitialState = {
  openFilter: false,
  multilineFilterFields: {
    componentName: '',
    status: false
  }
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case IS_OPEN_FILTER:
      return {
        ...state,
        openFilter: action.payload
      }
    case IS_DISPLAY_ALL_FILTER_FIELDS:
      return {
        ...state,
        multilineFilterFields: action.payload
      }
    default:
      return state
  }
}
