import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import queryString from 'query-string'
import { HistoryTransaction } from 'screens/client/Profile/components/HistoryTransaction'
import styles from './PointsSystem.module.scss'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
import { ETransactionHistory } from 'globalConfigs'
import { getPSType } from 'utils/toggleBarsValue'

type PointsSystemProps = {
  transactionToggleBarOptions: IToggleBarData[]
  id: string
  balance: number
  history: { push: any; location: { search: string; pathname: string } }
}

export const PointsSystem: FC<PointsSystemProps> = ({
  transactionToggleBarOptions,
  id,
  balance,
  history
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const parsed = queryString.parse(history.location.search)
  const [selectedTransactionType, setSelectedType] = useState<IToggleBarData>(
    parsed.type === ETransactionHistory.GOODS
      ? transactionToggleBarOptions[0]
      : parsed.type === ETransactionHistory.ACHIEVEMENTS
      ? transactionToggleBarOptions[1]
      : transactionToggleBarOptions[2]
  )

  return (
    <div>
      <span className={styles['mini-title']}>{words['admin.settings.transactionStory']}</span>
      <div className={styles.toggle}>
        <ToggleBar
          name="active-achievements-no-active"
          data={transactionToggleBarOptions}
          defaultChecked={selectedTransactionType}
          style={2}
          onChange={event => {
            history.push(
              `${history.location.pathname}?${queryString.stringify({
                type: getPSType(event.value),
                profileType: parsed.profileType,
                userId: id
              })}`
            )
            setSelectedType(event)
          }}
        />

        <div className={styles['points-balance']}>
          {words['user.header.balance']}: <span>{balance}</span>
        </div>
      </div>
      <div>
        <HistoryTransaction />
      </div>
    </div>
  )
}
