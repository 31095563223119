import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import styles from './AchievementsItemDetails.module.scss'
import { Icons } from '../../../components/Icons'
import { Button } from 'components/UiKit/Button'
import { ReactComponent as Logo } from 'screens/client/Goods/Product/point_icon.svg'
import { TAchievementsItemDetails } from './AchievementsItemDetails.model'
import { RequestForAchievementModal } from '../Achievements/components/RequestForAchievementModal'

export const AchievementsItemDetails: FC<TAchievementsItemDetails> = ({
  achievement,
  getCurrentAchievement,
  createRequestAchievement,
  match,
  history
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [openRequestForAchievement, setRequestForAchievement] = useState<boolean>(false)
  const toggleRequestForAchievement = () => setRequestForAchievement(!openRequestForAchievement)

  useEffect(() => {
    const { id } = match.params
    getCurrentAchievement(id)
  }, [])

  const handleClick = (event: any) => {
    event.preventDefault()
    toggleRequestForAchievement()
  }

  return (
    <>
      <div className={styles.container}>
        <section>
          <div className={styles.header}>
            <h1>{words['user.pointsSystem.market.table.goodsPageName']}</h1>
          </div>
        </section>
        <section>
          <div className={styles['block-go-back']}>
            <button
              type="button"
              className={styles['go-back']}
              onClick={event => {
                event.preventDefault()
                history.goBack()
              }}
            >
              <Icons icon="backArrowIcon" />
              <span className={styles['go-back-text']}>{words['back']}</span>
            </button>
          </div>
        </section>
        <section className={styles.details}>
          <div className={styles['details-image']}>
            <div className={styles.price}>
              <span>{achievement && achievement.price}</span>
              <i className={styles.logo}>
                <Logo />
              </i>
            </div>
            <img src={achievement && achievement.photo} alt="achievement_photo" />
          </div>
          <div className={styles['details-description']}>
            <h3>{achievement && achievement.name}</h3>

            <div className={styles['details-description-item']}>
              <span>{`${words['user.pointsSystem.market.table.category']}:`} </span>
              <div className={styles['details-description-text']}>
                {achievement && achievement.categoryId && achievement.categoryId.name}
              </div>
            </div>

            <div className={styles['details-description-item']}>
              <span>{`${words['admin.productionCalendar.new.description']}:`}</span>
              <br />
              <div className={styles['details-description-text']}>
                {achievement && achievement.description}
              </div>
            </div>

            <div className={styles['details-btn-container']}>
              <Button type="button" className={styles['basket-btn']} onClick={handleClick}>
                {words['user.pointsSystem.initiatives.table.apply']}
              </Button>
            </div>
          </div>
        </section>
      </div>
      <RequestForAchievementModal
        open={openRequestForAchievement}
        toggle={toggleRequestForAchievement}
        id={achievement.id}
        createRequest={createRequestAchievement}
      />
    </>
  )
}
