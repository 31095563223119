import {
  GET_USER_RATING_REQUEST,
  GET_USER_RATING_SUCCESS,
  GET_USER_RATING_ERROR,
  CLEAR_USERS_RATING
} from './UserRating.constants'
import { IInitialState } from './UserRating.model'
import { IAction } from 'models'

export const initialState: IInitialState = {
  results: [],
  total: 0,
  loading: false
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_USER_RATING_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_USER_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
        results: [...state.results, ...action.payload.results],
        total: action.payload.total
      }

    case GET_USER_RATING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case CLEAR_USERS_RATING:
      return {
        ...initialState
      }

    default:
      return state
  }
}
