import { messages } from './validationMessages'

type unstring = string | null
type strNum = string | number

interface IRules {
  email: RegExp
  personName: RegExp
  password: RegExp
  isPhoneNumber: RegExp
}

class Validation {
  public rules: IRules = {
    email: /^\w+([\._%+-]?\w+)*@(technorely|sheerchain)\.com$/i,
    personName: /^[a-z'-]{3,32}$/i,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
    isPhoneNumber: /^(\+38\d{3}\d{3}\d{2}\d{2})?$/
  }

  public required = (errMessage?: string) => (value: strNum): unstring => {
    if (typeof value === 'string') value = value.trim()
    return value ? null : errMessage ? errMessage : messages.required
  }

  public isNumber = (errMessage?: string) => (value: number): unstring => {
    return !isNaN(value) ? null : errMessage ? errMessage : messages.isNumber
  }
  public isInteger = (errMessage?: string) => (value: number): unstring => {
    return !value.toString().match(/\D/gi) ? null : errMessage ? errMessage : messages.isNumber
  }

  public isEmail = (errMessage?: string) => (value: string): unstring =>
    this.rules.email.test(value) ? null : errMessage ? errMessage : messages.email

  public isPassword = (errMessage?: string) => (value: string): unstring =>
    this.rules.password.test(value) ? null : errMessage ? errMessage : messages.password

  public isPasswordConfirm = (errMessage?: string) => (value: string, values: any): unstring => {
    const isValue = this.rules.password.test(value)

    if (!isValue) {
      // Optional
      return messages.password
    }

    if (isValue && values.password !== value) {
      return errMessage ? errMessage : messages.confirmPassword
    }

    return null
  }

  public minValue = (min: number) => (value: string): unstring =>
    value.length >= min ? null : `${messages.minText} ${min}`

  public maxValue = (max: number) => (value: string): unstring =>
    value.length <= max ? null : `${messages.maxText} ${max}`

  public min = (min: number) => (value: string | number): unstring =>
    Number(value) >= min ? null : `${messages.minText} ${min}`

  public max = (max: number) => (value: string | number): unstring =>
    Number(value) <= max ? null : `${messages.maxText} ${max}`

  public composeValidators = (...validators: any[]) => (value: any, values: any) =>
    validators.reduce((error, validator) => error || validator(value, values), null)

  public isPhoneNumber = (errMessage?: string) => (value: string): unstring =>
    this.rules.isPhoneNumber.test(value) ? null : errMessage ? errMessage : messages.isPhoneNumber
}

const validation = new Validation()
export default validation
