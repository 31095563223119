import React, { FC } from 'react'
import moment from 'moment'
import styles from './ReviewCard.module.scss'
import { Options } from '../../../UiKit/Table/components/Options'
import { RatingView } from '../RatingView/RatingView'
import { TReviewsItem } from '../../../../screens/admin/Shop/Shop.model'

export type TReviewCardProps = {
  reviewItem: TReviewsItem
  isRemovable?: boolean
  onRemove: (id: number) => void
}

export const ReviewCard: FC<TReviewCardProps> = ({ reviewItem, isRemovable = false, onRemove }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.reviewer}>
          <div className={styles['reviewer-image']}>
            <img src={reviewItem.authorId.photo} alt="" />
          </div>
          <div className={styles['reviewer-name']}>{reviewItem.authorId.fullName}</div>
        </div>
        <div className={styles.date}>{moment(reviewItem.reviewDate).format('DD.MM.YYYY')}</div>
        {isRemovable && (
          <div className={styles.remove}>
            <Options dotted={false} onRemove={() => onRemove(reviewItem.id)} />
          </div>
        )}
      </div>

      <div className={styles.estimate}>
        <RatingView rating={reviewItem.rating} />
      </div>
      {reviewItem.comment && <div className={styles.description}>{reviewItem.comment}</div>}
    </div>
  )
}
