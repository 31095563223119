import React, { FC, useState } from 'react'
import { IOpenBasketPopup } from './OpenBasketPopup.model'
import { Nav, NavItem } from 'reactstrap'
import stylesFromHeader from '../../../../../elements/Header/Header.module.scss'
import { ReactComponent as IconBasket } from '../../../../../assets/images/basket_icon.svg'
import styles from '../../Basket.module.scss'
import { ReactComponent as IconBasketClose } from '../../../../../assets/images/close.svg'
import { NavLink } from 'react-router-dom'
import { BasketElement } from '../BasketElement/BasketElement'
import { Icons } from 'components/Icons'
import cls from 'classnames'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import toastr from '../../../../../utils/toastr'
import { Button } from 'components/UiKit/Button'

export const OpenBasketPopup: FC<IOpenBasketPopup> = ({
  totalQuantity,
  totalPrice,
  basket,
  addToCart,
  removeFromCart,
  balance
}) => {
  const [openBasketPopup, setOpenBasketPopup] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)

  const handleOpenBasketPopup = (event: any) => {
    event.preventDefault()
    setOpenBasketPopup(!openBasketPopup)
    document.body.style.overflowY = 'hidden'
  }

  const handleCloseBasketPopup = (event: any) => {
    event.preventDefault()
    setOpenBasketPopup(false)
    document.body.style.overflowY = 'auto'
  }
  const displayToastr = () => {
    toastr('info', words['NOT_ENOUGH_TCENTS'])
  }
  return (
    <>
      <Nav>
        <NavItem>
          <span onClick={handleOpenBasketPopup}>
            <span className={stylesFromHeader.icon_basket}>
              <IconBasket />
              <span className={stylesFromHeader.counter}>{totalQuantity}</span>
            </span>
          </span>
        </NavItem>
      </Nav>
      {openBasketPopup && (
        <div className={stylesFromHeader.mask} onClick={handleCloseBasketPopup} />
      )}
      <section
        className={cls({
          [styles['basket-hidden']]: !openBasketPopup,
          [stylesFromHeader['basket-container']]: openBasketPopup
        })}
      >
        <header className={stylesFromHeader['basket-header']}>
          <h2>{words['user.basket']}</h2>
          <IconBasketClose className={styles['basket-close']} onClick={handleCloseBasketPopup} />
        </header>
        <div className={stylesFromHeader['basket-content']}>
          <BasketElement basket={basket} addToCart={addToCart} removeFromCart={removeFromCart} />
        </div>
        <div className={stylesFromHeader['basket-footer']}>
          <div className={stylesFromHeader['basket-total-amount']}>
            <p>{words['user.basket.commonSum']}</p>
            <p>
              <span>{totalPrice}</span> <Icons icon="pointIcon" />
            </p>
          </div>
          <div className={stylesFromHeader['basket-btn-container']}>
            {balance && balance < totalPrice ? (
              <Button className={stylesFromHeader['basket-btn-not-enough']} onClick={displayToastr}>
                {words['user.basket.checkout']}
              </Button>
            ) : (
              <NavLink
                className={stylesFromHeader['basket-btn']}
                to="/dashboard/point-system/basket"
              >
                {words['user.basket.checkout']}
              </NavLink>
            )}
          </div>
        </div>
      </section>
    </>
  )
}
