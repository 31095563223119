import React, { FC } from 'react'
import cls from 'classnames'
import styles from './BalanceCell.module.scss'

type TBalanceProps = {
  content: number
}

export const BalanceCell: FC<TBalanceProps> = ({ content }) => {
  return (
    <div
      className={cls({
        [styles.positive]: content >= 0,
        [styles.negative]: content < 0
      })}
    >
      {content}
    </div>
  )
}
