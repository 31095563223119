import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'

import EditProfileComponent from './EditProfile'
import { getBalance } from '../NewRequestTimeOff/NewRequestTimeOff.thunx'
import { updateUser, getSelectOptions } from '../EditProfile/EditProfile.thunx'
import { updateCurrentTimeBalance, updatePointsBalance } from 'screens/admin/Users/Users.thunx'
import { getUserProfileInfo } from '../Profile/Profile.thunx'
import { checkUserProfileSuccess } from '../Profile/Profile.actions'

export const mapStateToProps = (state: TState) => {
  return {
    currentUser: state.auth.data,
    role: state.auth.data.role,
    dataUsers: state.client.newRequestTimeOff.users.results,
    loading: state.client.editProfile.loading,
    error: state.client.editProfile.error,
    positions: state.client.editProfile.positions,
    rank: state.client.editProfile.rank,
    maritalStatus: state.client.editProfile.maritalStatus,
    englishLevel: state.client.editProfile.englishLevel,
    department: state.client.editProfile.department,
    academicDegree: state.client.editProfile.academicDegree,
    balance: state.client.newRequestTimeOff.balance,
    userInfo: state.client.profile.user
  }
}
export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateUser,
      getSelectOptions,
      updateCurrentTimeBalance,
      getBalance,
      updatePointsBalance,
      getUserProfileInfo,
      checkUserProfileSuccess
    },
    dispatch
  )

export const EditProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfileComponent)
