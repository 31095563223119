import React from 'react'
import { Column } from 'react-table'
import { TableSortHeader, Options } from 'components/UiKit/Table'
import styles from './AchievementsAdm.module.scss'
import { TWords } from 'elements/SideBar/SideBar.config'

type TGetColumnsOptions = {
  onEdit?: (id: number) => void
  onRemove?: (id: number) => void
  onRestore?: (id: number) => void
}
type TGetColumns = (options: TGetColumnsOptions, words: TWords) => Array<Column<any>>
export const getColumns: TGetColumns = ({ onEdit, onRemove, onRestore }, words) => [
  {
    Header: ({ column }) => (
      <TableSortHeader id={column.id}>{words['user.profile.table.initiative']}</TableSortHeader>
    ),
    id: 'name',
    accessor: ({ name }) => (
      <div>
        <div className={styles.title}>{name}</div>
      </div>
    ),
    width: 300
  },
  {
    Header: ({ column }) => (
      <TableSortHeader id={column.id}>
        {words['user.pointsSystem.market.table.category']}
      </TableSortHeader>
    ),
    id: 'categoryId',
    accessor: d => d.categoryId.name,
    width: 300
  },
  {
    Header: ({ column }) => (
      <TableSortHeader id={column.id}>{words['user.profile.table.reward']}</TableSortHeader>
    ),
    accessor: 'price',
    width: 300
  },
  {
    id: 'actions',
    accessor: ({ id }) => (
      <Options
        dotted={false}
        onEdit={onEdit && (() => onEdit(id))}
        onRemove={onRemove && (() => onRemove(id))}
        onRestore={onRestore && (() => onRestore(id))}
      />
    ),
    width: 100
  }
]
