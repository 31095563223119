import { IAction } from 'models'
import {
  EDIT_USER_ERROR,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  GET_POSITIONS
} from './EditProfile.constants'

export type IInitialState = {
  error: string
  loading: boolean
}

export const initialState: IInitialState = {
  error: '',
  loading: false
}

export default (state = initialState, action: IAction): any => {
  switch (action.type) {
    case EDIT_USER_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case EDIT_USER_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    case GET_POSITIONS:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
