import { connect } from 'react-redux'
import { UserRatingComponent } from './UserRating'
import { bindActionCreators, Dispatch } from 'redux'
import { clearUsersRating } from './UserRating.actions'
import { TState } from 'store'
import { getUsersRating } from './UserRating.thunx'

export const mapStateToProps = (state: TState) => ({
  rating: state.client.rating.results,
  total: state.client.rating.total
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearUsersRating,
      getUsersRating
    },
    dispatch
  )

export const UserRating = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRatingComponent)
