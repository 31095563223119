import Goods from '../../screens/client/Goods'
// import UserRating from '../../screens/client/UserRating'
import Information from '../../screens/client/Information'
import { UserRating } from '../../screens/client/Profile/components/UserRating'
// import Information from '../../screens/client/Information'
// import Basket from '../../screens/client/Basket'
import { Profile } from '../../screens/client/Profile'
import { NewRequestTimeOff } from '../../screens/client/NewRequestTimeOff'
// import Lottery from '../../screens/client/Lottery'
import { Achievements } from '../../screens/client/Achievements'
import { Users } from 'screens/admin/Users'
import { Settings } from 'screens/admin/Settings'
import TimeOffRequests from '../../screens/client/TimeOffRequests'
import { Basket } from '../../screens/client/Basket'
import { Shop } from 'screens/admin/Shop'
import { GoodsItem } from 'screens/admin/GoodsItem'
import { GoodsItemDetails } from '../../screens/client/GoodsItemDetails'
import { AchievementsAdm } from '../../screens/admin/AchievementsAdm'
import { AchievementItem } from 'screens/admin/AchievementsAdm/components/AchievementItem'
import { Requests } from 'screens/admin/Requests'
import { AchievementsItemDetails } from 'screens/client/AchievementsItemDetails'
import { EditProfile } from '../../screens/client/EditProfile'
import { Team } from '../../screens/client/Team'
import { ForeignProfile } from '../../screens/client/ForeignProfile'
import { TeamAdmin } from '../../screens/admin/Team'

export type TRoutes = typeof adminRoutes | typeof clientRoutes
export const adminRoutes = [
  {
    path: ['/dashboard/requests'],
    exact: true,
    component: TimeOffRequests
  },
  {
    path: ['/dashboard/requests/create', '/dashboard/requests/:id/edit'],
    exact: true,
    component: NewRequestTimeOff
  },
  {
    path: '/dashboard/point-system/store',
    exact: true,
    component: Shop
  },
  {
    path: '/dashboard/point-system/store/create',
    exact: true,
    component: GoodsItem
  },
  {
    path: `/dashboard/point-system/store/:id/edit`,
    exact: true,
    component: GoodsItem
  },
  {
    path: '/dashboard/point-system/achievements',
    exact: true,
    component: AchievementsAdm
  },
  {
    path: [
      '/dashboard/point-system/achievements/create',
      `/dashboard/point-system/achievements/:id/edit`
    ],
    exact: true,
    component: AchievementItem
  },
  {
    path: '/dashboard/point-system/requests',
    exact: true,
    component: Requests
  },
  // {
  //   path: '/dashboard/point-system/history',
  //   exact: true,
  //   component: () => '/dashboard/point-system/history'
  // },
  {
    path: '/dashboard/settings',
    exact: true,
    component: Settings
  },
  {
    path: '/dashboard/users',
    exact: true,
    component: Users
  },
  {
    path: '/dashboard/faq',
    exact: true,
    component: Information
  },
  {
    path: '/dashboard/teamAdmin',
    exact: true,
    component: TeamAdmin
  },
  {
    path: '/dashboard/foreign-profile',
    exact: true,
    component: ForeignProfile
  },
  {
    path: '/dashboard/profile/editprofile',
    exact: true,
    component: EditProfile
  }
]

export const clientRoutes = [
  {
    path: '/dashboard/requests',
    exact: true,
    component: TimeOffRequests
  },
  {
    path: ['/dashboard/requests/create', '/dashboard/requests/:id/edit'],
    exact: true,
    component: NewRequestTimeOff
  },
  {
    path: '/dashboard/profile',
    exact: true,
    component: Profile
  },
  {
    path: '/dashboard/profile/editprofile',
    exact: true,
    component: EditProfile
  },
  {
    path: '/dashboard/point-system/profile/rating',
    exact: true,
    component: UserRating
  },
  {
    path: '/dashboard/point-system/store',
    exact: true,
    component: Goods
  },
  {
    path: '/dashboard/point-system/store/:id',
    exact: true,
    component: GoodsItemDetails
  },
  // {
  //   path: '/dashboard/point-system/store/item',
  //   // path: "/dashboard/point-system/store/:id", // todo - need to take product by id
  //   exact: true,
  //   component: () => 'Item GoodBoy'
  // },
  {
    path: '/dashboard/point-system/achievements',
    exact: true,
    component: Achievements
  },
  {
    path: '/dashboard/point-system/achievements/:id',
    exact: true,
    component: AchievementsItemDetails
  },
  {
    path: '/dashboard/point-system/basket',
    exact: true,
    component: Basket
  },
  // {
  //   path: '/dashboard/point-system/lottery',
  //   exact: true,
  //   component: Lottery
  // },
  {
    path: '/dashboard/faq',
    exact: true,
    component: Information
  },
  {
    path: '/dashboard/team',
    exact: true,
    component: Team
  },
  {
    path: '/dashboard/foreign-profile',
    exact: true,
    component: ForeignProfile
  }
]
