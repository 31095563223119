export const SIGN_IN_CLEAR: string = 'SIGN_IN_CLEAR'

export const AUTH_TOKEN_SUCCESS: string = 'AUTH_TOKEN_SUCCESS'
export const AUTH_TOKEN_FAILED: string = 'AUTH_TOKEN_FAILED'

export const SET_TOKEN: string = 'SET_TOKEN'

export const PULLING_SUCCESS: string = 'PULLING_SUCCESS'
export const PULLING_ERROR: string = 'PULLING_ERROR'

export const LOG_OUT_REQUEST: string = 'LOG_OUT_REQUEST'
export const LOG_OUT_SUCCESS: string = 'LOG_OUT_SUCCESS'
export const LOG_OUT_ERROR: string = 'LOG_OUT_ERROR'

export const REFRESH_TOKEN_REQUEST: string = 'REFRESH_TOKEN_REQUEST'
export const REFRESH_TOKEN_SUCCESS: string = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_ERROR: string = 'REFRESH_TOKEN_ERROR'

export const CHANGE_USER_DAY_OF_BIRTH_REQUEST = 'CHANGE_USER_DAY_OF_BIRTH_REQUEST'
export const CHANGE_USER_DAY_OF_BIRTH_SUCCESS = 'CHANGE_USER_DAY_OF_BIRTH_SUCCESS'
export const CHANGE_USER_DAY_OF_BIRTH_ERROR = 'CHANGE_USER_DAY_OF_BIRTH_ERROR'

export const CHANGE_USER_CITY_REQUEST = 'CHANGE_USER_CITY_REQUEST'
export const CHANGE_USER_CITY_SUCCESS = 'CHANGE_USER_CITY_SUCCESS'
export const CHANGE_USER_CITY_ERROR = 'CHANGE_USER_CITY_ERROR'

export const CHANGE_USER_POINTS_TRANSFER_REQUEST = 'CHANGE_USER_POINTS_TRANSFER_REQUEST'
export const CHANGE_USER_POINTS_TRANSFER_SUCCESS = 'CHANGE_USER_POINTS_TRANSFER_SUCCESS'
export const CHANGE_USER_POINTS_TRANSFER_ERROR = 'CHANGE_USER_POINTS_TRANSFER_ERROR'

export const SET_USER_AVATAR = 'SET_USER_AVATAR'
