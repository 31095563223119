import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'

import styles from './TerminationInformationModal.module.scss'
import Modal from 'components/Modal'
import moment from 'moment'

interface TerminationInformationModalProps {
  open: boolean
  toggleTerminationInformationModal: any
  terminationDate: string
  deactivationComment: string
  terminationInitiator: { id: number; name: string } | null
  blackList: boolean
}

export const TerminationInformationModal: FC<TerminationInformationModalProps> = ({
  open,
  toggleTerminationInformationModal,
  terminationDate,
  deactivationComment,
  terminationInitiator,
  blackList
}) => {
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <Modal isShow={open} onClose={toggleTerminationInformationModal} className={styles.modal}>
      <h3>{words['admin.team.information_about_termination']}</h3>
      <div className={styles.row}>
        <div className={styles['item-name']}>
          {words['admin.team.lastWorkingDay']}:
          <span>{terminationDate && moment(terminationDate).format('DD.MM.YYYY')}</span>
        </div>
        {blackList && <div className={styles['black-list']}>{words['admin.team.blackList']}</div>}
      </div>
      <div className={styles.row}>
        <div className={styles['item-name']}>
          {words['admin.team.terminationInitiator']}:
          <span>{terminationInitiator && words[terminationInitiator.name]}</span>
        </div>
      </div>
      <div>
        <div className={styles['item-name']}>{words['user.profile.table.comment']}:</div>
        <div className={styles['item-text']}>{deactivationComment}</div>
      </div>
    </Modal>
  )
}
