import React, { FC } from 'react'
import cls from 'classnames'
import styles from './RequestTypeCell.module.scss'
import { STATUS_OF_REQUESTS_TIME_OFF } from 'globalConfigs'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TTableProps = {
  content: number
}

export const RequestTypeCell: FC<TTableProps> = ({ content }) => {
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <div
      className={cls(styles.base, {
        [styles.pending]: content === 1,
        [styles.approve]: content === 2,
        [styles.reject]: content === 3,
        [styles.cancel]: content === 4
      })}
    >
      {STATUS_OF_REQUESTS_TIME_OFF(words)[content]}
    </div>
  )
}
