import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Header } from './Header'
import { getUserPhoto, pulling } from '../../screens/auth/Login/Login.thunx'
import { addToCart, removeFromCart } from '../../screens/client/Basket/Basket.actions'
import {
  getTotalPriseSelector,
  getTotalQuantitySelector,
  getDataSelector
} from '../../screens/client/Basket/Basket.reducer'
import { withRouter } from 'react-router'
import { logoutAction } from 'screens/auth/Login/Login.thunx'
import { getPointsSettings } from 'screens/admin/Settings/components/SettingsPointsSystem/SettingsPointsSystem.thunx'
import { checkUserProfile } from 'screens/client/Profile/Profile.thunx'
const mapStateToProps = (state: any) => ({
  user: state.auth.data,
  totalQuantity: getTotalQuantitySelector(state),
  totalPrice: getTotalPriseSelector(state),
  basket: getDataSelector(state),
  pointsFundPerMonth: state.admin.settings.pointsSystem.result.pointsFundPerMonth,
  pointsFund: state.admin.settings.pointsSystem.result.pointsFund,
  dataUsers: state.client.newRequestTimeOff.users.results,
  userPhoto: state.auth.avatar,
  profileStatus: state.client.profile.profile.status,
  profileLoading: state.client.profile.profile.loading
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      pulling,
      getPointsSettings,
      addToCart,
      removeFromCart,
      logout: logoutAction,
      getUserPhoto,
      checkUserProfile
    },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header))
