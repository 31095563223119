import { connect } from 'react-redux'
import { Shop as ShopComponent } from './Shop'
import { TState } from 'store'
import { bindActionCreators, Dispatch } from 'redux'
import { removeGood, restoreGood } from './Shop.thunx'
import { getGoods } from '../../client/Goods/Goods.thunx'
import { getGoodsCategories } from '../GoodsItem/GoodsItem.thunx'
import { clearGoodsData } from '../../client/Goods/Goods.actions'

export const mapStateToProps = (state: TState) => ({
  // user: state.auth.data,
  goods: state.client.goods.dataGoods,
  goodsCategories: state.admin.goodsItem.goodsCategories,
  total: state.client.goods.total,
  minPrice: state.client.goods.minPrice,
  maxPrice: state.client.goods.maxPrice
  // loading: state.admin.shop.loading
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getGoods,
      getGoodsCategories,
      removeGood,
      restoreGood,
      clearGoodsData
    },
    dispatch
  )

export const Shop = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopComponent)
