import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import Modal from 'components/Modal'
import styles from './EditPhotoModal.module.scss'
import { Button } from 'components/UiKit/Button'
import ReactCrop, { Crop } from 'react-image-crop'
import { Field } from 'react-final-form'
import { ReactComponent as AddImgIcon } from 'assets/images/plus_grey.svg'
import { ReactComponent as EditAvatar } from 'assets/images/editBtn.svg'
import trimCanvas from 'trim-canvas'

export const EditPhotoModal: FC<any> = ({ open, closeCropperModal, save, photo }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [crop, setCrop] = useState<Crop>()
  const [src, setSrc] = useState()
  const [fileType, setFileType] = useState<string>('image/jpg')
  const [originalImageFile, setOriginalImageFile] = useState<File | null>(null)

  useEffect(() => {
    setSrc(photo)
  }, [photo])

  const onImageLoaded = () => {
    const width = 130 //image.width
    const height = 130 //image.height

    setCrop({
      unit: 'px',
      aspect: 1,
      width,
      height,
      x: 0,
      y: 0
    })

    return false
  }

  const onSelectFile = (e: any) => {
    const f = e.target.files[0]
    setOriginalImageFile(f)
    if (f) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setSrc(reader.result)
      })
      reader.readAsDataURL(f)
      setFileType(f.type)
    }
  }

  const makeClientCrop = async (imgCrop: any) => {
    const img: any = document.getElementsByClassName('ReactCrop__image')[0]

    const fileName = originalImageFile
      ? originalImageFile.name
      : photo
          .split('/')
          .pop()
          .replace(/^[0-9_]+/, '')

    if (src && imgCrop.width && imgCrop.height) {
      const cropedImage: any = await getCroppedImg(img, imgCrop, fileName)

      const saveObj = {
        croppedBlob: cropedImage,
        originalFile: originalImageFile,
        originalImageSrc: src,
        fileName
      }

      save(saveObj)
    }
  }

  const getCroppedImg = (image: any, imgCrop: any, fileName: any) => {
    const canvas: any = document.createElement('canvas')
    const scale: any = image.naturalWidth / image.width
    canvas.width = imgCrop.width * scale
    canvas.height = imgCrop.height * scale
    const ctx: any = canvas.getContext('2d')

    ctx.drawImage(
      image,
      imgCrop.x * scale,
      imgCrop.y * scale,
      imgCrop.width * scale,
      imgCrop.height * scale,
      0,
      0,
      imgCrop.width * scale,
      imgCrop.height * scale
    )

    return new Promise((resolve, reject) => {
      trimCanvas(canvas).toBlob(
        (blob: any) => {
          if (!blob) {
            reject(new Error('Canvas is empty'))
            return
          }
          blob.name = fileName
          resolve(blob)
        },
        fileType,
        1
      )
    })
  }

  return (
    <Modal isShow={open} onClose={closeCropperModal} className={styles.modal}>
      <header>{words['user.profile.savePhotoPopup.header']}</header>

      <Field
        name={'avatar'}
        render={({ input }) => (
          <div className={styles['cropper-wrapper']}>
            <div className={src ? styles['edit-photo-container'] : styles['add-photo-container']}>
              <label htmlFor="avatar">
                {src && (
                  <EditAvatar
                    style={{ cursor: 'pointer' }}
                    // onChange={setIsEditHandler}
                  />
                )}
                {!src && <AddImgIcon style={{ cursor: 'pointer' }} />}
                {!src && (
                  <div className={styles['add-photo']}>{words['user.editProfile.addPhoto']}</div>
                )}
              </label>
              <input {...input} id="avatar" type="file" onChange={onSelectFile} />
            </div>
            <ReactCrop
              src={src}
              crop={crop}
              onImageLoaded={onImageLoaded}
              onChange={(newCrop: any) => {
                setCrop(newCrop)
              }}
              crossorigin="anonymous"
              circularCrop={true}
              imageStyle={{ maxHeight: 'calc(100vh - 200px)' }}
            />
          </div>
        )}
      />
      <div className={styles['btn-container']}>
        <Button
          outline={true}
          onClick={() => {
            closeCropperModal()
          }}
        >
          <span>{words['user.profile.savePopup.dontSave']}</span>
        </Button>

        <Button
          onClick={() => {
            makeClientCrop(crop)
            closeCropperModal()
          }}
        >
          <span>{words['user.profile.savePopup.saveBtn']}</span>
        </Button>
      </div>
    </Modal>
  )
}
