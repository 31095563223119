import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import { AchievementsItemDetails as AchievementsItemDetailsComponent } from './AchievementsItemDetails'
import { createRequestAchievement, getCurrentAchievement } from '../Achievements/Achievements.thunx'

export const mapStateToProps = (state: TState) => ({
  achievement: state.client.achievements.currentAchievement
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCurrentAchievement,
      createRequestAchievement
    },
    dispatch
  )

export const AchievementsItemDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(AchievementsItemDetailsComponent)
