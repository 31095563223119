import { Dispatch } from 'redux'
import { API, api } from 'services/api'
import {
  getProductCalendarItemError,
  getProductCalendarItemRequest,
  getProductCalendarItemSuccess,
  updateProductCalendarError,
  updateProductCalendarRequest,
  updateProductCalendarSuccess
} from './SettingsTimeOfRequestUpdate.actions'
import toastr from 'utils/toastr'
import history from 'utils/history'
import { TState } from 'store'

export type TSetProductCalendarBody = {
  date: string
  description: string
  type: 1 | 2
}

export const getProductCalendarItem = (id: number) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(getProductCalendarItemRequest())
  const words = getData().global.language.words

  try {
    const { data } = await api.get(`${API.PRODUCT_CALENDAR}/${id}`)
    dispatch(getProductCalendarItemSuccess(data.payload))
  } catch (error) {
    toastr('error', words['NOT_FOUND'], error)
    dispatch(getProductCalendarItemError(error))
  }
}

export const updateProductCalendar = (id: number, body: TSetProductCalendarBody) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(updateProductCalendarRequest())
  const words = getData().global.language.words

  try {
    const { data } = await api.put(`${API.PRODUCT_CALENDAR}/${id}`, body)
    dispatch(updateProductCalendarSuccess(data.payload))
    toastr('info', words['SUCCESS_UPDATED'])
    history.push({
      pathname: history.location.pathname,
      search: `calendar=true`
    })
  } catch (error) {
    dispatch(updateProductCalendarError(error))
    toastr('error', words['FILED_UPDATE_CALENDAR'], error)
  }
}
