import { IAction } from 'models/index'
import {
  SET_PRODUCT_CALENDAR_SUCCESS,
  SET_PRODUCT_CALENDAR_REQUEST,
  SET_PRODUCT_CALENDAR_ERROR,
  GET_PRODUCT_CALENDAR_ERROR,
  GET_PRODUCT_CALENDAR_SUCCESS,
  GET_PRODUCT_CALENDAR_REQUEST,
  DELETE_PRODUCT_CALENDAR_SUCCESS,
  DELETE_PRODUCT_CALENDAR_ERROR
} from './SettingsTimeOfRequest.constants'
import { AxiosError } from 'axios'
import { TDeleteProductCalendarSuccess } from './SettingsTimeOfRequest.actions'

export type TSettingsTimeOfRequestTableData = {
  workingDays: Array<{ date: string; description: string; id: number }>
  nonWorkingDays: Array<{ date: string; description: string; id: number }>
}
export type TSettingsTimeOfRequestInitialState = {
  table: {
    results: TSettingsTimeOfRequestTableData
    total: number
    loading: boolean
    error: AxiosError | null
  }

  modal: {
    success: boolean
    loading: boolean
    error: AxiosError | null
  }
}
export const initialState: TSettingsTimeOfRequestInitialState = {
  table: {
    results: {
      workingDays: [],
      nonWorkingDays: []
    },
    total: 0,
    error: null,
    loading: false
  },
  modal: {
    error: null,
    success: false,
    loading: false
  }
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_PRODUCT_CALENDAR_REQUEST:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true
        }
      }

    case SET_PRODUCT_CALENDAR_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          success: true,
          loading: false
        }
      }

    case SET_PRODUCT_CALENDAR_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: false,
          success: false,
          error: action.payload
        }
      }

    case GET_PRODUCT_CALENDAR_REQUEST:
      return {
        ...state,
        table: {
          ...state.table,
          loading: true
        }
      }

    case GET_PRODUCT_CALENDAR_SUCCESS:
      return {
        ...state,
        table: {
          ...state.table,
          results: {
            ...state.table.results,
            workingDays: action.payload.workingDays,
            nonWorkingDays: action.payload.nonWorkingDays
          },
          total: action.payload.total,
          loading: false
        }
      }

    case DELETE_PRODUCT_CALENDAR_ERROR:
    case GET_PRODUCT_CALENDAR_ERROR:
      return {
        ...state,
        table: {
          ...state.table,
          error: action.payload,
          loading: false
        }
      }

    case DELETE_PRODUCT_CALENDAR_SUCCESS: {
      const { id } = (action as TDeleteProductCalendarSuccess).payload

      return {
        ...state,
        table: {
          ...state.table,
          results: {
            ...state.table.results,
            nonWorkingDays: state.table.results.nonWorkingDays.filter(item => item.id !== id),
            workingDays: state.table.results.workingDays.filter(item => item.id !== id)
          },
          loading: false
        }
      }
    }

    default:
      return state
  }
}
