import { IAction } from 'models/index'
import {
  GET_PRODUCT_CALENDAR_ITEM_ERROR,
  GET_PRODUCT_CALENDAR_ITEM_SUCCESS,
  GET_PRODUCT_CALENDAR_ITEM_REQUEST,
  UPDATE_PRODUCT_CALENDAR_ERROR,
  UPDATE_PRODUCT_CALENDAR_REQUEST,
  UPDATE_PRODUCT_CALENDAR_SUCCESS
} from './SettingsTimeOfRequestUpdate.constants'
import { AxiosError } from 'axios'

export type TSettingsTimeOfRequestUpdateItem = {
  date: string
  description: string
  id: number
  type: number
}
export type TSettingsTimeOfRequestInitialState = {
  calendarItem: {
    results: TSettingsTimeOfRequestUpdateItem
    loading: boolean
    error: AxiosError | null
  }
  updateCalendarItem: {
    error: AxiosError | null
    loading: boolean
  }
}
export const initialState: TSettingsTimeOfRequestInitialState = {
  calendarItem: {
    results: {
      date: '',
      description: '',
      id: 0,
      type: 0
    },
    error: null,
    loading: false
  },
  updateCalendarItem: {
    error: null,
    loading: false
  }
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_PRODUCT_CALENDAR_ITEM_REQUEST:
      return {
        ...state,
        calendarItem: {
          ...state.calendarItem,
          loading: true
        }
      }

    case GET_PRODUCT_CALENDAR_ITEM_SUCCESS:
      return {
        ...state,
        calendarItem: {
          ...state.calendarItem,
          results: action.payload.productCalendar,
          loading: false
        }
      }

    case GET_PRODUCT_CALENDAR_ITEM_ERROR:
      return {
        ...state,
        calendarItem: {
          ...state.calendarItem,
          loading: false,
          error: action.payload
        }
      }

    case UPDATE_PRODUCT_CALENDAR_REQUEST:
      return {
        ...state,
        updateCalendarItem: {
          ...state.updateCalendarItem,
          loading: true
        }
      }

    case UPDATE_PRODUCT_CALENDAR_SUCCESS:
      return {
        ...state,
        updateCalendarItem: {
          ...state.updateCalendarItem,
          loading: false
        }
      }

    case UPDATE_PRODUCT_CALENDAR_ERROR:
      return {
        ...state,
        updateCalendarItem: {
          ...state.updateCalendarItem,
          loading: false,
          error: action.payload
        }
      }

    default:
      return state
  }
}
