/* eslint-disable no-shadow */
import { TSelectOption } from './components/Select'
import { TWords } from 'elements/SideBar/SideBar.config'
import { IToggleBarData } from 'components/ToggleBar'
import { IChekbox } from 'components/UiKit/Table/components/SetColumns'

export type TConfig = typeof config
export type TMapperNumberString = { [key: number]: string }
export type TMapperStringsNumber = { [key: string]: number }
export type TMultipleSelectOptions = {
  values: number[]
  label: string
}

export const config = {
  trackJsToken: process.env.REACT_APP_TRACK_JS_TOKEN || '476fd1e32d67479e8015c712dc7c41c9',
  trackJsApplication: process.env.REACT_APP_TRACK_JS_APP || 'template',
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
  googleLogin:
    process.env.REACT_APP_GOOGLE_LOGIN ||
    'https://dev-points-system.technorely.com/backend/api/login',
  dateFormat: 'dd.MM.yyyy',
  fileTypeRegExp: /.+\.(\w+)$/,
  fileNameRegExp: /^.+\/\d+_(.+)\./
}
export const DATE_FORMAT_FOR_DETAILS = 'DD.MM.YY'

export const TYPE_OF_REQUESTS_TIME_OFF: TMapperNumberString = {
  1: 'Panding',
  2: 'Day-off',
  3: 'Absent',
  4: 'Self-paid',
  5: 'Sick',
  6: 'Remote'
}

export const STATUS_OF_REQUESTS_TIME_OFF = (words: TWords): TMapperNumberString => {
  return {
    1: words['user.dayOffTracker.table.requestStatus.pending'],
    2: words['user.dayOffTracker.table.requestStatus.approved'],
    3: words['user.dayOffTracker.table.requestStatus.rejected'],
    4: words['user.dayOffTracker.table.requestStatus.canceled'],
    5: words['user.dayOffTracker.table.requestStatus.processed']
  }
}

export const STATUS_OF_REQUESTS_SELECT = (words: TWords): TSelectOption[] => {
  return [
    { value: '1', label: words['user.dayOffTracker.table.requestStatus.pending'] },
    { value: '2', label: words['user.dayOffTracker.table.requestStatus.approved'] },
    { value: '3', label: words['user.dayOffTracker.table.requestStatus.rejected'] }
  ]
}

export const REQUEST_STATUS_COLORS: { [key: number]: string } = {
  1: 'orange',
  2: 'green',
  3: 'red',
  4: 'blue',
  5: 'green'
}
export const TYPE_TRANSACTION = (words: TWords): TMapperNumberString => {
  return {
    1: words['user.profile.table.type.outgoingTransfer'],
    2: words['user.profile.table.type.incomingTransfer']
  }
}

export const ACTIONS_FOR_CHANGE_STATUS_OF_REQUESTS_TIME_OFF: TMapperStringsNumber = {
  approve: 2,
  reject: 3,
  cancel: 4
}

export const FILTER_SELECT_TYPE = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: '2', label: words['manager.dayOffTracker.details.requestType.dayOff'] },
    { value: '3', label: words['manager.dayOffTracker.details.requestType.absent'] },
    { value: '5', label: words['manager.dayOffTracker.details.requestType.sick'] },
    { value: '6', label: words['manager.dayOffTracker.details.requestType.remote'] },
    { value: '4', label: words['manager.dayOffTracker.details.requestType.selfPaid'] }
  ]
}

export const FILTER_SELECT_STATUS = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: '1', label: words['user.dayOffTracker.table.requestStatus.pending'] },
    { value: '2', label: words['user.dayOffTracker.table.requestStatus.approved'] },
    { value: '3', label: words['user.dayOffTracker.table.requestStatus.rejected'] },
    { value: '4', label: words['user.dayOffTracker.table.requestStatus.canceled'] },
    { value: '5', label: words['user.dayOffTracker.table.requestStatus.processed'] }
  ]
}

export const FILTER_SELECT_ACCAUNT_STATUS = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: '1', label: words['admin.table.filter.activated'] },
    { value: '2', label: words['admin.table.filter.deactivated'] }
  ]
}

export const FILTER_SELECT_STATUS_FOR_USER = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: '1', label: words['user.dayOffTracker.table.requestStatus.pending'] },
    { value: '2', label: words['user.dayOffTracker.table.requestStatus.approved'] },
    { value: '3', label: words['user.dayOffTracker.table.requestStatus.rejected'] }
  ]
}

export const FILTER_SELECT_INITIATIVES_STATUS = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: '1', label: words['user.dayOffTracker.table.requestStatus.pending'] },
    { value: '5', label: words['user.dayOffTracker.table.requestStatus.processed'] }
  ]
}

export const LIFE_PRIORITIES = (words: TWords): TSelectOption[] => {
  return [
    { value: '1', label: words['user.profile.mainInfo.livePriorities.family'] },
    { value: '2', label: words['user.profile.mainInfo.livePriorities.career'] },
    { value: '3', label: words['user.profile.mainInfo.livePriorities.selfDev'] },
    { value: '4', label: words['user.profile.mainInfo.livePriorities.spiritDev'] },
    { value: '5', label: words['user.profile.mainInfo.livePriorities.finances'] },
    { value: '6', label: words['user.profile.mainInfo.livePriorities.charity'] },
    { value: '7', label: words['user.profile.mainInfo.livePriorities.phisicalImprovement'] }
  ]
}

export const AMOUNT_OF_CHILDREN = (words: TWords): TSelectOption[] => {
  return [
    { value: '0', label: words['user.editProfile.notSelected'] },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
  ]
}

export const TYPES_TRANSACTIONS = (words: TWords): TSelectOption[] => {
  return [
    { value: '1', label: words['user.profile.table.type.outgoingTransfer'] },
    { value: '2', label: words['user.profile.table.type.incomingTransfer'] }
  ]
}

export const FILTER_SELECT_ROLES_FOR_USERS = (words: TWords): TSelectOption[] => {
  return [
    { value: '2', label: words['roles.admin'] },
    { value: '3', label: words['roles.manager'] },
    { value: '4', label: words['roles.user'] }
  ]
}

export const ROLES = (words: TWords): TMapperNumberString => {
  return {
    1: words['roles.superAdmin'],
    2: words['roles.admin'],
    3: words['roles.manager'],
    4: words['roles.user']
  }
}

export const PRODUCT_CALENDAR_TYPE = (words: TWords): TMapperNumberString => {
  return {
    1: words['admin.productionCalendar.new.type.workingDay'],
    2: words['admin.productionCalendar.new.type.holiday']
  }
}

export const FILTER_SELECT_PRODUCT_CALENDAR_TYPE = (words: TWords): TSelectOption[] => {
  return [
    { value: '1', label: words['admin.productionCalendar.new.type.workingDay'] },
    { value: '2', label: words['admin.productionCalendar.new.type.holiday'] }
  ]
}

export enum EPageLimits {
  USERS = 200,
  MANAGERS = 200,
  GOODS = 20,
  ACHIEVEMENTS = 20,
  HISTORY = 10,
  TRANSACTION = 10,
  TEAM = 20
}

export const PERIOD_OF_POLLING: number = 30000
export const PAGING_LIMIT: number = 100

export enum EPointsEdit {
  STEP = 100
}

export enum EPointsSettings {
  STEP_FEE_CHARGER = 1,
  STEP_POINTS_FUND = 1000,
  STEP_ACHIEVEMENTS = 1000
}

export enum ERowSize {
  DEFAULT = 50,
  SMALL = 56,
  MIDDLE = 72,
  LARGE = 120
}

export enum EProfileHistory {
  MAIN_INFO = 'main-info',
  CAREER = 'career',
  TIME_OFF_BALANCE = 'time-off-balance',
  POINTS_SYSTEM = 'points-system'
}

export enum ETransactionHistory {
  GOODS = 'goods',
  ACHIEVEMENTS = 'initiative',
  TRANSACTIONS = 'transactions'
}

export enum EFaq {
  PROCESSES = 'processes',
  WEEKENDS = 'weekends',
  POINTSSYSTEM = 'pointssystem'
}

export const USER_ROLE = 'userRole'
export const ADMIN_ROLE = 'adminRole'

export const requiredOptions: any = {
  'main-info': ['rank', 'position', 'phoneNumber', 'hometown', 'city', 'dateOfBirth'],
  career: ['technologies', 'careerGoal']
}

export const getTransactionToggleBarOptions = (words: TWords): IToggleBarData[] => [
  {
    value: ETransactionHistory.GOODS,
    title: words['user.profile.goodsService']
  },
  {
    value: ETransactionHistory.ACHIEVEMENTS,
    title: words['user.profile.initiatives']
  },
  {
    value: ETransactionHistory.TRANSACTIONS,
    title: words['user.profile.transactions']
  }
]

export const getToggleBarOptions = (words: TWords, isEdit: boolean, isAdmin: boolean): any => {
  const toggleBarOptions = [
    {
      value: EProfileHistory.MAIN_INFO,
      title: words['user.profile.togglebar.mainInfo']
    },
    {
      value: EProfileHistory.CAREER,
      title: words['user.profile.togglebar.career']
    }
  ]

  if (!isEdit || isAdmin) {
    toggleBarOptions.push(
      {
        value: EProfileHistory.TIME_OFF_BALANCE,
        title: words['user.profile.togglebar.daysOff']
      },
      {
        value: EProfileHistory.POINTS_SYSTEM,
        title: words['user.profile.togglebar.pointsSystem']
      }
    )
  }
  return toggleBarOptions
}

export const setToggleBarOptionsTeam = (words: TWords): IToggleBarData[] => {
  return [
    {
      value: 'active',
      title: words['admin.team.toggle.active']
    },
    {
      value: 'deleted',
      title: words['admin.team.toggle.deactivated']
    }
  ]
}

export const checkboxConfig = (words: TWords, deleted: string): IChekbox[] => {
  const checkboxConfigArray = [
    { name: 'fullName', label: words['admin.users.table.filters.userName'], value: true },
    { name: 'position', label: words['user.profile.career.position'], value: true },
    { name: 'rank', label: words['user.profile.career.level'], value: true },
    { name: 'city', label: words['user.profile.mainInfo.location'], value: true },
    {
      name: 'department',
      label: words['user.profile.career.department'],
      value: false
    },
    {
      name: 'typeOfEmployment',
      label: words['user.profile.career.occupation'],
      value: true
    },
    { name: 'workingHoursPerDay', label: words['user.profile.career.workingHours'], value: false },
    { name: 'firstWorkingDay', label: words['user.profile.career.hiringDate'], value: true },
    {
      name: 'probationEndDate',
      label: words['user.profile.career.probationEndDate'],
      value: false
    },
    { name: 'salary', label: words['user.profile.career.salary'], value: true },
    { name: 'dateOfBirth', label: words['user.profile.mainInfo.dob'], value: false },
    { name: 'maritalStatus', label: words['user.profile.mainInfo.martialStatus'], value: false },
    {
      name: 'numberOfChildren',
      label: words['user.profile.mainInfo.numberOfChildren'],
      value: false
    },
    { name: 'englishLevel', label: words['user.profile.career.englishLevel'], value: false }
  ]

  if (deleted === 'true') {
    checkboxConfigArray.splice(
      4,
      0,
      { name: 'terminationDate', label: words['admin.team.terminationDate'], value: true },
      { name: 'terminationInitiator', label: words['admin.team.terminationInitiator'], value: true }
    )
  }

  return checkboxConfigArray
}
