import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import queryString from 'query-string'
import moment from 'moment'
import cn from 'classnames'

import styles from './TeamAdmin.module.scss'
import './TeamAdmin.scss'
import history from '../../../utils/history'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
import {
  checkboxConfig,
  EProfileHistory,
  ETransactionHistory,
  setToggleBarOptionsTeam
} from 'globalConfigs'
import { Filter } from 'components/UiKit/Filter'
import { getFilterTeamAdmin, getFilterTeamAdminDeactivate } from './TeamAdmin.filter.config'
import { TeamTable } from 'components/UiKit/Table'
import { TManagersData } from 'screens/client/NewRequestTimeOff/NewRequestTimeOff.reducer'
import { parseSelectedOptions } from 'utils/parseSelectedOptions'
import { getTeamColumns } from './TeamAdmin.columns'
import { useLoadDateFortTable } from 'hooks/useLoadDateFortTable'
import { TeamAdminProps } from './TeamAdmin.model'
import { IChekbox } from 'components/UiKit/Table/components/SetColumns'

export const TeamAdmin: FC<TeamAdminProps> = ({
  data = [],
  total,
  hiddenColumns,
  currentTab,
  checkedItems,
  positions,
  rank,
  maritalStatus,
  englishLevel,
  department,
  typeOfEmployment,
  terminationInitiator,
  displayAllFields,
  getTeamAdminList,
  getSelectOptionsForTeamAdmin,
  getTerminationInitiatorOptions,
  setHiddenColumns,
  setCurrentTab,
  setCheckedItems,
  clearTeamAdminData
}) => {
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)

  const toggleBarOptions = setToggleBarOptionsTeam(words)
  const [parsedData, setParsedData] = useState<TManagersData[]>([])
  const [selectedType, setSelectedType] = useState<IToggleBarData>(
    parsed.deleted === 'true' ? toggleBarOptions[1] : toggleBarOptions[0]
  )
  const currentLang = localStorage.getItem('TechnorelyLanguage')

  useEffect(() => {
    return () => {
      clearTeamAdminData()
    }
  }, [])
  useEffect(() => {
    if (parsed.deleted) {
      setCurrentTab({
        tab: String(parsed.deleted),
        lang: currentLang
      })
      if (currentTab.tab !== parsed.deleted || currentTab.lang !== currentLang) {
        setCheckedItems(checkboxConfig(words, String(parsed.deleted)))
      }
    }
  }, [parsed.deleted])

  useEffect(() => {
    if (parsed.deleted) {
      const toHiddenColumn: string[] = []
      checkedItems.map((item: IChekbox) => {
        if (!item.value) {
          toHiddenColumn.push(item.name)
        }
        return toHiddenColumn
      })
      setHiddenColumns(toHiddenColumn)
    }
  }, [checkedItems, parsed.deleted])

  useEffect(() => {
    if (parsed.deleted) {
      getSelectOptionsForTeamAdmin()
      if (parsed.deleted === 'true') {
        getTerminationInitiatorOptions()
      }
    }
  }, [parsed.deleted])

  useEffect(() => {
    if (parsed.deleted) {
      setParsedData(
        data &&
          data.map((item: any) => {
            const newItem = { ...item }
            newItem.firstWorkingDay =
              newItem.firstWorkingDay && moment(newItem.firstWorkingDay).format('DD.MM.YYYY')
            newItem.probationEndDate =
              newItem.probationEndDate && moment(newItem.probationEndDate).format('DD.MM.YYYY')
            newItem.terminationDate =
              newItem.terminationDate && moment(newItem.terminationDate).format('DD.MM.YYYY')
            newItem.dateOfBirth =
              newItem.dateOfBirth && moment(newItem.dateOfBirth).format('DD.MM.YYYY')
            if (newItem.children && newItem.children.length > 0) {
              newItem.numberOfChildren = newItem.children.length
            }
            return parseSelectedOptions(newItem, true, {
              positions,
              rank,
              maritalStatus,
              englishLevel,
              department,
              typeOfEmployment,
              terminationInitiator
            })
          })
      )
    }
  }, [data, positions])

  const columnsMemoized = useMemo(() => getTeamColumns(words, checkedItems, parsed.deleted), [
    checkedItems
  ])
  const { setCurrentPage } = useLoadDateFortTable(getTeamAdminList)

  if (!parsed.deleted) {
    history.push({ search: 'deleted=false', pathname: history.location.pathname })
  }

  const rowClick = (row: any) => {
    history.push(
      `/dashboard/foreign-profile?userId=${row.original.id}&profileType=${
        EProfileHistory.MAIN_INFO
      }&type=${ETransactionHistory.GOODS}`
    )
  }

  return (
    <div className={styles.containerTeamAdmin}>
      <section>
        <header className={styles.header}>
          <h1>{words['sidebar.team']}</h1>
          <div className={styles['header-history']}>
            <div className={styles.toggle}>
              <ToggleBar
                name="active-goods-no-active"
                data={toggleBarOptions}
                defaultChecked={selectedType}
                onChange={event => {
                  history.push({
                    pathname: `${history.location.pathname}`,
                    search: `deleted=${event.value === 'deleted'}`
                  })
                  setSelectedType(event)
                }}
              />
            </div>
            {/* to do with BE part
            <div className={styles['container-change-history']}>
              <Icons icon="timeIcon" />
              <div className={styles['change-history']}>{words['admin.team.changeHistory']}</div>
            </div> */}
          </div>
        </header>

        <div className={styles['filter-container']}>
          <Filter
            config={
              parsed.deleted === 'false'
                ? getFilterTeamAdmin(words, {
                    positions,
                    rank,
                    department,
                    typeOfEmployment,
                    englishLevel,
                    displayAllFields
                  })
                : getFilterTeamAdminDeactivate(words, {
                    positions,
                    rank,
                    department,
                    typeOfEmployment,
                    englishLevel,
                    terminationInitiator,
                    displayAllFields
                  })
            }
            withButton={true}
            defaultOpened={true}
            isMultilineBtn
            currentNameComponent={`TeamAdmin${parsed.deleted}`}
          />
        </div>
        <TeamTable
          total={total}
          data={parsedData}
          noData={words['noData']}
          getData={({ page }) => {
            setCurrentPage(page)
          }}
          columns={columnsMemoized}
          onRowClick={row => rowClick(row)}
          classNameToRow="TeamItemRow"
          hiddenColumns={hiddenColumns}
          lang={currentLang}
        />
        <div className={styles['active-users']}>
          {parsed.deleted === 'true'
            ? words['admin.team.deactivatedUsers']
            : words['admin.team.activeUsers']}
          <span
            className={cn({
              [styles['active-number-users']]: parsed.deleted === 'false',
              [styles['inactive-number-users']]: parsed.deleted === 'true'
            })}
          >
            {parsedData && parsedData.length}
          </span>
        </div>
      </section>
    </div>
  )
}
