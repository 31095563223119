class Storage {
  public set = (key: string, value: any) => {
    return window.localStorage.setItem(key, JSON.stringify(value))
  }

  public get = (key: string) => {
    try {
      const serializedState = window.localStorage.getItem(key)

      return serializedState === null ? null : JSON.parse(serializedState)
    } catch (err) {
      return null
    }
  }

  public remove = (key: string) => {
    return window.localStorage.removeItem(key)
  }

  public update = (key: string, values: any) => {
    let data = this.get(key)
    data = values
    return this.set(key, data)
  }
}

const storage = new Storage()
export default storage
