import PrivateRoute from './PrivatRoute'
import { signInTokenFailed, signInTokenSuccess } from '../../screens/auth/Login/Login.actions'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { TState } from 'store'

export const mapStateToProps = ({ auth }: TState) => ({
  isAuthenticated: auth.data && !!auth.data.accessToken
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signInTokenSuccess,
      signInTokenFailed
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
