import React from 'react'
import { Field } from 'react-final-form'
import styles from './FieldFactory.module.scss'
import { Input } from 'components/UiKit/Inputs'
import { Select } from 'Select'
import { RedNote } from 'components/RedNote'
import validation from 'utils/validation'
import { TSelectOption } from 'components/Select'
import { DatePicker } from '../DatePicker'
// import { addCurrentTime, formatterDateForDatePicker } from '../Filter/Filter'
import { Textarea } from 'components/Textarea'
import { TFieldConfigRow, TFieldFactoryProps } from './FieldFactory.model'

const FieldFactory: any = ({ config, form, words, currentLanguage }: TFieldFactoryProps) => {
  return (
    <div>
      {config.map((row: TFieldConfigRow, arrIndex: number) => {
        return (
          <div key={arrIndex} className={styles.row} style={row.style && row.style}>
            {row.items.map((item: any, index: number) => {
              const validatorNames: any = {
                required: item.required,
                isPhoneNumber: item.isPhoneNumber,
                isMin: item.minValue
              }
              const validators: any = {
                required: validation.required(words['user.requiredMessage']),
                isPhoneNumber: validation.isPhoneNumber(),
                isMin: validation.min(validatorNames.isMin)
              }
              const isValidate: any = Object.values(validatorNames).find(validatorType => {
                return validatorType && true
              })

              const setValidators = () => {
                const setOfValidators: any = []
                Object.keys(validatorNames).forEach(validator => {
                  if (validatorNames[validator]) {
                    setOfValidators.push(validators[validator])
                  }
                })
                return setOfValidators
              }

              return (
                <Field
                  key={index}
                  name={item.name}
                  validate={isValidate && validation.composeValidators(...setValidators())}
                >
                  {({ input, meta }) => {
                    const componentInfo = item.component({ input, meta, form })
                    let component = <></>

                    switch (componentInfo.type) {
                      case 'input':
                        component = (
                          <Input
                            {...input}
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                            {...componentInfo.props}
                          />
                        )
                        break
                      case 'select':
                        component = (
                          <Select
                            {...input}
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                            onChange={(value: TSelectOption) =>
                              form.change(input.name, (value as TSelectOption).value)
                            }
                            emptyMessage={words['noOption']}
                            value={componentInfo.props.options.find(
                              (element: TSelectOption) =>
                                Number(element.value) === Number(input.value)
                            )}
                            {...componentInfo.props}
                          />
                        )
                        break
                      case 'multiSelect':
                        component = (
                          <Select
                            {...input}
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                            onChange={(value: [TSelectOption]) => {
                              return form.change(
                                input.name,
                                value && value.map(i => ({ value: i.value }))
                              )
                            }}
                            emptyMessage={words['noOption']}
                            value={
                              input.value &&
                              input.value.map((valueItem: { value: string }) => {
                                return componentInfo.props.options.find(
                                  (element: TSelectOption) => {
                                    return Number(element.value) === Number(valueItem.value)
                                  }
                                )
                              })
                            }
                            {...componentInfo.props}
                          />
                        )
                        break
                      case 'datePicker':
                        component = (
                          <DatePicker
                            {...input}
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                            locale={currentLanguage}
                            name={input.name}
                            onChange={event => {
                              if (event) {
                                form.change(input.name, event)
                                // form.change(
                                //   input.name,
                                //   formatterDateForDatePicker(addCurrentTime(event))
                                // )
                              }
                            }}
                            {...componentInfo.props}
                          />
                        )
                        break
                      case 'textarea':
                        component = (
                          <Textarea
                            {...input}
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                            {...componentInfo.props}
                          />
                        )
                    }

                    return (
                      <div
                        style={
                          !item.inputWrapperClassName
                            ? { width: '100%', marginBottom: '32px' }
                            : undefined
                        }
                        className={item.inputWrapperClassName ? item.inputWrapperClassName : ''}
                      >
                        <div className={styles['label']}>
                          <label>
                            {item.label} {item.required && <RedNote />}
                          </label>
                        </div>
                        {component}
                      </div>
                    )
                  }}
                </Field>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default FieldFactory
