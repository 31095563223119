import {
  GET_USER_RATING_REQUEST,
  GET_USER_RATING_SUCCESS,
  GET_USER_RATING_ERROR,
  CLEAR_USERS_RATING
} from './UserRating.constants'
import { action } from 'store/store.utils'

import { TUserData } from '../../../../admin/Users/Users.model'
import { AxiosError } from 'axios'

export const getUserRatingRequest = () => action(GET_USER_RATING_REQUEST)
export const clearUsersRating = () => action(CLEAR_USERS_RATING)
export const getUserRatingSuccess = (payload: { results: TUserData[]; total: number }) =>
  action(GET_USER_RATING_SUCCESS, payload)
export const getUserRatingError = (payload: AxiosError) => action(GET_USER_RATING_ERROR, payload)
