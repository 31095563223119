import React, { FC, HTMLAttributes, ReactNode } from 'react'
import { ReactComponent as Add } from 'assets/images/plus.svg'
import { ReactComponent as Remove } from 'assets/images/minus.svg'
import { ReactComponent as ArrowDown } from 'assets/images/arrow_icon.svg'
import { ReactComponent as ArrowSort } from 'assets/images/arrow_sort_icon.svg'
import { ReactComponent as Basket } from 'assets/images/basket_icon.svg'
import { ReactComponent as Discharge } from 'assets/images/cancel_icon.svg'
import { ReactComponent as Purchases } from 'assets/images/purchases.svg'
import { ReactComponent as Close } from 'assets/images/close.svg'
import { ReactComponent as DischargeOutline } from 'assets/images/discharge_outline.svg'
import { ReactComponent as DischargeOutlineDisabled } from 'assets/images/discharge_outline_disabled.svg'
import { ReactComponent as Ellipsis } from 'assets/images/ellipsis_icon.svg'
import { ReactComponent as Exit } from 'assets/images/exit_icon.svg'
import { ReactComponent as Shop } from 'assets/images/goods_icon.svg'
import { ReactComponent as Login } from 'assets/images/login.svg'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { ReactComponent as LogoSmall } from 'assets/images/logo-small.svg'
import { ReactComponent as Next } from 'assets/images/next.svg'
import { ReactComponent as NormalDischarge } from 'assets/images/normalDischarge.svg'
import { ReactComponent as PlusIcon } from 'assets/images/plus_icon.svg'
import { ReactComponent as Point } from 'assets/images/point.svg'
import { ReactComponent as PointIcon } from 'assets/images/point_icon.svg'
import { ReactComponent as ProfileIcon } from 'assets/images/profile_icon.svg'
import { ReactComponent as RequestsUserIcon } from 'assets/images/requests_user_icon.svg'
import { ReactComponent as RouletteIcon } from 'assets/images/roulette_icon.svg'
import { ReactComponent as TcentIcon } from 'assets/images/tcent_icon.svg'
import { ReactComponent as TimeOffRequests } from 'assets/images/time-off-requests.svg'
import { ReactComponent as TimeIcon } from 'assets/images/time_icon.svg'
import { ReactComponent as TimeIconDisable } from 'assets/images/time_icon_disable.svg'
import { ReactComponent as Settings } from 'assets/images/settings.svg'
import { ReactComponent as Users } from 'assets/images/users.svg'
import { ReactComponent as UpdateIcon } from 'assets/images/update_icon.svg'
import { ReactComponent as DoubleArrow } from 'assets/images/doubleArrow.svg'
import { ReactComponent as LogoCollapsed } from 'assets/images/logo_collapsed.svg'
import { ReactComponent as Profile } from 'assets/images/profile.svg'
import { ReactComponent as RestoreIcon } from 'assets/images/restore_icon.svg'
import { ReactComponent as UpdateDisabledIcon } from 'assets/images/update_disabled_icon.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/images/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/images/arrow_right_icon.svg'
import { ReactComponent as PointEditIcon } from 'assets/images/point_edit_icon.svg'
import { ReactComponent as BackArrowIcon } from 'assets/images/back_arrow_icon.svg'
import { ReactComponent as ButtonFilterIcon } from 'assets/images/icon_button_filter.svg'
import { ReactComponent as Filter } from 'assets/images/filter_normal_icon.svg'
import { ReactComponent as FilterDisabled } from 'assets/images/filter_disabled_icon.svg'
import { ReactComponent as Comment } from 'assets/images/icon_table_comment.svg'
import { ReactComponent as CommentDisabled } from 'assets/images/icon_table_comment_disabled.svg'
import { ReactComponent as CloseTable } from 'assets/images/icon_table_close.svg'
import { ReactComponent as FAQ } from 'assets/images/FAQ.svg'
import { ReactComponent as IconFaqClose } from 'assets/images/iconFaqClose.svg'
import { ReactComponent as IconFaqOpen } from 'assets/images/iconFaqOpen.svg'
import { ReactComponent as StarSmallActive } from 'assets/images/icon_star_small_active.svg'
import { ReactComponent as StarSmallPassive } from 'assets/images/icon_star_small_passive.svg'
import { ReactComponent as FaqLogo } from 'assets/images/FaqLogo.svg'
import { ReactComponent as UsersRating } from 'assets/images/users_rating.svg'
import { ReactComponent as FirstPlace } from 'assets/images/icon_1st.svg'
import { ReactComponent as SecondPlace } from 'assets/images/icon_2st.svg'
import { ReactComponent as ThirdPlace } from 'assets/images/icon_3st.svg'
import { ReactComponent as TimeUnselect } from 'assets/images/timeUnselect.svg'
import { ReactComponent as TeamIcon } from 'assets/images/team_icon.svg'
import { ReactComponent as ColumnsIcon } from 'assets/images/columns.svg'
import { ReactComponent as FilterOpen } from 'assets/images/filter_open.svg'
import { ReactComponent as FilterClosed } from 'assets/images/filter_closed.svg'

export type TIcon =
  | 'add'
  | 'remove'
  | 'arrowDown'
  | 'arrowSort'
  | 'basket'
  | 'discharge'
  | 'purchase'
  | 'close'
  | 'dischargeOutline'
  | 'ellipsis'
  | 'exit'
  | 'shop'
  | 'login'
  | 'logo'
  | 'logoSmall'
  | 'next'
  | 'normalDischarge'
  | 'plusIcon'
  | 'point'
  | 'pointIcon'
  | 'profileIcon'
  | 'requestsUserIcon'
  | 'rouletteIcon'
  | 'tcentIcon'
  | 'timeOffRequests'
  | 'timeIcon'
  | 'timeIconDisable'
  | 'settings'
  | 'users'
  | 'updateIcon'
  | 'doubleArrow'
  | 'logoCollapsed'
  | 'profile'
  | 'restoreIcon'
  | 'updateDisabledIcon'
  | 'dischargeOutlineDisabled'
  | 'arrowLeftIcon'
  | 'arrowRightIcon'
  | 'pointEditIcon'
  | 'backArrowIcon'
  | 'buttonFilter'
  | 'filterIcon'
  | 'filterDisabledIcon'
  | 'iconTableComment'
  | 'iconTableCommentDisabled'
  | 'iconTableClose'
  | 'FAQ'
  | 'FaqLogo'
  | 'iconFaqClose'
  | 'iconFaqOpen'
  | 'starSmallActive'
  | 'starSmallPassive'
  | 'usersRating'
  | 'firstPlace'
  | 'secondPlace'
  | 'thirdPlace'
  | 'TimeUnselect'
  | 'TeamIcon'
  | 'columns'
  | 'filterOpen'
  | 'filterClosed'

export type TIcons = { [key: string]: ReactNode }
export type TIconsProps = {
  icon: TIcon
} & HTMLAttributes<HTMLElement>

const icons: TIcons = {
  add: <Add />,
  remove: <Remove />,
  arrowDown: <ArrowDown />,
  arrowSort: <ArrowSort />,
  basket: <Basket />,
  discharge: <Discharge />,
  purchase: <Purchases />,
  close: <Close />,
  dischargeOutline: <DischargeOutline />,
  ellipsis: <Ellipsis />,
  exit: <Exit />,
  faq: <FAQ />,
  shop: <Shop />,
  login: <Login />,
  logo: <Logo />,
  logoSmall: <LogoSmall />,
  next: <Next />,
  normalDischarge: <NormalDischarge />,
  plusIcon: <PlusIcon />,
  point: <Point />,
  pointIcon: <PointIcon />,
  profileIcon: <ProfileIcon />,
  requestsUserIcon: <RequestsUserIcon />,
  rouletteIcon: <RouletteIcon />,
  tcentIcon: <TcentIcon />,
  timeOffRequests: <TimeOffRequests />,
  timeIcon: <TimeIcon />,
  timeIconDisable: <TimeIconDisable />,
  settings: <Settings />,
  users: <Users />,
  updateIcon: <UpdateIcon />,
  doubleArrow: <DoubleArrow />,
  logoCollapsed: <LogoCollapsed />,
  profile: <Profile />,
  restoreIcon: <RestoreIcon />,
  updateDisabledIcon: <UpdateDisabledIcon />,
  dischargeOutlineDisabled: <DischargeOutlineDisabled />,
  arrowLeftIcon: <ArrowLeftIcon />,
  arrowRightIcon: <ArrowRightIcon />,
  pointEditIcon: <PointEditIcon />,
  backArrowIcon: <BackArrowIcon />,
  buttonFilter: <ButtonFilterIcon />,
  filterIcon: <Filter />,
  filterDisabledIcon: <FilterDisabled />,
  iconTableComment: <Comment />,
  iconTableCommentDisabled: <CommentDisabled />,
  iconTableClose: <CloseTable />,
  FAQ: <FAQ />,
  FaqLogo: <FaqLogo />,
  iconFaqClose: <IconFaqClose />,
  iconFaqOpen: <IconFaqOpen />,
  starSmallActive: <StarSmallActive />,
  starSmallPassive: <StarSmallPassive />,
  usersRating: <UsersRating />,
  firstPlace: <FirstPlace />,
  secondPlace: <SecondPlace />,
  thirdPlace: <ThirdPlace />,
  timeUnselect: <TimeUnselect />,
  TeamIcon: <TeamIcon />,
  columns: <ColumnsIcon />,
  filterOpen: <FilterOpen />,
  filterClosed: <FilterClosed />
}

export const Icons: FC<TIconsProps> = ({ icon, ...rest }) => {
  return (
    <i aria-hidden="true" {...rest}>
      {icons[icon]}
    </i>
  )
}
