import React, { CSSProperties, FC } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { config } from '../../../globalConfigs'
import { Select } from 'Select' // TODO -- rename alias

import styles from './DatePicker.module.scss'
import { Icons } from '../../Icons'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import ReactTooltip from 'react-tooltip'

export type TDatepickerProps = ReactDatePickerProps & {
  locale?: string
  dateFormat?: string
  isNextYear?: boolean
  isInvalid?: undefined | string
  errorMessage?: undefined | string
}

export const DatePicker: FC<TDatepickerProps> = ({
  locale = 'ru',
  dateFormat = config.dateFormat,
  isNextYear = false,
  ...rest
}) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const range = (start: number, stop: number, step = 1) =>
    Array(stop - start)
      .fill(start)
      .map((x, y) => x + y * step)

  const years = isNextYear
    ? range(1950, new Date().getFullYear() + 2, 1).reverse()
    : range(1950, new Date().getFullYear() + 1, 1).reverse()

  const months = [
    { value: 'January', label: words['January'] },
    { value: 'February', label: words['February'] },
    { value: 'March', label: words['March'] },
    { value: 'April', label: words['April'] },
    { value: 'May', label: words['May'] },
    { value: 'June', label: words['June'] },
    { value: 'July', label: words['July'] },
    { value: 'August', label: words['August'] },
    { value: 'September', label: words['September'] },
    { value: 'October', label: words['October'] },
    { value: 'November', label: words['November'] },
    { value: 'December', label: words['December'] }
  ]

  const yearStyles = {
    container: (style: CSSProperties) => ({
      ...style,
      width: '70px',
      height: '20px'
    }),
    control: (style: CSSProperties, state: any) => ({
      ...style,
      border: 'none',
      minHeight: '20px',
      boxShadow: state.isFocused && 'none'
    }),
    indicatorsContainer: (style: CSSProperties) => ({ ...style, div: { padding: '0 0 7px 0' } }),
    singleValue: (style: any) => ({ ...style, top: '38%', fontWeight: '600' }),
    dropdownIndicator: (style: CSSProperties) => ({ ...style, color: '#3737ED' })
  }

  const monthStyles = {
    indicatorsContainer: (style: CSSProperties) => ({ ...style, display: 'none' })
  }

  return (
    <div data-tip="" data-for="picker">
      <ReactDatePicker
        className={rest.isInvalid && styles['invalid-border']}
        locale={locale}
        dateFormat={dateFormat}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <button
              className={styles.btn}
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <Icons icon="arrowLeftIcon" />
            </button>
            <Select
              styles={{ ...yearStyles, ...monthStyles }}
              options={months}
              value={months[new Date(date).getMonth()]}
              onChange={(val: any) => changeMonth(months.indexOf(val))}
              menuIsOpen={false}
              isSearchable={false}
              emptyMessage={words['noOption']}
            />

            <button
              className={styles.btn}
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <Icons icon="arrowRightIcon" />
            </button>

            <Select
              styles={yearStyles}
              options={years.map(item => ({ value: item, label: item }))}
              value={{
                value: String(new Date(date).getFullYear()),
                label: String(new Date(date).getFullYear())
              }}
              onChange={(val: any) => changeYear(Number(val.value))}
              isSearchable={false}
              emptyMessage={words['noOption']}
            />
          </div>
        )}
        {...rest}
      />
      {rest.isInvalid && rest.errorMessage && (
        <ReactTooltip
          id="picker"
          place="bottom"
          type="error"
          effect="solid"
          getContent={() => rest.errorMessage}
          globalEventOff="click"
          class={styles.tooltip}
        />
      )}
    </div>
  )
}
