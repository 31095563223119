import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import TeamComponent from './Team'
import { TState } from 'store'
import { getTeamUsers } from './Team.thunx'
import { getSelectOptions } from '../EditProfile/EditProfile.thunx'

export const mapStateToProps = (state: TState) => {
  return {
    data: state.client.team.results,
    totalData: state.client.team.total
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTeamUsers,
      getSelectOptions
    },
    dispatch
  )

export const Team = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamComponent)
