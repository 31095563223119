import {
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_REQUEST,
  GET_FOREIGN_PROFILE_REQUEST,
  GET_FOREIGN_PROFILE_SUCCESS,
  GET_FOREIGN_PROFILE_ERROR
} from './Team.constants'
import { action } from 'store/store.utils'
import { AxiosError } from 'axios'
import { IActionOnlyType } from 'global.model'

export const getUsersRequest = (): IActionOnlyType => action(GET_USERS_REQUEST)
export const getUsersSuccess = (payload: { results: any[]; total: number }) =>
  action(GET_USERS_SUCCESS, payload)
export const getUsersError = (payload: AxiosError) => action(GET_USERS_ERROR, payload)

export const getForeignProfileRequest = (): IActionOnlyType => action(GET_FOREIGN_PROFILE_REQUEST)
export const getForeignProfileSuccess = (payload: any) =>
  action(GET_FOREIGN_PROFILE_SUCCESS, payload)
export const getForeignProfileError = (payload: AxiosError) =>
  action(GET_FOREIGN_PROFILE_ERROR, payload)
