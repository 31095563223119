import React, { FC, useState } from 'react'
import styles from './CreateReview.module.scss'
import { RateStars } from '../RateStars'
import { Textarea } from '../../../Textarea'
import { Button } from 'components/UiKit/Button'
import { useUser } from '../../../../hooks/useUser'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export type TCreateReviewProps = {
  onSubmit: (body: { comment: string; rating: number | null }, authorReview: TAutorReview) => void
}

export type TAutorReview = {
  id: number | undefined
  fullName: string | undefined
  photo: string | undefined
}

export const CreateReview: FC<TCreateReviewProps> = ({ onSubmit }) => {
  const { userData } = useUser()
  const authorReview = {
    id: userData.data && userData.data.id,
    fullName: userData.data && userData.data.fullName,
    photo: userData.data && userData.data.photo
  }
  const [comment, setComment] = useState('')
  const [rating, setRating] = useState<number | null>(0)
  const words = useSelector((state: TState) => state.global.language.words)

  const handlerRate = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setRating(Number(event.currentTarget && event.currentTarget.value))
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>{words['user.pointsSystem.market.table.createRewiew']}</div>

        <RateStars ratingState={rating} onChange={handlerRate} />

        <div className={styles['comment-container']}>
          <Textarea
            name="comment"
            title={words['user.pointsSystem.market.table.rewiew']}
            value={comment}
            onChange={(event: React.BaseSyntheticEvent) => setComment(event.currentTarget.value)}
          />
        </div>

        <div className={styles['btn-container']}>
          <Button
            outline={true}
            className={styles.btn}
            onClick={() => {
              onSubmit({ comment, rating }, authorReview)
              setComment('')
              setRating(null)
            }}
          >
            {words['user.basket.send']}
          </Button>
        </div>
      </div>
    </>
  )
}
