import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { SettingsPointsSystem as SettingsPointsSystemComponent } from './SettingsPointsSystem'
import {
  getPointsSettings,
  updatePointsSettings,
  updateSettingsPointsBalance
} from './SettingsPointsSystem.thunx'
import { TState } from 'store'
import { getUsersList } from 'screens/client/Profile/Profile.thunx'

export const mapStateToProps = (state: TState) => ({
  settingsPointsSystem: state.admin.settings.pointsSystem.result,
  usersList: state.client.profile.usersList
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPointsSettings,
      updatePointsSettings,
      getUsersList,
      updateSettingsPointsBalance
    },
    dispatch
  )

export const SettingsPointsSystem = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsPointsSystemComponent)
