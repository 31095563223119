/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-key */
import React, { FC, useEffect, useState } from 'react'
import {
  Column,
  useBlockLayout,
  useTable,
  useRowSelect,
  UseTableRowProps,
  useExpanded,
  useFlexLayout,
  useResizeColumns
} from 'react-table'
import styles from './Table.module.scss'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ERowSize } from 'globalConfigs'
import cls from 'classnames'

type TGetDataOptions = {
  page: number
}

type TTableProps = {
  columns: Array<Column<any>>
  data: any[]
  total: number
  virtualScroll?: boolean
  getData: (options: TGetDataOptions) => void
  noData?: string
  limit?: number
  onRowClick?: (row: any) => any
  onSelectedRows?: (selected: Array<UseTableRowProps<any>>) => void
  rowSize?: ERowSize
  classNameToRow?: string | undefined
  hiddenColumns?: any
  lang?: any
}

export const Table: FC<TTableProps> = ({
  columns,
  data,
  virtualScroll = true,
  getData,
  noData = 'Нет данных',
  limit = 10,
  total,
  onRowClick,
  onSelectedRows,
  rowSize = ERowSize.DEFAULT,
  classNameToRow = ''
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows
  } = useTable(
    {
      columns,
      data
    },
    useRowSelect,
    useBlockLayout,
    useExpanded
  )

  useEffect(() => {
    if (selectedFlatRows && onSelectedRows) {
      onSelectedRows(selectedFlatRows)
    }
  }, [selectedFlatRows])

  useEffect(() => {
    getData({
      page: currentPage
    })
  }, [currentPage])

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)

      return (
        <div
          onClick={() => {
            if (onRowClick) {
              onRowClick(row)
            }
          }}
          {...row.getRowProps({
            style
          })}
          className={cls({
            [styles.bodyTr]: true,
            [classNameToRow]: true
          })}
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className={styles.bodyTd}>
                {typeof cell.value === 'string' || typeof cell.value === 'number' ? (
                  <div title={`${cell.value}`} className={styles.bodyTdText}>
                    {cell.render('Cell')}
                  </div>
                ) : (
                  cell.render('Cell')
                )}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

  const isDataEmpty = data.length
  const renderTable = (
    <div {...getTableProps()} className={styles.table}>
      <div className={styles.header}>
        {headerGroups.map((headerGroup, headerGroupIndex) => {
          return (
            <div className={styles.headerTr} key={`${headerGroup.id}_${headerGroupIndex}`}>
              {headerGroup.headers.map(column => (
                <div
                  className={styles.headerTh}
                  {...column.getHeaderProps()}
                  style={{
                    ...column.getHeaderProps().style,
                    cursor: isDataEmpty ? 'auto' : 'not-allowed'
                  }}
                >
                  {isDataEmpty ? (
                    column.render('Header')
                  ) : (
                    <div style={{ pointerEvents: 'none' }}>{column.render('Header')}</div>
                  )}
                </div>
              ))}
            </div>
          )
        })}
      </div>
      <div
        {...getTableBodyProps()}
        className={styles.body}
        style={{ height: '50vh', width: '100%' }}
      >
        {isDataEmpty ? (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                itemCount={rows.length}
                itemSize={rowSize}
                width={width}
                onItemsRendered={({ overscanStopIndex }) => {
                  const page = Math.round(overscanStopIndex / limit)
                  const maxPages = Math.ceil(total / limit)

                  if (page < maxPages) {
                    setCurrentPage(page + 1)
                  }
                }}
              >
                {RenderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        ) : (
          <div className={styles['no-data']}>{noData}</div>
        )}
      </div>
    </div>
  )

  // TODO --- add base table
  return virtualScroll ? renderTable : renderTable
}

export const TeamTable: FC<TTableProps> = ({
  columns,
  data,
  getData,
  noData = 'Нет данных',
  limit = 10,
  total,
  onRowClick,
  onSelectedRows,
  rowSize = ERowSize.DEFAULT,
  classNameToRow = '',
  hiddenColumns,
  lang = 'en'
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [columnWidth, setColumnWidth] = useState<number>(0)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows,
    setHiddenColumns
  } = useTable(
    {
      columns,
      data
    },
    useRowSelect,
    useFlexLayout,
    // useBlockLayout,
    useExpanded,
    useResizeColumns
  )

  useEffect(() => {
    if (selectedFlatRows && onSelectedRows) {
      onSelectedRows(selectedFlatRows)
    }
  }, [selectedFlatRows])

  useEffect(() => {
    getData({
      page: currentPage
    })
  }, [currentPage])

  useEffect(() => {
    if (hiddenColumns) {
      setHiddenColumns(hiddenColumns)
    }
  }, [hiddenColumns])
  const tableWidthAdjustment = lang === 'ru' ? 51 : 91
  let widthColumn = tableWidthAdjustment
  useEffect(() => {
    columns.map((item: any) => {
      if (!hiddenColumns.includes(item.accessor)) {
        if (item.width) {
          widthColumn += item.width
        } else {
          widthColumn += item.minWidth
        }
      }
    })
    setColumnWidth(widthColumn)
  }, [columns, hiddenColumns])

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)

      return (
        <div
          onClick={() => {
            if (onRowClick) {
              onRowClick(row)
            }
          }}
          {...row.getRowProps({
            style
          })}
          className={cls({
            [styles.bodyTrTeam]: true,
            [classNameToRow]: true
          })}
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className={styles.bodyTd}>
                {typeof cell.value === 'string' || typeof cell.value === 'number' ? (
                  <div title={`${cell.value}`} className={styles.bodyTdText}>
                    {cell.render('Cell')}
                  </div>
                ) : (
                  cell.render('Cell')
                )}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

  const isDataEmpty = data.length
  const renderTable = (
    <div {...getTableProps()} className={styles.teamTable}>
      <div className={styles.header}>
        {headerGroups.map((headerGroup, headerGroupIndex) => {
          return (
            <div className={styles.headerTrTeam} key={`${headerGroup.id}_${headerGroupIndex}`}>
              {headerGroup.headers.map(column => (
                <div
                  className={styles.headerTh}
                  {...column.getHeaderProps()}
                  style={{
                    ...column.getHeaderProps().style,
                    cursor: isDataEmpty ? 'auto' : 'not-allowed'
                  }}
                >
                  {isDataEmpty ? (
                    column.render('Header')
                  ) : (
                    <div style={{ pointerEvents: 'none' }}>{column.render('Header')}</div>
                  )}
                </div>
              ))}
            </div>
          )
        })}
      </div>
      <div
        {...getTableBodyProps()}
        className={styles.bodyTeam}
        style={{ height: '50vh', width: '100%' }}
      >
        {isDataEmpty ? (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                itemCount={rows.length}
                itemSize={rowSize}
                width={width > columnWidth ? width : columnWidth}
                onItemsRendered={({ overscanStopIndex }) => {
                  const page = Math.round(overscanStopIndex / limit)
                  const maxPages = Math.ceil(total / limit)

                  if (page < maxPages) {
                    setCurrentPage(page + 1)
                  }
                }}
              >
                {RenderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        ) : (
          <div className={styles['no-data']}>{noData}</div>
        )}
      </div>
    </div>
  )

  return renderTable
}
