import {
  GET_REQUESTS_REQUEST,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_ERROR,
  ACTION_REQUESTS_REQUEST,
  ACTION_REQUESTS_SUCCESS,
  ACTION_REQUESTS_ERROR,
  CLEAR_REQUESTS_DATA
} from './Requests.constants'
import { AxiosError } from 'axios'
import { action } from 'store/store.utils'
import { TRequestsResponse } from './Requests.model'

export const getRequestsRequest = () => action(GET_REQUESTS_REQUEST)
export const getRequestsSuccess = (payload: TRequestsResponse) =>
  action(GET_REQUESTS_SUCCESS, payload)
export const getRequestsError = (payload: AxiosError) => action(GET_REQUESTS_ERROR, payload)

export const requestsActionRequest = () => action(ACTION_REQUESTS_REQUEST)
export const requestsActionSuccess = () => action(ACTION_REQUESTS_SUCCESS)
export const requestsActionError = (payload: AxiosError) => action(ACTION_REQUESTS_ERROR, payload)

export const clearRequestsData = () => action(CLEAR_REQUESTS_DATA)
