import React, { FC, useEffect, useState } from 'react'
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom'
import PrivateRoute from '../PrivatRoute'
import { Dashboard } from './components/Dashboard'
import AuthRoute from '../AuthRoute'
import { Login } from 'screens/auth/Login'
import { adminRoutes, clientRoutes } from './App.routes'
import { mapStateToProps, mapDispatchToProps } from './App.container'
import { jwtDecode } from 'utils/jwt'
import { adminSideBar, clientSideBar, TSideBarMenu } from '../SideBar/SideBar.config'
import styles from './App.module.scss'
import {
  defaultContextValue,
  isManager,
  isUser,
  TUserDataWithoutTokens,
  isAdmin,
  isSuperAdmin,
  TContextValue,
  UserContext
} from 'utils/user'
import { ELanguages } from 'store/global'
import UncontrolledLottie from '../Lotties/UncontrolledLottie'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type Props = RouteComponentProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export type TLanguageObject = {
  fetchInfo: (language: string) => void
  languages: string[]
  currentLanguage: string
}

export const App: FC<Props> = props => {
  const { currentLanguage, loading, error, fetchInformation, accessToken } = props
  const [isAdminSide, setAdminSide] = useState<boolean | null>(null)
  const [contextValue, setContextValue] = useState<TContextValue>(defaultContextValue)
  const words = useSelector((state: TState) => state.global.language.words)
  const technorelyLanguage = 'TechnorelyLanguage'

  const fetchInfo = (language: string) => {
    localStorage.setItem(technorelyLanguage, language)
    fetchInformation(language)
  }

  const fetchInformationWithSave = (language: string) => {
    const currentLang = localStorage.getItem(technorelyLanguage)
    if (currentLang) {
      fetchInformation(currentLang)
    } else {
      localStorage.setItem(technorelyLanguage, language)
      fetchInformation(language)
    }
  }

  const languageObject = {
    fetchInfo,
    languages: Object.values(ELanguages),
    currentLanguage
  }

  useEffect(() => {
    document.cookie = `r_g=${window.location.origin}; path=/;`
  }, [])

  useEffect(() => {
    fetchInformationWithSave(currentLanguage)
  }, [currentLanguage])

  useEffect(() => {
    let userData

    if (accessToken) {
      userData = jwtDecode(accessToken) as TUserDataWithoutTokens | null
    }

    if (userData) {
      // TODO -- create context
      const role = isSuperAdmin(userData.role) || isAdmin(userData.role)
      if (isAdminSide !== role) {
        setAdminSide(role)
      }

      setContextValue({
        data: userData,
        superAdmin: isSuperAdmin(userData.role),
        admin: isAdmin(userData.role),
        manager: isManager(userData.role),
        user: isUser(userData.role)
      } as TContextValue)
    }
  }, [accessToken])

  const routes = isAdminSide === null ? [] : isAdminSide ? adminRoutes : clientRoutes
  const sideBarMenu =
    isAdminSide === null
      ? ({} as TSideBarMenu)
      : isAdminSide
      ? adminSideBar(words)
      : clientSideBar(words)
  const successPath = isAdminSide ? '/dashboard/requests?status=1' : '/dashboard/profile'
  const isSignin = window.location.href.includes('signin')

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        document.location.reload()
      }, 2000)
    }
  }, [error])

  const content = () => {
    if ((loading || error) && !isSignin) {
      return (
        <div className={styles.loadingDiv}>
          <div className={styles.loading}>
            <UncontrolledLottie />
          </div>
        </div>
      )
    }
    return (
      <UserContext.Provider value={contextValue}>
        <Switch>
          <PrivateRoute
            sideBarMenu={sideBarMenu}
            routes={routes}
            path="/dashboard"
            component={Dashboard}
            successPath={successPath}
            languageObject={languageObject}
          />
          <AuthRoute path="/signin" component={Login} successPath={successPath} />

          <Route render={() => <Redirect to="/signin" />} />
        </Switch>
      </UserContext.Provider>
    )
  }

  return content()
}
