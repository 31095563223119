import React, { FC, useState, useEffect, useRef, useCallback } from 'react'
import { Form, Field } from 'react-final-form'
import { IGoodsItem } from './GoodsItem.model'
import { Button } from 'components/UiKit/Button'
import { Input } from 'components/UiKit/Inputs'
import { BounceLoader } from 'components/BounceLoader/model'
import styles from './GoodsItem.module.scss'
import { Select } from 'Select'
import { Textarea } from 'components/Textarea'
import cn from 'classnames'
import { ReactComponent as AddImgIcon } from 'assets/images/add_img_icon.svg'
import ReactTooltip from 'react-tooltip'
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import validation from 'utils/validation'
import { ADMIN_ROLE, config } from 'globalConfigs'
import { ReactComponent as IconDelete } from 'assets/images/delete_icon.svg'
import { Icons } from '../../../components/Icons'
import { imageToBlob } from '../../../utils/imageThroughCanvasToBlob'
import { Reviews } from '../../../components/Reviews/Reviews'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TOptions = {
  value: string
  label: string
}

const TYPE_SELECT_PEOPLE: TOptions[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' }
]

export const GoodsItem: FC<IGoodsItem> = ({
  data,
  setCreateItem,
  getGoodsCategories,
  getCurrentItem,
  removeReview,
  clearGoodData,
  match,
  history,
  goodsCategories,
  setNewCategory,
  deleteGoodsCategory,
  loading
}) => {
  const isEdit = !!match.params.id
  const deleteCategory = useCallback(deleteGoodsCategory, [])
  const words = useSelector((state: TState) => state.global.language.words)

  useEffect(() => {
    getData()

    if (isEdit && isNaN(Number(match.params.id))) {
      history.push('/dashboard/point-system/store')
    }

    if (isEdit) {
      const { id } = match.params
      getCurrentItem(id)
    }
    return () => {
      clearGoodData()
    }
  }, [])

  const [crop, setCrop] = useState<Crop>()
  const [src, setSrc] = useState()
  const [userFileProps, setUserFileProps] = useState({ name: 'name', type: 'image/jpg' })
  const [croppedImage, setCroppedImage] = useState()
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const newCategoryRef = useRef<HTMLInputElement>(null)

  const [originalBlob, setOriginalBlob] = useState()
  const [originalImage, setOriginalImage] = useState()

  const blobToBase64 = (blob: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      setSrc(reader.result)
    }
    setOriginalImage([new File([blob], 'originalPhoto', { type: blob.type })])
  }

  useEffect(() => {
    isEdit &&
      fetch(data.originalPhoto)
        .then(res => res.blob())
        .then(res => blobToBase64(res))
        .then(() => setIsImageLoaded(true))
  }, [data])

  useEffect(() => {
    const category = goodsCategories.map((props: any) => ({
      value: String(props.id),
      label: props.name
    }))

    setCategories(category)
  }, [goodsCategories])

  useEffect(() => {
    if (isEdit && data && data.photo) {
      const [firstPhoto] = data.photo
      const fileType: RegExpMatchArray | null = firstPhoto.match(config.fileTypeRegExp)
      const fileName: RegExpMatchArray | null = firstPhoto.match(config.fileNameRegExp)

      setUserFileProps({
        name: fileName ? fileName[1] : 'fileName',
        type: `image/${fileType ? fileType[1] : 'image/jpg'}`
      })
      setSrc(firstPhoto)
    }
  }, [isEdit, data])

  useEffect(() => {
    setOriginalImage([new File([originalBlob], userFileProps.name, { type: userFileProps.type })])
  }, [originalBlob])

  const [categories, setCategories] = useState<TOptions[]>([])

  const handleCatrgories = (categoryName: string) => {
    setCategories([...categories, { value: `${categories.length + 1}`, label: categoryName }])
    setNewCategory(categoryName, 1)
  }

  const getData = () => {
    getGoodsCategories()
  }

  const goodsCategoriesForSelect = () => {
    return goodsCategories.map((item: any) => ({
      value: item.id,
      label: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {item.name}
          <span
            data-id={item.id}
            style={{ cursor: 'pointer' }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              deleteCategory(item.id)
            }}
          >
            {<IconDelete />}
          </span>
        </div>
      )
    }))
  }

  const onImageLoaded = (image: HTMLImageElement) => {
    image ? setIsImageLoaded(true) : setIsImageLoaded(false)
    const width = image.width > image.height ? (image.height / image.width) * 100 : 100
    const height = image.height > image.width ? (image.width / image.height) * 100 : 100
    const x = width === 100 ? 0 : (100 - width) / 2
    const y = height === 100 ? 0 : (100 - height) / 2
    setCrop({
      unit: '%',
      aspect: 1 / 1.022641509,
      width,
      height,
      x,
      y
    })

    // for default crop
    onCropComplete({
      unit: '%',
      aspect: 1 / 1.022641509,
      width,
      height,
      x,
      y
    })

    return false
  }

  const onSelectFile = ({ onChange }: any) => (e: any) => {
    const f = e.target.files[0]
    imageToBlob(window.URL.createObjectURL(f), b => {
      b && setOriginalBlob(b)
    })
    const reader = new FileReader()
    reader.addEventListener('load', () => setSrc(reader.result))
    reader.readAsDataURL(e.target.files[0])
    setUserFileProps({ name: e.target.files[0].name, type: e.target.files[0].type })
    onChange(e)
  }

  const onCropComplete = (imgCrop: any) => {
    makeClientCrop(imgCrop)
  }

  const makeClientCrop = async (imgCrop: any) => {
    const img: any = document.getElementsByClassName('ReactCrop__image')[0]
    if (src && imgCrop.width && imgCrop.height) {
      const cropedImage: any = await getCroppedImg(img, imgCrop, userFileProps.name)
      setCroppedImage([new File([cropedImage], userFileProps.name, { type: cropedImage.type })])
    }
  }

  const getCroppedImg = (image: any, imgCrop: any, fileName: any) => {
    const canvas: any = document.createElement('canvas')
    const scaleX: any = image.naturalWidth / image.width
    const scaleY: any = image.naturalHeight / image.height
    canvas.width = imgCrop.width
    canvas.height = imgCrop.height
    const ctx: any = canvas.getContext('2d')

    ctx.drawImage(
      image,
      imgCrop.x * scaleX,
      imgCrop.y * scaleY,
      imgCrop.width * scaleX,
      imgCrop.height * scaleY,
      0,
      0,
      imgCrop.width,
      imgCrop.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob: any) => {
          if (!blob) {
            reject(new Error('Canvas is empty'))
            return
          }
          blob.name = fileName
          resolve(blob)
        },
        userFileProps.type,
        1
      )
    })
  }

  const formDataAppendFiles = (body: any, croppedFiles: any, originalFiles: any) => {
    croppedFiles.map((item: any) => {
      body.append(`croppedPhoto`, item)
    })
    originalFiles.map((item: any) => {
      body.append(`originalPhoto`, item)
    })
  }
  // const formDataAppendFiles = (body: any, files: any) => {
  //   files.map((item: any, index: any) => {
  //     body.append(`photo${index}`, item)
  //   })
  // }

  // TODO -- move to utils
  // const formDataFormatter (data:any) => {
  //   const fd = new FormData()
  //   Object.entries(data).forEach((item: any) => {
  //     fd.append(...item)
  //   })
  //   return fd
  // }

  const handleCreateRequest = (values: any) => {
    // TODO -- move formatting to side effect
    const body = new FormData()
    body.append('name', values.name)
    body.append('categoryId', values.categoryId.value)
    body.append('price', values.price)
    body.append('numberOfPeople', values.numberOfPeople.value)
    body.append('description', values.description)
    formDataAppendFiles(body, croppedImage, originalImage)
    setCreateItem(body, match.params.id)
  }

  const onRemove = (id: number) => {
    removeReview(id, ADMIN_ROLE)
  }
  const cancelHandler = () => {
    history.push('/dashboard/point-system/store')
  }
  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h1>{isEdit ? words['admin.shop.new.editGoods'] : words['admin.shop.new.createGoods']}</h1>
      </div>

      <section>
        <div className={styles['block-go-back']}>
          <button
            type="button"
            className={styles['go-back']}
            onClick={event => {
              event.preventDefault()
              history.goBack()
            }}
          >
            <Icons icon="backArrowIcon" />
            <span className={styles['go-back-text']}>{words['back']}</span>
          </button>
        </div>
      </section>

      <Form
        initialValues={
          isEdit
            ? {
                name: data.name || '',
                categoryId:
                  {
                    value: data.categoryId && data.categoryId.id,
                    label: data.categoryId && data.categoryId.name
                  } || '',
                price: data.price || '',
                description: data.description || '',
                numberOfPeople: { value: data.numberOfPeople, label: data.numberOfPeople } || '',
                lag: { value: data.lag, label: data.lag } || ''
              }
            : {}
        }
        onSubmit={handleCreateRequest}
        render={({ form, handleSubmit }) => {
          const formState = form.getState()
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles['content-container']}>
                <Field
                  name={'photo'}
                  validate={
                    isEdit
                      ? validation.composeValidators(() =>
                          validation.required(words['user.requiredMessage'])(croppedImage)
                        )
                      : validation.composeValidators(
                          validation.required(words['user.requiredMessage'])
                        )
                  }
                  render={({ input, meta }) =>
                    isEdit && !isImageLoaded ? (
                      <div
                        className={cn({
                          [styles.emptyPicture]: true
                          // [styles.invalid]: meta.error && meta.touched
                        })}
                      >
                        <BounceLoader />
                      </div>
                    ) : (
                      <div
                        className={cn({
                          [styles.picture]: true,
                          [styles.invalid]: meta.error && meta.touched
                        })}
                        data-tip=""
                        data-for="photo"
                      >
                        <div className={styles.crop} />
                        <div>
                          <label
                            htmlFor="photo"
                            className={isImageLoaded ? styles.right : styles.center}
                          >
                            <AddImgIcon />
                            <input
                              {...input}
                              id="photo"
                              type="file"
                              onChange={onSelectFile(input)}
                            />
                          </label>

                          {meta.error && meta.touched && (
                            <ReactTooltip
                              id="photo"
                              place="top"
                              type="error"
                              effect="solid"
                              getContent={() => meta.error}
                              globalEventOff="click"
                            />
                          )}
                        </div>
                        <div className={styles.info}>
                          <div className={styles.pictureInfo}>{`${
                            words['admin.shop.new.imageFormat']
                          }: JPEG, JPG, PNG`}</div>
                          <div className={styles.pictureInfo}>{`${
                            words['admin.shop.new.imageSize']
                          }: 550 x 335 px`}</div>
                          <div className={styles.pictureInfo}>{`${
                            words['admin.shop.new.imageWeight']
                          }: ${words['user.pointsSystem.market.table.to']} 10 MB`}</div>
                        </div>
                        <ReactCrop
                          src={src}
                          crop={crop}
                          onImageLoaded={onImageLoaded}
                          onChange={(newCrop: any) => {
                            setCrop(newCrop)
                          }}
                          onComplete={onCropComplete}
                          crossorigin="anonymous"
                        />
                      </div>
                    )
                  }
                />
                <div className={styles['form-column-container']}>
                  <div className={styles['form-row-flex-container']}>
                    <Field
                      name={'name'}
                      validate={validation.composeValidators(
                        validation.required(words['user.requiredMessage']),
                        validation.minValue(2),
                        validation.maxValue(99)
                      )}
                      render={({ input, meta }) => (
                        <div className={styles['input-name']}>
                          <label className={styles['label-for-field']}>
                            {words['admin.productionCalendar.new.name']}
                            <span className={styles['red-note']}>*</span>
                          </label>
                          <Input
                            {...input}
                            variant="outlined"
                            className={styles['input']}
                            isInvalid={meta.error && meta.touched}
                            errorMessage={meta.error}
                          />
                        </div>
                      )}
                    />
                    {
                      <Field
                        name={'categoryId'}
                        validate={validation.composeValidators(
                          validation.required(words['user.requiredMessage'])
                        )}
                        render={({ input, meta }) => (
                          <div className={styles['input-categoryId']}>
                            <label className={styles['label-for-field']}>
                              {words['user.pointsSystem.market.table.category']}
                              <span className={styles['red-note']}>*</span>
                            </label>
                            <Select
                              value={input.value}
                              inRef={newCategoryRef}
                              placeholder=""
                              options={goodsCategoriesForSelect()}
                              isAddNewItem={true}
                              isInvalid={meta.error && meta.touched}
                              onChange={event => {
                                return form.change(input.name, event)
                              }}
                              emptyMessage={words['noOption']}
                              errorMessage={meta.error}
                              addCategory={handleCatrgories}
                              getData={getData}
                              isClearable={true}
                            />
                          </div>
                        )}
                      />
                    }
                  </div>

                  <div className={styles['form-row-flex-container']}>
                    <Field
                      name={'price'}
                      validate={validation.composeValidators(
                        validation.required(words['user.requiredMessage']),
                        validation.isNumber(),
                        validation.isInteger(),
                        validation.min(0),
                        validation.max(9999)
                      )}
                      render={({ input, meta }) => (
                        <div className={styles['input-price']}>
                          <label className={styles['label-for-field']}>
                            {words['user.pointsSystem.market.table.price']}
                            <span className={styles['red-note']}>*</span>
                          </label>
                          <Input
                            {...input}
                            variant="outlined"
                            type="number"
                            className={styles['input']}
                            isInvalid={meta.error && meta.touched}
                            errorMessage={meta.error}
                          />
                        </div>
                      )}
                    />

                    <Field
                      name={'numberOfPeople'}
                      validate={validation.composeValidators(
                        validation.required(words['user.requiredMessage'])
                      )}
                      render={({ input, meta }) => (
                        <div className={styles['input-people']}>
                          <label className={styles['label-for-field']}>
                            {words['user.profile.table.numberOfPeople']}
                            <span className={styles['red-note']}>*</span>
                          </label>
                          <Select
                            {...input}
                            placeholder=""
                            options={TYPE_SELECT_PEOPLE}
                            isInvalid={meta.error && meta.touched}
                            errorMessage={meta.error}
                            // hiddenDefaultFilterOption={true}
                            onChange={event => {
                              return form.change(input.name, event)
                            }}
                            emptyMessage={words['noOption']}
                          />
                        </div>
                      )}
                    />
                  </div>

                  <Field
                    name={'description'}
                    validate={validation.composeValidators(
                      validation.required(words['user.requiredMessage']),
                      validation.maxValue(1000)
                    )}
                    render={({ input, meta }) => (
                      <div>
                        <label className={styles['label-for-field']}>
                          {words['admin.productionCalendar.new.description']}
                          <span className={styles['red-note']}>*</span>
                        </label>
                        <Textarea
                          {...input}
                          placeholder={`${words['admin.shop.new.descriptionPlaceholder']}...`}
                          isInvalid={meta.error && meta.touched}
                          errorMessage={meta.error}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className={styles['footer-btn-container']}>
                {isEdit && (
                  <Button onClick={cancelHandler} disabled={false}>
                    {words['admin.productionCalendar.new.buttons.cancel']}
                  </Button>
                )}

                <Button type="submit" disabled={!formState.valid || loading}>
                  {isEdit
                    ? words['admin.productionCalendar.new.buttons.save']
                    : words['user.dayOffTracker.create']}
                </Button>
              </div>
            </form>
          )
        }}
      />
      {isEdit && (
        <Reviews
          reviews={data && data.reviews}
          isRemovable={true}
          onRemove={onRemove}
          isCreatable={false}
        />
      )}
    </section>
  )
}
