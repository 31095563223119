import React, { FC } from 'react'
import { IBasketName } from './BasketName.model'
import styles from '../../Basket.module.scss'

export const BasketName: FC<IBasketName> = ({ photo, name, category }) => {
  return (
    <div className={styles['basket-element']}>
      <div>
        <img src={photo} alt="" />
      </div>

      <div className={styles['basket-name-category']}>
        <div>{name}</div>

        <div>{category}</div>
      </div>
    </div>
  )
}
