export enum ELanguages {
  RU = 'ru',
  EN = 'en'
}
export interface IInitialState {
  words: {}
  error: string
  loading: boolean
  languages: { [key in ELanguages]: string }
  currentLanguage: ELanguages
}
