import React, { FC } from 'react'
import { Field, Form } from 'react-final-form'
import styles from './SettingsTimeOfRequestForm.module.scss'
import cls from 'classnames'
import { Select } from 'Select'
import { config, FILTER_SELECT_PRODUCT_CALENDAR_TYPE } from 'globalConfigs'
import { Button } from 'components/UiKit/Button'
import { DatePicker } from 'components/UiKit/DatePicker'
import { Input } from 'components/UiKit/Inputs'
import {
  setProductCalendar,
  TSetProductCalendarBody
} from '../SettingsTimeOfRequest/SettingsTimeOfRequest.thunx'
import { TSelectOption } from '../../../../../components/Select'
import { Icons } from '../../../../../components/Icons'
import { addCurrentTime, formatterDateForDatePicker } from 'components/UiKit/Filter/Filter'
import validation from '../../../../../utils/validation'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TSettingsTimeOfRequestFormProps = {
  save: typeof setProductCalendar
}

export const SettingsTimeOfRequestForm: FC<TSettingsTimeOfRequestFormProps> = ({ save }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)

  const defaultField = {
    type: 1,
    description: '',
    date: formatterDateForDatePicker(addCurrentTime(new Date()))
  }

  return (
    <Form
      initialValues={{
        fields: [defaultField]
      }}
      onSubmit={values => {
        save((values as { fields: TSetProductCalendarBody[] }).fields)
      }}
    >
      {({ form, handleSubmit }) => {
        const formState = form.getState()

        return (
          <>
            <header className={styles.header}>
              {words['admin.productionCalendar.new.header']}
            </header>
            <form onSubmit={handleSubmit} className={styles.container}>
              <div className={styles.row}>
                <div className={styles['input-label']}>
                  {words['admin.productionCalendar.new.type']}
                </div>
                <div className={styles['input-label']}>
                  {words['admin.productionCalendar.new.name']}
                </div>
                <div className={styles['input-label']}>
                  {words['admin.productionCalendar.new.date']}
                </div>
              </div>
              <div className={styles['inputs-wrapper']}>
                {(formState.values.fields as Array<typeof defaultField>).map((_, index) => (
                  <div key={index} className={styles.row}>
                    <div className={styles.field}>
                      <Field name={`fields[${index}].type`}>
                        {({ input }) => {
                          return (
                            <Select
                              {...input}
                              placeholder={words['admin.productionCalendar.new.choiceType']}
                              value={FILTER_SELECT_PRODUCT_CALENDAR_TYPE(words).find(
                                predicate => input.value === Number(predicate.value)
                              )}
                              options={FILTER_SELECT_PRODUCT_CALENDAR_TYPE(words)}
                              onChange={event => {
                                form.change(input.name, Number((event as TSelectOption).value))
                              }}
                              emptyMessage={words['noOption']}
                            />
                          )
                        }}
                      </Field>
                    </div>
                    <div className={styles.field}>
                      <Field
                        name={`fields[${index}].description`}
                        validate={validation.composeValidators(
                          validation.required(words['user.requiredMessage'])
                        )}
                      >
                        {({ input, meta }) => (
                          <Input
                            placeholder={words['admin.productionCalendar.new.description']}
                            variant="outlined"
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                            {...input}
                          />
                        )}
                      </Field>
                    </div>
                    <div className={cls({ [styles.field]: true, [styles.date]: true })}>
                      <Field name={`fields[${index}].date`}>
                        {({ input }) => (
                          <DatePicker
                            locale={currentLanguage}
                            placeholderText={words['admin.productionCalendar.new.day']}
                            name={input.name}
                            autoComplete="off"
                            dateFormat={config.dateFormat}
                            selected={input.value ? input.value : null}
                            isNextYear={true}
                            onChange={event => {
                              if (!event) {
                                return form.change(
                                  input.name,
                                  formatterDateForDatePicker(addCurrentTime(new Date()))
                                )
                              }

                              form.change(
                                input.name,
                                formatterDateForDatePicker(addCurrentTime(event))
                              )
                            }}
                            startDate={new Date()}
                            minDate={new Date()}
                          />
                        )}
                      </Field>
                    </div>

                    <div
                      className={cls({ [styles.field]: true, [styles.remove]: true })}
                      onClick={() => {
                        form.change(
                          'fields',
                          (formState.values.fields as TSetProductCalendarBody[]).filter(
                            (_$, idx) => idx !== index
                          )
                        )
                      }}
                    >
                      <Icons icon="dischargeOutline" />
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.buttons}>
                <Button
                  onClick={() => form.change('fields', [...formState.values.fields, defaultField])}
                  className={styles.btn}
                  outline={true}
                >
                  {words['admin.productionCalendar.buttons.add']}
                </Button>
                <Button type="submit" className={styles.btn}>
                  {words['admin.productionCalendar.new.buttons.save']}
                </Button>
              </div>
            </form>
          </>
        )
      }}
    </Form>
  )
}
