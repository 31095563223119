import {
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  CLEAR_USER_DATA,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_ERROR,
  RESTORE_USERS_REQUEST,
  RESTORE_USERS_SUCCESS,
  RESTORE_USERS_ERROR,
  GET_POSITION_REQUEST,
  GET_POSITION_SUCCESS,
  GET_POSITION_ERROR,
  UPDATE_POINTS_BALANCE_REQUEST,
  UPDATE_POINTS_BALANCE_SUCCESS,
  UPDATE_POINTS_BALANCE_ERROR,
  GET_USERS_TO_FILTER_SUCCESS,
  GET_USERS_TO_FILTER_ERROR
} from './Users.constants'
import { action } from 'store/store.utils'
import { AxiosError } from 'axios'
import {
  TUserData,
  TUpdateUserPayload,
  TPositions,
  TUpdatePointsBalancePayload,
  TUsersToFilter /*, TPositions */
} from './Users.model'

export const getUsersRequest = () => action(GET_USERS_REQUEST)
export const clearUsersData = () => action(CLEAR_USER_DATA)
export const getUsersSuccess = (payload: { results: TUserData[]; total: number }) =>
  action(GET_USERS_SUCCESS, payload)
export const getUsersError = (payload: AxiosError) => action(GET_USERS_ERROR, payload)

export const updateUserRequest = () => action(UPDATE_USER_REQUEST)
export const updateUserSuccess = (payload: TUpdateUserPayload) =>
  action(UPDATE_USER_SUCCESS, payload)
export const updateUserError = (payload: AxiosError) => action(UPDATE_USER_ERROR, payload)

export const updatePointsBalanceRequest = () => action(UPDATE_POINTS_BALANCE_REQUEST)
export const updatePointsBalanceSuccess = (payload: TUpdatePointsBalancePayload) =>
  action(UPDATE_POINTS_BALANCE_SUCCESS, payload)
export const updatePointsBalanceError = (payload: AxiosError) =>
  action(UPDATE_POINTS_BALANCE_ERROR, payload)

export const deleteUsersRequest = () => action(DELETE_USERS_REQUEST)
export const deleteUsersSuccess = (id: number) => action(DELETE_USERS_SUCCESS, { id })
export const deleteUsersError = (payload: AxiosError) => action(DELETE_USERS_ERROR, payload)

export const restoreUsersRequest = () => action(RESTORE_USERS_REQUEST)
export const restoreUsersSuccess = (id: number) => action(RESTORE_USERS_SUCCESS, { id })
export const restoreUsersError = (payload: AxiosError) => action(RESTORE_USERS_ERROR, payload)

export const getPositionsRequest = () => action(GET_POSITION_REQUEST)
export const getPositionsSuccess = (payload: { results: TPositions }) =>
  action(GET_POSITION_SUCCESS, payload)
export const getPositionsError = (payload: AxiosError) => action(GET_POSITION_ERROR, payload)

export const getUsersToFilterSuccess = (payload: { results: TUsersToFilter[] }) =>
  action(GET_USERS_TO_FILTER_SUCCESS, payload)
export const getUsersToFilterError = (payload: AxiosError) =>
  action(GET_USERS_TO_FILTER_ERROR, payload)
