import React, { FC } from 'react'
import moment from 'moment'
import styles from './PersonalInfo.module.scss'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import {
  TChildrenBirthDayProps,
  TLifePrioritiesProps,
  TPersonalInfoProps
} from './PersonalInfo.model'
import { LIFE_PRIORITIES } from 'globalConfigs'
import { TSelectOption } from 'components/Select'
import { ReactComponent as LogoFile } from 'assets/images/logo-file.svg'
import { isUser } from 'utils/user'
import { FileView } from './FileView'

export const PersonalInfo: FC<TPersonalInfoProps | any> = ({
  isSameUser = false,
  dateOfBirth,
  hometown,
  maritalStatus,
  children,
  university,
  academicDegree,
  hobby,
  lifeGoals,
  lifePriorities,
  testResult,
  role,
  userfiles
}: any) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const LifePriorities: FC<TLifePrioritiesProps> = ({ priorities }) => {
    const temp: any = []
    priorities.map((item: { value: string }) => {
      const label = LIFE_PRIORITIES(words).find(
        (element: TSelectOption) => element.value === item.value
      )
      label && temp.push(label.label)
      return temp
    })
    return temp.join(', ')
  }

  const ChildrenBirthDay: FC<TChildrenBirthDayProps> = ({ childrenArr }): any => {
    return childrenArr
      .map((DOB: string) => {
        return moment(DOB).format('DD.MM.YYYY')
      })
      .join('; ')
  }

  const showHiddenInfo = isSameUser || !isUser(role)

  return (
    <>
      <div className={styles['info-section']}>
        <h2>{words['user.profile.mainInfo.personalInfo']}</h2>
        <div className={styles['info-container']}>
          <div>
            {dateOfBirth && (
              <p>
                <span>{words['user.profile.mainInfo.dob']}: </span>
                {moment(dateOfBirth).format('DD.MM.YYYY')}
              </p>
            )}
            {hometown && (
              <p>
                <span>{words['user.profile.mainInfo.nativeCity']}: </span>
                {hometown}
              </p>
            )}
            {hobby && (
              <p>
                <span>{words['user.profile.mainInfo.hobbies']}: </span>
                {hobby}
              </p>
            )}
            {lifeGoals && (
              <p>
                <span>{words['user.profile.mainInfo.lifeGoal']}: </span>
                {lifeGoals}
              </p>
            )}
          </div>
          <div>
            {university && (
              <p>
                <span>{words['user.profile.mainInfo.university']}: </span>
                {university}
              </p>
            )}
            {academicDegree && academicDegree.name && (
              <p>
                <span>{words['user.profile.mainInfo.degree']}: </span>
                {words[academicDegree.name]}
              </p>
            )}
            {lifePriorities && lifePriorities.length > 0 && (
              <p>
                <span>{words['user.profile.mainInfo.lifePriorities']}: </span>
                <LifePriorities priorities={lifePriorities} />
              </p>
            )}
            {maritalStatus && maritalStatus.name && (
              <p>
                <span>{words['user.profile.mainInfo.martialStatus']}: </span>
                {words[maritalStatus.name]}
              </p>
            )}
            {children && children.length > 0 && (
              <p>
                <span>{words['user.profile.mainInfo.numberOfChildren']}: </span>
                {children.length}
                &nbsp;
                <span className={styles['children-birth-day-field']}>
                  (<ChildrenBirthDay childrenArr={children} />)
                </span>
              </p>
            )}
            {showHiddenInfo && testResult && (
              <a className={!testResult ? styles['inactive'] : ''} href={testResult}>
                <LogoFile /> {words['user.profile.mainInfo.psychoTestResults']}.doc
              </a>
            )}
          </div>
        </div>
      </div>
      {showHiddenInfo && userfiles && userfiles.length > 0 && (
        <div className={styles['filesSection']}>
          <h2>{words['user.profile.mainInfo.files']}</h2>
          {userfiles.map((item: any, idx: number) => (
            <FileView
              isEdit={false}
              item={item}
              idx={idx}
              key={String(idx)}
              deleteFile={() => null}
            />
          ))}
        </div>
      )}
    </>
  )
}
