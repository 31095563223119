import React, { FC, useState } from 'react'
import styles from './SideBar.module.scss'
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom'
import AccordionItem from 'components/AccordionItem'
import { mapDispatchToProps } from './SideBar.container'
import { TSideBarMenu } from './SideBar.config'
import { Icons } from '../../components/Icons'
import cls from 'classnames'
import storage from 'utils/storage'
import { TLanguageObject } from 'elements/App/App'

export type TSideBarProps = {
  mainMenu: TSideBarMenu
  successPath: string
  onCollapsed: (isCollapsed: boolean) => void
  languageObject?: TLanguageObject
} & RouteComponentProps &
  ReturnType<typeof mapDispatchToProps>

const psLink = '/dashboard/point-system'
const sideBarKey = 'side_bar'

const SideBar: FC<TSideBarProps> = ({
  successPath,
  mainMenu,
  history,
  languageObject,
  onCollapsed
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(!!Number(storage.get(sideBarKey)))
  const [accordionHover, setAccordionHover] = useState<boolean>(false)
  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
    onCollapsed(!collapsed)
    storage.set(sideBarKey, collapsed ? '0' : '1')
  }

  const isRenderSubMenu = history.location.pathname.includes(psLink)
  const hover = collapsed ? accordionHover : true

  const menu = Object.keys(mainMenu).map((item, index) => {
    const itemHasElement = mainMenu[item].content.length > 0
    return (
      <li
        key={index}
        className={styles.side_bar_li}
        onMouseEnter={() => {
          if (itemHasElement) {
            setAccordionHover(true)
          }
        }}
        onMouseLeave={() => {
          if (itemHasElement) {
            setAccordionHover(false)
          }
        }}
      >
        <NavLink
          className={styles.side_bar_div}
          to={mainMenu[item].path}
          exact={mainMenu[item].exact}
          activeClassName={styles.active}
        >
          <i className={styles.side_bar_icon}>{mainMenu[item].icon}</i>
          {!collapsed && mainMenu[item].title}
        </NavLink>
        {isRenderSubMenu && itemHasElement && (
          <ul
            className={cls({
              [styles.accordion_ul]: true,
              [styles.collapsed]: collapsed,
              [styles.hide]: !hover
            })}
          >
            {itemHasElement &&
              mainMenu[item].content.map((i: any, ind: number) => (
                <AccordionItem key={ind} to={i.to} title={i.title} />
              ))}
          </ul>
        )}
      </li>
    )
  })

  return (
    <div className={cls({ [styles.container]: true, [styles.collapsed]: collapsed })}>
      <NavLink
        to={successPath}
        exact={true}
        activeStyle={{ pointerEvents: 'none' }}
        isActive={(_, location) => location.pathname === successPath}
      >
        <div className={styles.logo}>
          {collapsed ? <Icons icon="logoCollapsed" /> : <Icons icon="logo" />}
        </div>
      </NavLink>

      <ul className={`${styles.side_bar_ul} ${styles.menu_top}`}>{menu}</ul>
      <div className={styles.languages}>
        <div
          className={cls({
            [styles.languageButtonsCollapse]: collapsed,
            [styles.languageButtons]: !collapsed
          })}
        >
          {languageObject &&
            languageObject.languages.map(item => (
              <button
                key={item}
                onClick={() => {
                  languageObject.fetchInfo(item)
                }}
                className={cls({
                  [styles.collapseCurrentLanguage]: item === languageObject.currentLanguage,
                  [styles.collapseLanguage]: item !== languageObject.currentLanguage
                })}
              >
                {item.toUpperCase()}
              </button>
            ))}
        </div>
      </div>

      <button onClick={toggleCollapsed} className={styles.collapse}>
        <Icons icon="doubleArrow" />
      </button>
    </div>
  )
}

export default withRouter(SideBar)
