import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import ProfileComponent from './Profile'
import { changeUserPointsTransfer, getUsersList, getUserProfileInfo } from './Profile.thunx'
import { TState } from 'store'
import { getBalance } from '../NewRequestTimeOff/NewRequestTimeOff.thunx'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.auth.data,
    role: state.auth.data.role,
    timeBalance: state.client.newRequestTimeOff.balance,
    usersList: state.client.profile.usersList,
    userInfo: state.client.profile.user,
    PSBalanse: state.auth.data.balance
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getBalance,
      changeUserPointsTransfer,
      getUsersList,
      getUserProfileInfo
    },
    dispatch
  )

export const Profile = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileComponent)
