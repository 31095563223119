/* eslint-disable react/jsx-no-target-blank */
import React, { FC, ReactElement } from 'react'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'

import { Icons } from '../../../Icons'
import { ReactComponent as FileLogo } from 'assets/images/logo-file.svg'
import styles from './FileView.module.scss'
import { useSelector } from 'react-redux'
import { TState } from 'store'

interface FileViewProps {
  isEdit: boolean
  item: any
  idx: number
  deleteFile: (item: any, idx: number) => void
}

export const FileView: FC<FileViewProps> = ({ isEdit, item, idx, deleteFile }): ReactElement => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <div className={styles['file-container']}>
      <a className={styles['file-name-link']} href={item.link} target="_blank">
        <FileLogo />
        <div className={styles['file-name']}>{item.name}</div>
      </a>
      {isEdit && (
        <div
          className={styles['del-file-icon']}
          onClick={() => deleteFile(item, idx)}
          data-tip="true"
          data-for="discharge"
        >
          <Icons icon="dischargeOutline" />
          <ReactTooltip
            className={cn(styles.tooltip, styles['tooltip-discharge'])}
            id="discharge"
            place="bottom"
          >
            {words['admin.users.table.buttons.delete']}
          </ReactTooltip>
        </div>
      )}
    </div>
  )
}
