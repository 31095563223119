import React, { FC, useState } from 'react'
import styles from './HeaderMenu.module.scss'
import { logoutAction } from 'screens/auth/Login/Login.thunx'
import history from 'utils/history'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { useHistory } from 'react-router'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'

type THeaderMenuProps = {
  photo: string
  userSide: boolean
  logout: typeof logoutAction
}

export const HeaderMenu: FC<THeaderMenuProps> = ({ photo, userSide, logout }) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  const words = useSelector((state: TState) => state.global.language.words)
  const { location } = useHistory()

  return (
    <div className={styles.container}>
      {open && <div className={styles.overlay} onClick={toggleOpen} />}

      <div className={styles.avatar} onClick={toggleOpen}>
        {photo ? (
          <img className={styles.avatar} src={photo} alt="avatar" />
        ) : (
          <DefaultAvatar className={styles.avatar} />
        )}
      </div>
      {open && (
        <ul className={styles.menu}>
          {userSide && location.pathname !== '/dashboard/profile' && (
            <li onClick={() => history.push('/dashboard/profile')}>
              {words['user.header.profile']}
            </li>
          )}
          {!userSide && (
            <li onClick={() => history.push('/dashboard/settings')}>
              {words['admin.header.settings']}
            </li>
          )}
          <li onClick={logout}>{words['user.header.quit']}</li>
        </ul>
      )}
    </div>
  )
}
