import {
  GET_GOOD_REQUEST,
  GET_GOOD_SUCCESS,
  GET_GOOD_ERROR,
  CREATE_REVIEW_SUCCESS,
  CLEAR_GOOD_DATA
} from './GoodsItemDetails.constants'
import { IInitialState } from './GoodsItemDetails.model'
import { IAction } from 'models'
import { REMOVE_REVIEW_SUCCESS } from '../../admin/GoodsItem/GoodsItem.constants'
import { TReviewsItem } from '../../admin/Shop/Shop.model'

export const initialState: IInitialState = {
  data: { reviews: [] },
  loading: false
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_GOOD_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_GOOD_SUCCESS:
      return {
        ...state,
        data: action.payload.payload.result,
        loading: false
      }

    case CLEAR_GOOD_DATA: {
      return {
        ...initialState
      }
    }

    case CREATE_REVIEW_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          reviews: [
            {
              ...action.payload.comment,
              authorId: {
                fullName: action.payload.authorReview.fullName,
                id: action.payload.authorReview.id,
                photo: action.payload.authorReview.photo
              }
            },

            ...state.data.reviews
          ]
        },
        loading: false
      }

    case REMOVE_REVIEW_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          reviews: state.data.reviews.filter(
            (element: TReviewsItem) => Number(action.payload) !== Number(element.id)
          )
        },
        loading: false
      }

    case GET_GOOD_ERROR:
      return {
        ...state,
        loading: false
      }

    default:
      return state
  }
}
