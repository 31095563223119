import React, { FC, useEffect, useState } from 'react'
import { ToggleBar } from 'components/ToggleBar'
import { config } from 'globalConfigs'
import { IProps } from './Login.model'

import styles from './Login.module.scss'

const toggleBarOptions = [
  {
    value: '4',
    title: 'User'
  },
  {
    value: '2',
    title: 'Admin'
  }
]

export const Login: FC<IProps> = () => {
  const [selectedType, setSelectedType] = useState(toggleBarOptions[0])

  useEffect(() => {
    document.cookie = `role=${selectedType.value};  path=/;`
  }, [selectedType])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.poster}>
          <div className={styles.img} />
        </div>
        <div className={styles.main}>
          <div className={styles.toggle}>
            <ToggleBar
              name="login"
              data={toggleBarOptions}
              defaultChecked={selectedType}
              onChange={setSelectedType}
            />
          </div>
          <h1 className={styles.header}>
            Welcome to <br />
            Technorely Platform
          </h1>

          <a href={config.googleLogin} className={styles.link}>
            Sign in
          </a>
        </div>
      </div>
    </>
  )
}
