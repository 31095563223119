import React, { FC } from 'react'
import { ICounter } from './model'
import styles from '../../elements/Header/Header.module.scss'
import stylesFromBasket from '../../screens/client/Basket/Basket.module.scss'
import { ReactComponent as Plus } from 'assets/images/plus.svg'
import { ReactComponent as Minus } from 'assets/images/minus.svg'

export const Counter: FC<ICounter> = ({ element, addToCart, removeFromCart, name }) => {
  const handleIncrement = (event: any) => {
    event.preventDefault()
    addToCart({
      ...element,
      addType: 'basket'
    })
  }
  const handleDecrement = (event: any) => {
    event.preventDefault()
    removeFromCart(element)
  }
  return (
    <section
      className={name ? stylesFromBasket[`counter-container-${name}`] : styles['counter-container']}
    >
      <button onClick={handleDecrement} disabled={element.quantity <= 1}>
        <Minus />
      </button>
      <div>{element.quantity}</div>
      <button onClick={handleIncrement}>
        <Plus />
      </button>
    </section>
  )
}
