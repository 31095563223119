import React, { FC, useState } from 'react'
import styles from './Achievement.module.scss'
import { TAchievementProps } from '../../Achievements.model'
import { Icons } from 'components/Icons'
import { RequestForAchievementModal } from '../RequestForAchievementModal'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import history from '../../../../../utils/history'

export const Achievement: FC<TAchievementProps> = ({ data, createRequest }) => {
  const [openRequestForAchievement, setRequestForAchievement] = useState<boolean>(false)
  const toggleRequestForAchievement = () => setRequestForAchievement(!openRequestForAchievement)
  const words = useSelector((state: TState) => state.global.language.words)

  const handleClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    toggleRequestForAchievement()
  }
  const handleItemClick = (itemId: number) => {
    history.push(`${history.location.pathname}/${itemId}`)
  }

  return (
    <>
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${data.photo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className={styles.price}>
          <span> {data.price} </span>
          <span className={styles.logo}>
            <Icons icon="pointIcon" />
          </span>
        </div>
        <div className={styles.descr} onClick={() => handleItemClick(data.id)}>
          <div className={styles.name}>{data.name}</div>
          <button className={styles.btn} onClick={handleClick} data-id={data.id}>
            {words['user.pointsSystem.initiatives.table.apply']}
          </button>
        </div>
      </div>
      <RequestForAchievementModal
        open={openRequestForAchievement}
        toggle={toggleRequestForAchievement}
        id={data.id}
        createRequest={createRequest}
      />
    </>
  )
}
