import {
  GET_GOOD_REQUEST,
  GET_GOOD_SUCCESS,
  GET_GOOD_ERROR,
  CREATE_REVIEW_REQUEST,
  CREATE_REVIEW_SUCCESS,
  CREATE_REVIEW_ERROR,
  CLEAR_GOOD_DATA
} from './GoodsItemDetails.constants'
import { AxiosError } from 'axios'
import { TCreateReviewBody, TGoodData } from './GoodsItemDetails.model'
import { action } from '../../../store/store.utils'
import { TAutorReview } from '../../../components/Reviews/components/CreateReview/CreateReview'

export const getGoodRequest = () => action(GET_GOOD_REQUEST)
export const getGoodSuccess = (payload: TGoodData) => action(GET_GOOD_SUCCESS, payload)
export const getGoodError = (error: AxiosError) => action(GET_GOOD_ERROR, error)

export const clearGoodData = () => action(CLEAR_GOOD_DATA)

export const createReviewRequest = () => action(CREATE_REVIEW_REQUEST)
export const createReviewSuccess = (payload: {
  comment: TCreateReviewBody
  authorReview: TAutorReview
}) => action(CREATE_REVIEW_SUCCESS, payload)
export const createReviewError = (error: AxiosError) => action(CREATE_REVIEW_ERROR, error)
