/* eslint-disable no-console */
import { Dispatch } from 'redux'
import {
  signInClear,
  logOutRequest,
  logOutSuccess,
  pollingSuccess,
  setUserAvatar
  // pollingError
} from './Login.actions'
import { API, api, logout } from 'services/api'
import { PERIOD_OF_POLLING } from 'globalConfigs'

let timer: any
export const getTimer = () => timer

export const logoutAction = () => async (dispatch: Dispatch) => {
  dispatch(logOutRequest())
  try {
    const response = await api.get(API.LOGOUT)
    await logout()

    dispatch(logOutSuccess(response))
    dispatch(signInClear())
  } catch (error) {
    await logout()
    // dispatch(logOutError(error))
    console.error(error)
    dispatch(signInClear())
  }
}

export const pulling: any = () => async (dispatch: Dispatch) => {
  clearTimeout(timer)
  try {
    const { data } = await api.get(`${API.CLIENT}${API.POLLING}`)
    dispatch(pollingSuccess(data.payload))

    timer = setTimeout(() => {
      pulling()(dispatch)
    }, PERIOD_OF_POLLING)
  } catch (err) {
    // dispatch(pollingError(err))
    console.error(err)
  }
}

export const getUserPhoto = (id: number) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.get(`${API.USER_PHOTO}/${id}`)
    dispatch(setUserAvatar(data.payload))
  } catch (error) {
    console.error(error)
  }
}
