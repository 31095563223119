import { IAction } from 'models/index'
import {
  GET_USERS_REQUEST,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  GET_FOREIGN_PROFILE_REQUEST,
  GET_FOREIGN_PROFILE_ERROR,
  GET_FOREIGN_PROFILE_SUCCESS
} from './Team.constants'

export const initialState: any = {
  results: [],
  total: 0,
  loading: false,
  foreinProfile: {}
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
    case GET_FOREIGN_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload.results,
        total: action.payload.total
      }

    case GET_FOREIGN_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        results: [],
        total: 0,
        foreinProfile: action.payload
      }

    case GET_USERS_ERROR:
    case GET_FOREIGN_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return state
  }
}
