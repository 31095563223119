import React, { ReactNode } from 'react'
import { Column } from 'react-table'
import cn from 'classnames'
import { Icons } from '../../../../../components/Icons'
import { TableSortHeader } from '../../../../../components/UiKit/Table'
import styles from './UserRating.module.scss'
import { TPositions } from '../../../../admin/Users/Users.model'
import { TWords } from 'elements/SideBar/SideBar.config'

type TGetColumnsOptions = {
  users: TRatingUserData[]
}

export type TRatingUserData = {
  id: number
  fullName: string
  email: string
  rank: string | null
  balance: number
  options?: string
  roles: number[]
  position: string | null
  userPosition: TPositions | null
  firstWorkingDay: any
  workingHoursPerDay: number
  rating: number
  photo: string
}

type TGetColumns = (words: TWords, options?: TGetColumnsOptions) => Array<Column<TRatingUserData>>

const MAP_RATING_PLACE: { [key: string]: ReactNode } = {
  '1': <Icons icon="firstPlace" />,
  '2': <Icons icon="secondPlace" />,
  '3': <Icons icon="thirdPlace" />
}

export const getColumns: TGetColumns = words => [
  {
    Header: words['user.profile.table.position'],
    id: 'position',
    accessor: ({ balance, rating }) => {
      const condition = rating > 3
      return (
        <span
          className={cn({
            [styles.blue]: condition || !balance,
            [styles.center]: !condition
          })}
        >
          {condition ? rating : balance > 0 ? MAP_RATING_PLACE[rating] : rating}
        </span>
      )
    },
    minWidth: 100
  },
  {
    Header: words['roles.user'],
    accessor: 'fullName',
    width: 300
  },
  {
    Header: () => <TableSortHeader id={'balance'}>{words['user.header.balance']}</TableSortHeader>,
    id: 'balance',
    accessor: ({ balance }) => <span className={styles.green}> {balance} </span>,
    width: 150
  }
]
