import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Users as UsersComponent } from './Users'
import {
  getUsers,
  updateUser,
  updateCurrentTimeBalance,
  deleteUser,
  restoreUser,
  getPosition,
  updatePointsBalance,
  getUsersToFilter
} from './Users.thunx'
import { clearUsersData } from './Users.actions'
import { getBalance, getManagers } from '../../client/NewRequestTimeOff/NewRequestTimeOff.thunx'
import { TState } from 'store'
import { getSelectOptions } from '../../client/EditProfile/EditProfile.thunx'

export const mapStateToProps = (state: TState) => ({
  data: state.admin.users.results,
  users: state.admin.users.usersToFilter,
  dataManagers: state.client.newRequestTimeOff.managers.results,
  dataPositions: state.admin.users.positions.results,
  total: state.admin.users.total,
  balance: state.client.newRequestTimeOff.balance,
  loading: state.admin.users.loading
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUsers,
      updateUser,
      getManagers,
      clearUsersData,
      getBalance,
      updateCurrentTimeBalance,
      deleteUser,
      restoreUser,
      getPosition,
      updatePointsBalance,
      getUsersToFilter,
      getSelectOptions
    },
    dispatch
  )

export const Users = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersComponent)
