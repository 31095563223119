import { applyMiddleware, createStore, combineReducers, AnyAction } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { config } from 'globalConfigs'
import { persistReducer, persistStore } from 'redux-persist'
import persistStorage from 'redux-persist/lib/storage' // defaults to localStorage for web

import auth from 'screens/auth/Login/Login.reducer'
import goods from 'screens/client/Goods/Goods.reducer'
import good from 'screens/client/GoodsItemDetails/GoodsItemDetails.reducer'
import basket from 'screens/client/Basket/Basket.reducer'
import info from 'screens/client/Information/Information.reducer'
import achievements from 'screens/client/Achievements/Achievements.reducer'
import profile from 'screens/client/Profile/Profile.reducer'
import editProfile from 'screens/client/EditProfile/EditProfile.reducer'
import newRequestTimeOff from 'screens/client/NewRequestTimeOff/NewRequestTimeOff.reducer'
import requestsTimeOffUser from 'screens/client/RequestsTimeOffUser/RequestsTimeOffUser.reducer'
import timeOffRequests from 'screens/client/TimeOffRequests/TimeOffRequests.reducer'
import rating from 'screens/client/Profile/components/UserRating/UserRating.reducer'
import goodsItem from 'screens/admin/GoodsItem/GoodsItem.reducer'
import shop from 'screens/admin/Shop/Shop.reducer'
import users from 'screens/admin/Users/Users.reducer'
import timeOfRequest from 'screens/admin/Settings/components/SettingsTimeOfRequest/SettingsTimeOfRequest.reducer'
import pointsSystem from 'screens/admin/Settings/components/SettingsPointsSystem/SettingsPointsSystem.reducer'
import timeOfRequestUpdate from 'screens/admin/Settings/components/SettingsTimeOfRequestUpdate/SettingsTimeOfRequestUpdate.reducer'
import requests from 'screens/admin/Requests/Requests.reducer'
import filter from 'components/UiKit/Filter/Filter.reducer'
import team from 'screens/client/Team/Team.reducer'
import teamAdmin from 'screens/admin/Team/TeamAdmin.reducer'
// import faq from 'screens/client/Information/Information.reducer'
import { languageReducer as language } from './global'

const rootPersistConfig = {
  key: 'root',
  storage: persistStorage,
  whitelist: ['auth']
}

const basketPersistConfig = {
  key: 'basket',
  storage: persistStorage,
  blacklist: ['error', 'loading']
}

const client = combineReducers({
  goods,
  good,
  basket: persistReducer(basketPersistConfig, basket),
  info,
  achievements,
  profile,
  editProfile,
  requestsTimeOffUser,
  timeOffRequests,
  newRequestTimeOff,
  rating,
  filter,
  team
  // faq
})

const settings = combineReducers({
  timeOfRequest,
  timeOfRequestUpdate,
  pointsSystem
})

const admin = combineReducers({
  goodsItem,
  shop,
  users,
  settings,
  requests,
  teamAdmin
})

const global = combineReducers({
  language
})

const appReducer = combineReducers({
  auth,
  admin,
  client,
  global
})

const rootReducer = (state: any, action = {}) => appReducer(state, action as AnyAction)

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const configureStore = () => {
  let middleware = applyMiddleware(thunkMiddleware)
  if (!config.isProduction) {
    middleware = composeWithDevTools(middleware)
  }

  return createStore(persistedReducer, {}, middleware)
}

export const store = configureStore()

export const persistor = persistStore(store)

export type TState = ReturnType<typeof appReducer>
