import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import {
  requestUserDay0fBirth,
  successUserDay0fBirth,
  errorUserDay0fBirth,
  errorUserCity,
  successUserCity,
  requestUserCity,
  successUserPointsTransfer,
  errorUserPointsTransfer,
  getHistoryRequest,
  getHistorySuccess,
  getHistoryError,
  getTransactionsRequest,
  getTransactionsSuccess,
  getTransactionsError,
  getUserProfileInfoRequest,
  getUserProfileInfoSuccess,
  getUserProfileInfoError,
  getUsersListSuccess,
  checkUserProfileRequest,
  checkUserProfileSuccess,
  checkUserProfileError
} from './Profile.actions'
import toastr from '../../../utils/toastr'
import { TUpdatePointsBalanceBody } from '../../admin/Users/Users.model'
import history from 'utils/history'
import { EPageLimits } from '../../../globalConfigs'
import queryString from 'query-string'
import { TState } from 'store'

export const setDob = (body: { [key: string]: number }) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(requestUserDay0fBirth())
  const words = getData().global.language.words

  try {
    await api.put(`${API.URL}${API.CLIENT}`, body)
    dispatch(successUserDay0fBirth(body))
    toastr('success', words['BIRTHDAY_DATE_UPDATED'])
  } catch (error) {
    dispatch(errorUserDay0fBirth(error.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], error)
  }
}

export const setUserCity = (body: { [key: string]: number }) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(requestUserCity())
  const words = getData().global.language.words

  try {
    await api.put(`${API.URL}${API.CLIENT}`, body)
    dispatch(successUserCity(body))
    toastr('success', words['UPDATED_CITY'])
  } catch (error) {
    dispatch(errorUserCity(error.response))
    toastr('error', words['FILED_UPDATE_USER'], error)
  }
}

export const changeUserPointsTransfer = (body: TUpdatePointsBalanceBody) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words

  try {
    const response = await api.post(API.TRANSACTION_USER, body)
    dispatch(successUserPointsTransfer(body))
    const errorElement = response.data.payload.find(
      (el: { [key: string]: string }) => el.message !== 'ok'
    )
    if (Object.keys(response.data).length === 0) {
      toastr('error', words['FILED_TRANSFER_POINTS'])
    } else if (
      errorElement &&
      errorElement.message.match(/not enough points on balance to make transaction/)
    ) {
      toastr('error', words['NOT_ENOUGH_POINTS_ON_BALANCE_TO_MAKE_TRANSACTION'])
    } else {
      toastr('info', words['SUCCESS_TRANSFERRED'])
    }
    history.push('/dashboard/profile')
  } catch (error) {
    dispatch(errorUserPointsTransfer(error))
    toastr('error', words['FILED_TRANSFER_POINTS'], error)
    history.push('/dashboard/point-system')
  }
}

export const getHistory = (page: number, search: string = '') => async (dispatch: Dispatch) => {
  dispatch(getHistoryRequest())
  try {
    const res = await api.get(
      `${API.HISTORY}${search}${search ? '&' : '?'}limit=${EPageLimits.HISTORY}&page=${page}`
    )
    dispatch(getHistorySuccess(res.data))
  } catch (error) {
    dispatch(getHistoryError(error))
  }
}

export const getTransactions = (page: number, search: string = '') => async (
  dispatch: Dispatch
) => {
  dispatch(getTransactionsRequest())
  const query = queryString.stringify({
    ...queryString.parse(search),
    limit: EPageLimits.TRANSACTION,
    page
  })
  try {
    const response = await api.get(`${API.TRANSACTION}?${query}`)

    dispatch(getTransactionsSuccess(response.data))
  } catch (error) {
    dispatch(getTransactionsError(error))
  }
}

/*********************** */
export const getUserProfileInfo = (id: number, profileType: string, isAdmin: boolean) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  const words = getData().global.language.words
  dispatch(getUserProfileInfoRequest())
  try {
    let data = {}
    if (isAdmin) {
      const res = await api.get(
        `${API.USER_PROFILE_INFO_FOR_ADMIN}/${id}?profileType=${profileType}`
      )
      data = { ...res.data }
    }
    if (!isAdmin) {
      const res = await api.get(`${API.USER_PROFILE_INFO}/${Number(id)}?profileType=${profileType}`)
      data = { ...res.data }
    }
    dispatch(getUserProfileInfoSuccess(data))
  } catch (error) {
    dispatch(getUserProfileInfoError(error))
    toastr('error', words['REQUEST_NOT_FOUND'], error)
  }
}

export const getUsersList = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.get(`${API.ALL_USERS}`)
    dispatch(getUsersListSuccess(data.payload))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export const checkUserProfile = (id: number) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  const words = getData().global.language.words
  dispatch(checkUserProfileRequest())
  try {
    const { data } = await api.get(`${API.CHECK_USER_PROFILE}/${Number(id)}`)
    dispatch(checkUserProfileSuccess(data.payload))
  } catch (error) {
    dispatch(checkUserProfileError(error))
    toastr('error', words['REQUEST_NOT_FOUND'], error)
  }
}
