import React, { useEffect, useRef, FC, CSSProperties } from 'react'

export type TClickAwayListenerProps = {
  className?: string
  onOutsideClick: () => void
  style?: CSSProperties
}

export const ClickAwayListener: FC<TClickAwayListenerProps> = ({
  children,
  className = '',
  onOutsideClick
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handler = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onOutsideClick()
      }
    }

    document.addEventListener('click', handler, false)

    return () => document.removeEventListener('click', handler)
  }, [ref])

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  )
}
