import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './ForeignProfile.module.scss'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { Redirect } from 'react-router-dom'

import { TForeignProfileProps } from './ForeignProfile.model'
import { UserHeader } from 'components/Profile/UserHeader'
import { PersonalInfo } from 'components/Profile/PersonalInfo'
import { CareerInfo } from 'components/Profile/CareerInfo'
import {
  EProfileHistory,
  ETransactionHistory,
  getToggleBarOptions,
  getTransactionToggleBarOptions
} from 'globalConfigs'
import history from 'utils/history'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
import { isUser, isAdmin as adminRole } from 'utils/user'
import { TimeOffBalance } from 'components/Profile/TimeOffBalance'
import { PointsSystem } from 'components/Profile/PointsSystem'
import { getProfileType, setDefaultMainToggleBarState } from 'utils/toggleBarsValue'

const ForeignProfile: FC<TForeignProfileProps> = ({
  user,
  profileInfo,
  foreinProfile,
  role,
  getUserProfileInfo,
  timeBalance,
  getBalance,
  getForeignProfile
}) => {
  const parsed = queryString.parse(history.location.search)
  const id = String(parsed.userId)
  const words = useSelector((state: TState) => state.global.language.words)
  const isAdmin = useMemo(() => adminRole(role), [role])
  const isSameUser = user && user.id === Number(parsed.userId)

  useEffect(() => {
    if (isAdmin || isSameUser) {
      if (parsed.profileType) {
        getUserProfileInfo(+id, String(parsed.profileType), isAdmin)
      }
      if (parsed.profileType === EProfileHistory.TIME_OFF_BALANCE) {
        getBalance(id)
      }
    }
    if (!isSameUser) {
      getForeignProfile(+id)
    }
  }, [parsed.profileType])

  const isEdit = false
  const [selectedProfileType, setSelectedProfileType] = useState<IToggleBarData>(
    setDefaultMainToggleBarState(parsed.profileType, words, isEdit, isAdmin)
  )

  if (!parsed.type || !parsed.profileType) {
    return (
      <Redirect
        to={{
          search: `type=${parsed.type ? parsed.type : ETransactionHistory.GOODS}&profileType=${
            parsed.profileType ? parsed.profileType : EProfileHistory.MAIN_INFO
          }&userId=${id}`,
          pathname: history.location.pathname
        }}
      />
    )
  }
  const dataToProfile = isSameUser || isAdmin ? profileInfo : foreinProfile

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['foreignUser.header']}</h1>
      </section>
      <section className={styles.block_profile}>
        <UserHeader isSameUser={isSameUser} role={role} {...dataToProfile} />
      </section>

      {!isUser(role) && (
        <div className={styles['main-toggle-bar-wrapper']}>
          <div>
            <ToggleBar
              name="profile-info"
              data={getToggleBarOptions(words, isEdit, isAdmin)}
              defaultChecked={selectedProfileType}
              onChange={event => {
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    profileType: getProfileType(event.value),
                    userId: id
                  })}`
                )
                setSelectedProfileType(event)
              }}
            />
          </div>
        </div>
      )}

      <section className={styles['personal-info']}>
        {(isUser(role) ||
          !parsed.profileType ||
          parsed.profileType === EProfileHistory.MAIN_INFO) && (
          <PersonalInfo isSameUser={isSameUser} role={role} {...dataToProfile} />
        )}
        {!isUser(role) && parsed.profileType === EProfileHistory.CAREER && (
          <CareerInfo {...dataToProfile} />
        )}
        {!isUser(role) && parsed.profileType === EProfileHistory.TIME_OFF_BALANCE && (
          <TimeOffBalance balance={timeBalance} />
        )}
        {!isUser(role) && parsed.profileType === EProfileHistory.POINTS_SYSTEM && (
          <PointsSystem
            transactionToggleBarOptions={getTransactionToggleBarOptions(words)}
            id={id}
            balance={dataToProfile && dataToProfile.balance}
            history={history}
          />
        )}
      </section>
    </div>
  )
}

export default ForeignProfile
