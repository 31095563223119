import React, { FC, useEffect, useMemo, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { IAllUsersList, TProfileProps } from './Profile.model'
import styles from './Profile.module.scss'
import {
  ETransactionHistory,
  EProfileHistory,
  getTransactionToggleBarOptions,
  getToggleBarOptions
} from 'globalConfigs'
import { TSelectOption } from 'components/Select'
import { UserHeader } from 'components/Profile/UserHeader'
import { PersonalInfo } from 'components/Profile/PersonalInfo'
import { CareerInfo } from 'components/Profile/CareerInfo'
import { TimeOffBalance } from '../../../components/Profile/TimeOffBalance'
import { PointsSystem } from 'components/Profile/PointsSystem'
import { Button } from 'components/UiKit/Button'
import { ProfileModalPointsTransferForm } from './components/ProfileModalPointsTransferForm'
import queryString from 'query-string'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { getProfileType, setDefaultMainToggleBarState } from 'utils/toggleBarsValue'
import { isAdmin as adminRole } from 'utils/user'

const Profile: FC<TProfileProps> = ({
  user,
  role,
  timeBalance,
  getBalance,
  usersList,
  getUsersList,
  userInfo,
  getUserProfileInfo,
  changeUserPointsTransfer,
  PSBalanse,
  history
}) => {
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)
  const isAdmin = useMemo(() => adminRole(role), [role])
  const isEdit = false
  const [selectedProfileType, setSelectedProfileType] = useState<IToggleBarData>(
    setDefaultMainToggleBarState(parsed.profileType, words, isEdit, isAdmin)
  )
  const [openPointsTransferModal, setOpenPointsTransferModal] = useState<boolean>(false)
  const togglePointsTransferModal = () => setOpenPointsTransferModal(!openPointsTransferModal)

  useEffect(() => {
    if (parsed.type || parsed.profileType) {
      getUserProfileInfo(user.id, String(parsed.profileType), isAdmin)
    }
  }, [])

  useEffect(() => {
    if (parsed.profileType && parsed.profileType === EProfileHistory.TIME_OFF_BALANCE) {
      getBalance()
    }
  }, [parsed.profileType])

  useEffect(() => {
    if (openPointsTransferModal) {
      getUsersList()
    }
  }, [openPointsTransferModal])

  const usersData: TSelectOption[] = useMemo(
    () =>
      usersList
        .filter((item: IAllUsersList) => item.id !== user.id)
        .map((item: IAllUsersList) => ({
          value: `${item.id}`,
          label: item.fullName
        })),
    [usersList]
  )

  if (!parsed.type || !parsed.profileType) {
    return (
      <Redirect
        to={{
          search: `type=${parsed.type ? parsed.type : ETransactionHistory.GOODS}&profileType=${
            parsed.profileType ? parsed.profileType : EProfileHistory.MAIN_INFO
          }&userId=${user.id}`,
          pathname: history.location.pathname
        }}
      />
    )
  }

  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>{words['user.header.profile']}</h1>
        </section>
        <section className={styles.block_profile}>
          <UserHeader isSameUser={true} role={role} {...userInfo} />
        </section>

        <div className={styles['main-toggle-bar-wrapper']}>
          <div>
            <ToggleBar
              name="profile-info"
              data={getToggleBarOptions(words, isEdit, isAdmin)}
              defaultChecked={selectedProfileType}
              onChange={event => {
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    profileType: getProfileType(event.value),
                    type: parsed.type,
                    userId: user.id
                  })}`
                )
                setSelectedProfileType(event)
              }}
            />
          </div>
          {parsed.profileType === EProfileHistory.POINTS_SYSTEM && (
            <div className={styles['points-btns']}>
              <Button
                type="button"
                className={styles['rating']}
                outline={true}
                onClick={() => {
                  history.push('/dashboard/point-system/profile/rating')
                }}
              >
                {words['user.profile.buttons.rating']}
              </Button>
              <Button
                type="button"
                className={styles['send-friend']}
                onClick={togglePointsTransferModal}
              >
                {words['user.profile.buttons.sendPoints']}
              </Button>
            </div>
          )}
        </div>

        <section className={styles['personal-info']}>
          {parsed.profileType === EProfileHistory.MAIN_INFO ? (
            <PersonalInfo isSameUser={true} role={role} {...userInfo} />
          ) : parsed.profileType === EProfileHistory.CAREER ? (
            <CareerInfo {...userInfo} />
          ) : parsed.profileType === EProfileHistory.TIME_OFF_BALANCE ? (
            <TimeOffBalance balance={timeBalance} />
          ) : parsed.profileType === EProfileHistory.POINTS_SYSTEM ? (
            <PointsSystem
              transactionToggleBarOptions={getTransactionToggleBarOptions(words)}
              id={String(userInfo && userInfo.id)}
              balance={PSBalanse || 0}
              history={history}
            />
          ) : (
            <></>
          )}
        </section>
      </div>

      <ProfileModalPointsTransferForm
        open={openPointsTransferModal}
        togglePointsTransfer={togglePointsTransferModal}
        users={usersData}
        changeUserPointsTransfer={changeUserPointsTransfer}
      />
    </>
  )
}

export default Profile
