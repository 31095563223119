import {
  GET_BASKET_REQUEST,
  GET_BASKET_SUCCESS,
  GET_BASKET_ERROR,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  REQUEST_PURCHASE_OF_GOODS_SUCCESS,
  TOGGLE_MODAL_PURCHASE
} from './Basket.constants'
import { IInitialBasketState, IAction } from './Basket.model'
import { TState } from 'store'

export const initialBasketState: IInitialBasketState = {
  data: [],
  error: '',
  loading: false,
  totalQuantity: 0,
  totalPrice: 0,
  openThankForPurchase: false
}

export const getTotalQuantitySelector = (state: TState) => state.client.basket.totalQuantity
export const getTotalPriseSelector = (state: TState) => state.client.basket.totalPrice
export const getDataSelector = (state: TState) => state.client.basket.data
export const getErrorSelector = (state: TState) => state.client.basket.error
export const getLoadingSelector = (state: TState) => state.client.basket.loading

export default (state = initialBasketState, action: IAction) => {
  switch (action.type) {
    case GET_BASKET_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_BASKET_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    }

    case GET_BASKET_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    }

    case ADD_TO_CART: {
      const insertElement = {
        ...action.payload,
        total:
          action.payload.quantity > 1
            ? action.payload.price * action.payload.quantity
            : action.payload.price
      }
      let insertData = [...state.data]
      const totalQuantity = 0
      const totalPrice = 0
      const foundElement = state.data.find(element => element.id === action.payload.id)

      if (foundElement) {
        foundElement.quantity += action.payload.addType === 'basket' ? 1 : action.payload.quantity
        foundElement.total = foundElement.quantity * foundElement.price
        insertData = [...state.data]
      } else {
        insertData = [...state.data, insertElement]
      }

      const baseTotal = insertData.reduce(
        (init, element) => {
          init.totalPrice += element.total
          init.totalQuantity += element.quantity
          return init
        },
        { totalQuantity, totalPrice }
      )
      return {
        ...state,
        data: insertData,
        ...baseTotal
      }
    }

    case REMOVE_FROM_CART: {
      const stateData = [...state.data]
      const basketState = state
      const foundElement = state.data.find(element => element.id === action.payload.id)

      if (!action.removeBasketElement) {
        if (foundElement) {
          foundElement.quantity -= 1
          foundElement.total = foundElement.quantity * foundElement.price
          basketState.totalPrice -= foundElement.price
          basketState.totalQuantity -= 1
          return {
            ...state,
            data: stateData,
            totalPrice: basketState.totalPrice,
            totalQuantity: basketState.totalQuantity
          }
        }
      }

      if (foundElement) {
        basketState.totalPrice -= foundElement.total
        basketState.totalQuantity -= foundElement.quantity
        const filterData = stateData.filter(element => element.id !== action.payload.id)
        return {
          ...state,
          data: filterData,
          totalPrice: basketState.totalPrice,
          totalQuantity: basketState.totalQuantity
        }
      } else {
        return {
          ...state,
          data: [],
          totalPrice: 0,
          totalQuantity: 0
        }
      }
    }

    case REQUEST_PURCHASE_OF_GOODS_SUCCESS:
      return {
        ...state,
        openThankForPurchase: true
      }
    case TOGGLE_MODAL_PURCHASE:
      return {
        ...state,
        openThankForPurchase: action.payload
      }

    default:
      return state
  }
}
