import React, { FC /*useState*/ } from 'react'
import Modal from 'components/Modal'
import { ReactComponent as RequestForAchievementImg } from 'assets/images/RequestForAchievement.svg'
import { Textarea } from 'components/Textarea'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import styles from './RequestForAchievementModal.module.scss'
import { RedNote } from 'components/RedNote'
import { TRequestAchievement, TRequestForAchievementModal } from '../../Achievements.model'
import { Field, Form } from 'react-final-form'
import validation from '../../../../../utils/validation'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export const RequestForAchievementModal: FC<TRequestForAchievementModal> = ({
  open,
  toggle,
  id,
  createRequest
}) => {
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <Modal isShow={open} onClose={toggle} className={styles.modal}>
      <Form
        onSubmit={(values: { [key: string]: any }) => {
          const body: TRequestAchievement = {
            requestUserComment: values.comments,
            details: [
              {
                initiativeId: id
              }
            ]
          }
          createRequest(body)
          toggle()
        }}
        render={({ handleSubmit }) => (
          <form className={styles.container} onSubmit={handleSubmit}>
            <div className={styles.header}>
              <header>{words['user.pointsSystem.initiatives.table.achievementClaim']}</header>
            </div>
            <div>
              <RequestForAchievementImg />
            </div>
            <div className={styles['comment-title']}>
              {`${words['user.profile.table.comment']}:`}
              <RedNote />
            </div>

            <Field
              name="comments"
              validate={validation.composeValidators(
                validation.required(words['user.requiredMessage']),
                validation.minValue(2)
              )}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <Textarea
                      {...input}
                      className={styles['comment']}
                      placeholder={`${
                        words['admin.settings.pointsSystem.balanceEdit.comment.placeHolder']
                      }...`}
                      isInvalid={meta.error && meta.submitFailed}
                      errorMessage={meta.error}
                    />
                  </div>
                )
              }}
            </Field>
            <div className={styles.btn}>
              <BaseButton
                children={words['user.pointsSystem.initiatives.table.apply']}
                size="percent"
                type="submit"
              />
            </div>
          </form>
        )}
      />
    </Modal>
  )
}
