import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { GoodsItem as GoodsItemComponent } from './GoodsItem'
import {
  setCreateItem,
  getGoodsCategories,
  deleteGoodsCategory,
  getCurrentItem,
  removeReview,
  setNewCategory
} from './GoodsItem.thunx'
import { TState } from 'store'
import { withRouter } from 'react-router-dom'
import { clearGoodData } from '../../client/GoodsItemDetails/GoodsItemDetails.actions'

export const mapStateToProps = (state: TState) => {
  return {
    data: state.admin.goodsItem.data,
    loading: state.admin.goodsItem.loading,
    goodsCategories: state.admin.goodsItem.goodsCategories
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setCreateItem,
      getGoodsCategories,
      deleteGoodsCategory,
      getCurrentItem,
      removeReview,
      clearGoodData,
      setNewCategory
    },
    dispatch
  )

export const GoodsItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GoodsItemComponent))
