import { AxiosError } from 'axios'
import { TSelectOption } from 'components/Select'
import { IChekbox } from 'components/UiKit/Table/components/SetColumns'
import { action } from 'store/store.utils'
import {
  CLEAR_TEAMADMIN_DATA,
  DELETE_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_TEAMADMIN_ERROR,
  GET_TEAMADMIN_REQUEST,
  GET_TEAMADMIN_SUCCESS,
  RESTORE_USER_ERROR,
  RESTORE_USER_REQUEST,
  RESTORE_USER_SUCCESS,
  SET_CHECKED_BOX,
  SET_CURRENT_TAB,
  SET_HIDDEN_COLUMNS,
  SET_OPTIONS_FOR_TEAMADMIN,
  SET_OPTIONS_FOR_TEAMADMIN_ERROR,
  SET_OPTIONS_FOR_TEAMADMIN_TERMINATIONINITIATOR,
  SET_OPTIONS_FOR_TEAMADMIN_TERMINATIONINITIATOR_ERROR,
  TOGGLE_OPEN_DEACTIVATE_MODAL,
  TOGGLE_OPEN_RESTORE_USER_MODAL,
  UPDATE_USER_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS
} from './TeamAdmin.constans'
import {
  IUserData,
  TUpdateTeamAdminPayload,
  IActionType,
  ITerminationInitiatorOptionsSuccess,
  ITerminationInitiatorOptionsError,
  ICurrentTab
} from './TeamAdmin.model'

export const getTeamAdminRequest = (): IActionType => action(GET_TEAMADMIN_REQUEST)
export const getTeamAdminSuccess = (payload: { results: IUserData[]; total: number }) =>
  action(GET_TEAMADMIN_SUCCESS, payload)
export const getTeamAdminError = (payload: AxiosError) => action(GET_TEAMADMIN_ERROR, payload)
export const clearTeamAdminData = () => action(CLEAR_TEAMADMIN_DATA)

export const updateUserRequest = (): IActionType => action(UPDATE_USER_REQUEST)
export const updateUserSuccess = (payload: TUpdateTeamAdminPayload) =>
  action(UPDATE_USER_SUCCESS, payload)
export const updateUserError = (payload: AxiosError) => action(UPDATE_USER_ERROR, payload)

export const deleteUserRequest = (): IActionType => action(DELETE_USER_REQUEST)
export const deleteUserSuccess = (id: number) => action(DELETE_USER_SUCCESS, { id })
export const deleteUserError = (payload: AxiosError) => action(DELETE_USER_ERROR, payload)
export const setOpenDeactivateModal = (): IActionType => action(TOGGLE_OPEN_DEACTIVATE_MODAL)

export const restoreUserRequest = (): IActionType => action(RESTORE_USER_REQUEST)
export const restoreUserSuccess = (id: number) => action(RESTORE_USER_SUCCESS, { id })
export const restoreUserError = (payload: AxiosError) => action(RESTORE_USER_ERROR, payload)
export const setOpenRestoreUserModal = (): IActionType => action(TOGGLE_OPEN_RESTORE_USER_MODAL)

export const getTerminationInitiatorOptionsSuccess = (payload: {
  results: TSelectOption
}): ITerminationInitiatorOptionsSuccess =>
  action(SET_OPTIONS_FOR_TEAMADMIN_TERMINATIONINITIATOR, payload)
export const getTerminationInitiatorOptionsError = (
  payload: AxiosError
): ITerminationInitiatorOptionsError =>
  action(SET_OPTIONS_FOR_TEAMADMIN_TERMINATIONINITIATOR_ERROR, payload)

export const setOptions = (payload: any) => action(SET_OPTIONS_FOR_TEAMADMIN, payload)
export const setOptionsError = (payload: AxiosError) =>
  action(SET_OPTIONS_FOR_TEAMADMIN_ERROR, payload)

export const setHiddenColumns = (payload: string[]) => action(SET_HIDDEN_COLUMNS, payload)
export const setCurrentTab = (payload: ICurrentTab) => action(SET_CURRENT_TAB, payload)
export const setCheckedItems = (payload: IChekbox[]) => action(SET_CHECKED_BOX, payload)
