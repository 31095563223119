import React, { FC, useState } from 'react'
import { Icons } from 'components/Icons'
import styles from './CommentsCell.module.scss'
import cls from 'classnames'
import { ClickAwayWrapper } from '../../../../ClickAwayWrapper'

type TCommentsCell = {
  comment: string | null
}

const MAX_LENGTH_WITHOUT_SCROLL = 100

export const CommentCell: FC<TCommentsCell> = ({ comment }) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const isScrollable = comment && comment.length > MAX_LENGTH_WITHOUT_SCROLL
  return (
    <>
      <ClickAwayWrapper onOutsideClick={() => setOpenConfirm(false)}>
        <div className={styles.container}>
          <Icons
            icon={comment ? 'iconTableComment' : 'iconTableCommentDisabled'}
            onClick={e => {
              e.stopPropagation()
              if (comment) {
                setOpenConfirm(true)
              }
            }}
          />

          {openConfirm && (
            <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
              <div
                className={cls({
                  [styles['comment']]: !isScrollable,
                  [styles['commentScroll']]: isScrollable
                })}
              >
                {comment}
              </div>
              <div className={styles['btn-container']}>
                <button
                  onClick={e => {
                    e.stopPropagation()
                    setOpenConfirm(false)
                  }}
                >
                  <Icons icon="iconTableClose" onClick={() => setOpenConfirm(true)} />
                </button>
              </div>
            </div>
          )}
        </div>
      </ClickAwayWrapper>
    </>
  )
}
