export const GET_TEAMADMIN_REQUEST = 'GET_TEAMADMIN_REQUEST'
export const GET_TEAMADMIN_SUCCESS = 'GET_TEAMADMIN_SUCCESS'
export const GET_TEAMADMIN_ERROR = 'GET_TEAMADMIN_ERROR'
export const CLEAR_TEAMADMIN_DATA = 'CLEAR_TEAMADMIN_DATA'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'
export const TOGGLE_OPEN_DEACTIVATE_MODAL = 'TOGGLE_OPEN_DEACTIVATE_MODAL'

export const RESTORE_USER_REQUEST = 'RESTORE_USER_REQUEST'
export const RESTORE_USER_SUCCESS = 'RESTORE_USER_SUCCESS'
export const RESTORE_USER_ERROR = 'RESTORE_USER_ERROR'
export const TOGGLE_OPEN_RESTORE_USER_MODAL = 'TOGGLE_OPEN_RESTORE_USER_MODAL'

export const SET_OPTIONS_FOR_TEAMADMIN_TERMINATIONINITIATOR =
  'SET_OPTIONS_FOR_TEAMADMIN_TERMINATIONINITIATOR'
export const SET_OPTIONS_FOR_TEAMADMIN_TERMINATIONINITIATOR_ERROR =
  'SET_OPTIONS_FOR_TEAMADMIN_TERMINATIONINITIATOR_ERROR'
export const SET_OPTIONS_FOR_TEAMADMIN = 'SET_OPTIONS_FOR_TEAMADMIN_FILTER'
export const SET_OPTIONS_FOR_TEAMADMIN_ERROR = 'SET_OPTIONS_FOR_TEAMADMIN_FILTER_ERROR'

export const SET_HIDDEN_COLUMNS = 'SET_HIDDEN_COLUMNS'
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
export const SET_CHECKED_BOX = 'SET_CHECKED_BOX'
