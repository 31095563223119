import React, { FC } from 'react'
import styles from './NotFound.module.scss'
import notFoundImg from 'assets/images/404.svg'

type TNotFoundProps = {
  title?: string
}

export const NotFound: FC<TNotFoundProps> = ({ title, children }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title || 'К сожалению, вы нашли неработающую ссылку'}</h2>
      <img src={notFoundImg} alt="notFound" />
      {children}
    </div>
  )
}
