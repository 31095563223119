import { TBaseFilterConfigData } from 'components/UiKit/Filter/Filter'
import { TSelectOption } from '../../../Select/Select.model'
import { TWords } from 'elements/SideBar/SideBar.config'
import { FILTER_SELECT_INITIATIVES_STATUS, FILTER_SELECT_STATUS_FOR_USER } from 'globalConfigs'

export const getFilterConfig = (
  words: TWords,
  {
    // TODO -- use it when create category select will done
    // categories,
    usersData = [],
    minFromDate,
    maxToDate,
    minPrice,
    maxPrice
  }: {
    usersData: TSelectOption[]
    minFromDate: string | number
    maxToDate: string | number
    minPrice: number
    maxPrice: number
  },
  selectedTypeIntoFilter?: string
): TBaseFilterConfigData[] => {
  const currentFormatMinFromDate = new Date(minFromDate).valueOf()
  const currentFormatMaxToDate = new Date(maxToDate).valueOf()
  if (selectedTypeIntoFilter === '2') {
    return [
      {
        name: 'fullName',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...usersData],
        label: words['roles.user'],
        width: '26%'
      },
      {
        name: 'dateFrom',
        secondName: 'dateTo',
        type: 'dateRange',
        label: words['manager.dayOffTracker.details.requestDate'],
        fieldData: {
          from: currentFormatMinFromDate,
          to: currentFormatMaxToDate
        },
        width: 100
      },
      {
        name: 'priceFrom',
        secondName: 'priceTo',
        type: 'inputRange',
        fieldData: { minValue: minPrice, maxValue: maxPrice },
        label: words['user.pointsSystem.market.table.price'],
        width: 70
      },
      {
        name: 'status',
        type: 'select',
        fieldData: FILTER_SELECT_INITIATIVES_STATUS(words),
        label: words['user.dayOffTracker.table.requestStatus'],
        placeholder: words['user.dayOffTracker.table.requestStatus'],
        width: 120
      }
    ]
  } else {
    return [
      {
        name: 'fullName',
        type: 'select',
        fieldData: [{ value: '@ALL@', label: words['all'] }, ...usersData],
        label: words['roles.user'],
        width: '26%'
      },
      {
        name: 'dateFrom',
        secondName: 'dateTo',
        type: 'dateRange',
        label: words['manager.dayOffTracker.details.requestDate'],
        fieldData: {
          from: currentFormatMinFromDate,
          to: currentFormatMaxToDate
        },
        width: 100
      },
      {
        name: 'priceFrom',
        secondName: 'priceTo',
        type: 'inputRange',
        fieldData: { minValue: minPrice, maxValue: maxPrice },
        label: words['user.profile.table.reward'],
        width: 70
      },
      {
        name: 'status',
        type: 'select',
        fieldData: FILTER_SELECT_STATUS_FOR_USER(words),
        label: words['user.dayOffTracker.table.requestStatus'],
        placeholder: words['user.dayOffTracker.table.requestStatus'],
        width: 120
      }
    ]
  }
}
