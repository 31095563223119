import { TSelectOption } from 'components/Select'
import { TWords } from 'elements/SideBar/SideBar.config'

export const blackList = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: 'Yes', label: words['user.deleting.successLabel'] },
    { value: 'No', label: words['user.deleting.cancelLabel'] }
  ]
}
