import React, { FC } from 'react'
import { withRouter, Route } from 'react-router-dom'
import history from 'utils/history'
import { IAuthRoute } from './AuthRoute.model'

const AuthRoute: FC<IAuthRoute> = ({
  isAuthenticated,
  component: Component,
  exact,
  path,
  successPath,
  ...rest
}) => {
  if (isAuthenticated) {
    history.push(successPath)
  }

  return (
    <Route
      {...rest}
      exact={exact}
      path={path}
      component={(props: any) => (
        <div>
          <Component {...props} />
        </div>
      )}
    />
  )
}

export default withRouter(AuthRoute)
