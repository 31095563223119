import React, { FC } from 'react'
import cls from 'classnames'

type TFormatCellProps = {
  content: any
  customClass: string
}

export const FormatCell: FC<TFormatCellProps> = ({ content, customClass }) => {
  return (
    <div
      className={cls({
        [customClass]: true
      })}
    >
      {content}
    </div>
  )
}
