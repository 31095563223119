import React, { FC, useEffect, useMemo } from 'react'
import { Icons } from '../../../../../components/Icons'
import { Table } from '../../../../../components/UiKit/Table'
import { ERowSize } from '../../../../../globalConfigs'
import styles from './UserRating.module.scss'
import { TUserRatingComponent } from './UserRating.model'
import { useLoadDateFortTable } from 'hooks/useLoadDateFortTable'
import { Redirect } from 'react-router'
import queryString from 'query-string'
import { getColumns } from './UserRating.columns'
import { TUserData } from '../../../../admin/Users/Users.model'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export const UserRatingComponent: FC<TUserRatingComponent> = ({
  rating,
  total,
  getUsersRating,
  clearUsersRating,
  history
}) => {
  const parsed = queryString.parse(history.location.search)
  useEffect(() => {
    clearUsersRating()
  }, [])

  const words = useSelector((state: TState) => state.global.language.words)
  const columns = getColumns(words)
  const columnsMemoized = useMemo(() => columns, [])

  const { setCurrentPage } = useLoadDateFortTable(getUsersRating)

  const getPositions = (data: TUserData[]) => {
    if (parsed.sort !== 'ASC' && parsed.sort !== 'DESC') {
      return data
    }
    return data.map((element, index) => ({
      ...element,
      rating: parsed.sort === 'DESC' ? index + 1 : data.length - index
    }))
  }

  if (!parsed.column && !parsed.sort) {
    return (
      <Redirect to={{ search: 'column=balance&sort=DESC', pathname: history.location.pathname }} />
    )
  }
  return (
    <div className={styles.container}>
      <section>
        <div className={styles.header}>
          <h1>{words['user.profile.table.forbesList']}</h1>
        </div>
      </section>

      <section>
        <div className={styles['block-go-back']}>
          <button
            type="button"
            className={styles['go-back']}
            onClick={event => {
              event.preventDefault()
              history.push('/dashboard/profile')
            }}
          >
            <Icons icon="backArrowIcon" />
            <span className={styles['go-back-text']}>{words['back']}</span>
          </button>
        </div>
      </section>

      <div className={styles['container-flex']}>
        <div className={styles.table}>
          <Table
            columns={columnsMemoized}
            data={getPositions(rating)}
            noData={words['noData']}
            total={total}
            getData={({ page }) => {
              setCurrentPage(page)
            }}
            rowSize={ERowSize.MIDDLE}
          />
        </div>
        <div className={styles['big-icon']}>
          <Icons icon="usersRating" />
        </div>
      </div>
    </div>
  )
}
