import style from './TimeOffBalance.module.scss'
import { TFieldConfigRow } from 'components/UiKit/FieldFactory/FieldFactory.model'
import { TTimeOfBalanceInfoConfigProps } from '../../EditProfile.model'

export const timeOfBalanceInfoConfig = (
  props: TTimeOfBalanceInfoConfigProps
): TFieldConfigRow[] => {
  const { words, min, nameCurrentBalance, nameMaxValue } = props

  const row1: TFieldConfigRow = {
    items: [
      {
        name: nameCurrentBalance,
        label: words['admin.team.currentBalance'],
        required: true,
        inputWrapperClassName: style['day-off-currentBalance'],
        minValue: min,
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined',
              type: 'number',
              step: '0.001'
            }
          }
        }
      },
      {
        name: nameMaxValue,
        label: words['admin.team.maximumValue'],
        required: true,
        inputWrapperClassName: style['day-off-maximalValue'],
        minValue: 0,
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined',
              type: 'number'
            }
          }
        }
      }
    ]
  }
  return [row1]
}
