import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import queryString from 'query-string'
import Pagination from 'react-js-pagination'
import { Filter } from 'components/UiKit/Filter'
import { getFilterUsers } from './Team.filter.config'
import styles from './Team.module.scss'
import history from 'utils/history'
import { UserItem } from './components/UserItem'
import { EPageLimits } from 'globalConfigs'
import { ReactComponent as Prev } from 'assets/images/prev.svg'
import { ReactComponent as Next } from 'assets/images/next.svg'
import { TTeamProps } from './Team.model'

const Team: FC<TTeamProps> = ({ data = [], totalData, getTeamUsers, getSelectOptions }) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [pageNumber, setPageNumber] = useState(1)
  const parsedSearch = queryString.parse(history.location.search)

  const positions = useSelector((state: TState) => state.client.editProfile.positions)
  const rank = useSelector((state: TState) => state.client.editProfile.rank)

  useEffect(() => {
    getSelectOptions()
  }, [])

  useEffect(() => {
    getTeamUsers(pageNumber, `?${queryString.stringify(parsedSearch)}`, EPageLimits.TEAM)
  }, [])

  const handlePageChange = (page: number) => {
    setPageNumber(page)
    getTeamUsers(page, `?${queryString.stringify(parsedSearch)}`)
  }

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['sidebar.team']}</h1>
      </section>
      <section className={styles.filter}>
        <div className={styles['team-title']}>{words['team.users_list']}</div>
        <Filter
          config={getFilterUsers(words, {
            positions,
            rank
          })}
          defaultOpened={true}
          withButton={true}
        />
        <div className={styles['items-wrapper']}>
          <div>
            {data.map((item: any, index: number) => (
              <UserItem
                key={index}
                id={item.id}
                photo={item.photo}
                fullName={item.fullName}
                rank={item.rank}
                position={item.position}
              />
            ))}
          </div>
        </div>
        <div className={styles['pagination-container']}>
          <Pagination
            itemsCountPerPage={EPageLimits.TEAM}
            totalItemsCount={data && totalData}
            activePage={pageNumber}
            onChange={handlePageChange}
            hideFirstLastPages={true}
            prevPageText={<Prev />}
            nextPageText={<Next />}
            linkClass={styles['pagination-item']}
            activeLinkClass={styles['pagination-active']}
            disabledClass={styles['pagination-disabled']}
          />
        </div>
      </section>
    </div>
  )
}

export default Team
