import {
  GET_BASKET_REQUEST,
  GET_BASKET_SUCCESS,
  GET_BASKET_ERROR,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  REQUEST_PURCHASE_OF_GOODS_SUCCESS,
  REQUEST_PURCHASE_OF_GOODS_ERROR,
  TOGGLE_MODAL_PURCHASE
} from './Basket.constants'
import { action } from 'store/store.utils'
import { IAction, ICartItem } from './Basket.model'
import { ActionCreator } from 'redux'
import { AxiosError } from 'axios'

export const getBasketRequest: ActionCreator<IAction> = payload => ({
  type: GET_BASKET_REQUEST,
  payload
})

export const getBasketSuccess: ActionCreator<IAction> = payload => ({
  type: GET_BASKET_SUCCESS,
  payload
})

export const getBasketError: ActionCreator<IAction> = payload => ({
  type: GET_BASKET_ERROR,
  payload
})

export const addToCart: ActionCreator<IAction> = (payload: object) => ({
  type: ADD_TO_CART,
  payload
})

export const removeFromCart: ActionCreator<IAction> = (
  payload: ICartItem,
  removeBasketElement: string
) => ({
  type: REMOVE_FROM_CART,
  payload,
  removeBasketElement
})

export const successRequestPurchaseOfGoods = () => action(REQUEST_PURCHASE_OF_GOODS_SUCCESS)
export const setOpenThankForPurchase = (payload: boolean) => action(TOGGLE_MODAL_PURCHASE, payload)

export const errorRequestPurchaseOfGoods = (payload: AxiosError) =>
  action(REQUEST_PURCHASE_OF_GOODS_ERROR, payload)
