import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './Achievements.module.scss'
import { Achievement } from './components/Achievement/Achievement'
import { Filter } from 'components/UiKit/Filter'
import Pagination from 'react-js-pagination'
import { EPageLimits } from '../../../globalConfigs'
import { ReactComponent as Prev } from '../../../assets/images/prev.svg'
import { ReactComponent as Next } from '../../../assets/images/next.svg'
import { TAchievement, TAchievementsProps } from './Achievements.model'
import { TSelectOption } from '../../../components/Select'
import { TGoodsCategories } from '../../admin/GoodsItem/GoodsItem.model'
import { getFilterConfig } from './Achievements.filter.config'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { TState } from 'store'

const Achievements: FC<TAchievementsProps> = ({
  data,
  total,
  minPrice,
  maxPrice,
  categories,
  teamBalance,
  getAchievements,
  getGoodsCategories,
  getPointsSettings,
  createRequestAchievement,
  location
}) => {
  const [pageNumber, setPageNumber] = useState(1)

  const words = useSelector((state: TState) => state.global.language.words)

  const progress = (teamBalance.currentAchievementsAmount / teamBalance.achievementsAmount) * 100

  const query = queryString.stringify({
    ...queryString.parse(location.search),
    deleted: false
  })
  useEffect(() => {
    getGoodsCategories()
    getPointsSettings()
  }, [])

  useEffect(() => {
    getAchievements(pageNumber, `?${query}`)
  }, [location.search])

  const categoriesData: TSelectOption[] = useMemo(
    () =>
      categories.map((item: TGoodsCategories) => ({
        value: `${item.id}`,
        label: item.name
      })),
    [categories]
  )

  const achievementsData = data.map((item: TAchievement, index: number) => {
    return <Achievement key={index} data={item} createRequest={createRequestAchievement} />
  })

  const handlePageChange = (page: number) => {
    setPageNumber(page)
    getAchievements(page, `?${query}`)
  }

  return (
    <section className={styles.container}>
      <section className={styles.header}>
        <h1>{words['admin.settings.pointsSystem.achivements']}</h1>
      </section>
      <section className={styles['progress-bar-container']}>
        <div className={styles['achievements-container']}>
          <div className={styles['progress-bar']}>
            <div className={styles.meter}>
              <div
                className={styles.color}
                style={{
                  width: `${progress <= 100 ? progress : 100}%`
                }}
              />
            </div>
            <div className={styles['progress-bar-amount']}>
              <span className={styles['input-label']}>{`${
                words['user.pointsSystem.initiatives.teamBalance']
              }: `}</span>
              <span className={styles['red-text']}>{`${
                teamBalance.currentAchievementsAmount
              }`}</span>{' '}
              / <span className={styles['green-text']}>{`${teamBalance.achievementsAmount}`}</span>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.section_filter}>
        <Filter
          config={getFilterConfig({
            categories: categoriesData,
            minPrice,
            maxPrice,
            words
          })}
          defaultOpened={true}
        />
      </section>
      <section className={styles.main}>{achievementsData}</section>
      <section className={styles.pagination}>
        <Pagination
          itemsCountPerPage={EPageLimits.GOODS}
          totalItemsCount={data && total}
          activePage={pageNumber}
          onChange={handlePageChange}
          hideFirstLastPages={true}
          prevPageText={<Prev />}
          nextPageText={<Next />}
          linkClass={styles['pagination-item']}
          activeLinkClass={styles['pagination-active']}
          disabledClass={styles['pagination-disabled']}
        />
      </section>
    </section>
  )
}

export default Achievements
