import React, { FC, useEffect, useState } from 'react'
import Modal from 'components/Modal/index'
import { Field, Form } from 'react-final-form'
import styles from './SettingsModalPointsEditForm.module.scss'
import { Select as NewUIKitSelect } from 'Select' // TODO -- rename alias
import { Button } from 'components/UiKit/Button/index'
import { Input } from 'components/UiKit/Inputs/index'
import { TSettingsModalPointsEditForm } from './SettingsModalPointsEditForm.model'
import { Icons } from 'components/Icons/index'
import validation from 'utils/validation/index'
import { Textarea } from 'components/Textarea/index'
import { EPointsEdit } from 'globalConfigs'
import { TSelectOption } from '../../../../../../../components/Select'
import { TUpdatePointsBalanceBody, TUserData } from '../../../../../Users/Users.model'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import history from '../../../../../../../utils/history'

export const SettingsModalPointsEditForm: FC<TSettingsModalPointsEditForm> = ({
  open,
  togglePointsEdit,
  dataUsers,
  getUsers,
  updateSettingsPointsBalance
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [isSelectedAllReceivers, setIsSelectedAllReceivers] = useState(true)
  const [usersData, setUsersData] = useState<TSelectOption[]>([
    { value: 'all', label: words['admin.settings.pointsSystem.balanceEdit.userName.allUsers'] }
  ])
  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    const filteredData: TSelectOption[] = dataUsers.map(({ id, fullName }: TUserData) => ({
      value: `${id}`,
      label: `${fullName}`
    }))
    const filteredData1 = filteredData.slice()
    filteredData1.unshift({
      value: 'all',
      label: words['admin.settings.pointsSystem.balanceEdit.userName.allUsers']
    })
    setUsersData(filteredData1)
  }, [dataUsers])

  const editPointsSubmit = (values: { [key: string]: any }) => {
    const body: TUpdatePointsBalanceBody = {
      transactions: values.receiver.map((item: TSelectOption) => ({
        receiverID: item.value,
        amount: values.points,
        comment: values.comments
      }))
    }
    updateSettingsPointsBalance(body)
    history.push(`${history.location.pathname}${history.location.search}`)
  }
  return (
    <>
      <Modal isShow={open} onClose={togglePointsEdit} className={styles.modal}>
        <Form
          onSubmit={(values: { [key: string]: any }) => {
            const formValues = {
              receiver: Array.isArray(values.receiver) ? values.receiver : [values.receiver],
              points: Number(values.points),
              comments: values.comments
            }
            editPointsSubmit(formValues)
            togglePointsEdit()
          }}
          initialValues={{ receiver: usersData && usersData[0] }}
        >
          {({ form, handleSubmit }) => {
            const formState = form.getState()
            return (
              <form onSubmit={handleSubmit} className={styles.form}>
                <header>{words['admin.PointsSystem.buttons.balanceEdit']}</header>
                <div className={styles['form-row']}>
                  <div className={styles['form-receiver']}>
                    <label>
                      {`${words['admin.users.table.filters.userName']}:`}
                      <span className={styles['red-note']}>*</span>
                      <Field
                        name="receiver"
                        validate={validation.composeValidators(
                          validation.required(words['user.requiredMessage'])
                        )}
                      >
                        {({ input, meta }) => (
                          <NewUIKitSelect
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isSearchable={true}
                            maxMenuHeight={150}
                            isCheckboxesStyle={true}
                            isSelectedAll={isSelectedAllReceivers}
                            placeholder={words['user.dayOffTracker.enterSurnameAndName']}
                            emptyMessage={words['noOption']}
                            options={usersData && usersData}
                            {...input}
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                            onChange={value => {
                              if (
                                Array.isArray(value) &&
                                value.find((item: TSelectOption) => item.value === 'all')
                              ) {
                                setIsSelectedAllReceivers(true)
                                return form.change('receiver', {
                                  value: 'all',
                                  label:
                                    words[
                                      'admin.settings.pointsSystem.balanceEdit.userName.allUsers'
                                    ]
                                })
                              } else {
                                setIsSelectedAllReceivers(false)
                              }
                              return form.change('receiver', value)
                            }}
                          />
                        )}
                      </Field>
                    </label>
                  </div>

                  <div className={styles['balance-input-container']}>
                    <div className={styles['input-label']}>
                      {`${words['admin.users.table.balanceEdit.points']}:`}
                      <span className={styles['red-note']}>*</span>
                    </div>
                    <Field
                      name="points"
                      validate={validation.composeValidators(
                        validation.required(words['user.requiredMessage']),
                        validation.isNumber()
                      )}
                    >
                      {({ input, meta }) => {
                        return (
                          <div className={styles['div-wrapper']}>
                            <button
                              onClick={() => {
                                if (
                                  !formState.values.points ||
                                  typeof formState.values.points !== 'number'
                                ) {
                                  form.change('points', -EPointsEdit.STEP)
                                } else {
                                  form.change(
                                    'points',
                                    Number(formState.values.points) - EPointsEdit.STEP
                                  )
                                }
                              }}
                              type="button"
                            >
                              <Icons icon="remove" />
                            </button>
                            <div className={styles['input-wrapper']}>
                              <Input
                                variant="outlined"
                                type="number"
                                isInvalid={meta.error && meta.submitFailed}
                                errorMessage={meta.error}
                                {...input}
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  !formState.values.points ||
                                  typeof formState.values.points !== 'number'
                                ) {
                                  form.change('points', EPointsEdit.STEP)
                                } else {
                                  form.change(
                                    'points',
                                    Number(formState.values.points) + EPointsEdit.STEP
                                  )
                                }
                              }}
                            >
                              <Icons icon="add" />
                            </button>
                          </div>
                        )
                      }}
                    </Field>
                  </div>
                </div>

                <div className={styles['comments-wrapper']}>
                  <Field
                    name="comments"
                    validate={validation.composeValidators(
                      validation.required(words['user.requiredMessage']),
                      validation.minValue(2)
                    )}
                  >
                    {({ input, meta }) => {
                      return (
                        <div>
                          <Textarea
                            {...input}
                            title={
                              <span>
                                {words['admin.settings.pointsSystem.balanceEdit.comment']}
                                <span className={styles['red-note']}>*</span>
                              </span>
                            }
                            placeholder={
                              words['admin.settings.pointsSystem.balanceEdit.comment.placeHolder']
                            }
                            isInvalid={meta.error && meta.submitFailed}
                            errorMessage={meta.error}
                          />
                        </div>
                      )
                    }}
                  </Field>
                </div>

                <div className={styles['form-row-btn']}>
                  <Button type="submit" className={styles['div-btn-wrapper']}>
                    {words['admin.PointsSystem.balanceEdit.buttons.apply']}
                  </Button>
                </div>
              </form>
            )
          }}
        </Form>
      </Modal>
    </>
  )
}
