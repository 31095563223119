import { Dispatch } from 'redux'
import { TState } from 'store'
import { API, api } from 'services/api'
import toastr from 'utils/toastr'
import {
  deleteUserError,
  deleteUserRequest,
  deleteUserSuccess,
  getTeamAdminError,
  getTeamAdminRequest,
  getTeamAdminSuccess,
  getTerminationInitiatorOptionsError,
  getTerminationInitiatorOptionsSuccess,
  restoreUserError,
  restoreUserRequest,
  restoreUserSuccess,
  setOptions,
  setOptionsError,
  updateUserError,
  updateUserRequest,
  updateUserSuccess
} from './TeamAdmin.actions'
import { TUpdateTeamAdminPayload } from './TeamAdmin.model'
import { transformApiResToFabricFormat } from 'utils/transformApiResToFabricFormat'
import history from 'utils/history'

export const getTeamAdminList = (page: number = 1, search: string = '') => async (
  dispatch: Dispatch
) => {
  dispatch(getTeamAdminRequest())
  try {
    const { data } = await api.get(`${API.TEAM_ADMIN}${search}${search ? '&' : '?'}page=${page}`)
    dispatch(getTeamAdminSuccess(data.payload))
  } catch (error) {
    dispatch(getTeamAdminError(error))
  }
}

export const updateUser = (body: TUpdateTeamAdminPayload) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(updateUserRequest())
  const words = getData().global.language.words
  try {
    await api.put(`${API.CLIENT}/${body.id}`, body)
    dispatch(updateUserSuccess(body))
    toastr('info', words['SUCCESS_UPDATED'])
    // history.push('/dashboard/users')
  } catch (error) {
    dispatch(updateUserError(error))
    toastr('error', words['FILED_UPDATE_USER'], error)
    // history.push('/dashboard/users')
  }
}

export const deleteUser = (body: any) => async (dispatch: Dispatch, getData: () => TState) => {
  dispatch(deleteUserRequest())
  const words = getData().global.language.words
  try {
    await api.delete(`${API.CLIENT}/${body.id}`, { data: body })
    dispatch(deleteUserSuccess(body.id))
    toastr('info', words['SUCCESS_DELETED'])
    history.push(`teamAdmin?deleted=true`)
  } catch (error) {
    toastr('error', words['FILED_DELETE_USER'], error)
    dispatch(deleteUserError(error))
  }
}

export const restoreUser = (id: number) => async (dispatch: Dispatch, getData: () => TState) => {
  dispatch(restoreUserRequest())
  const words = getData().global.language.words
  try {
    await api.put(`${API.CLIENT_RESTORE}/${id}`)
    dispatch(restoreUserSuccess(id))
    toastr('info', words['SUCCESS_RESTORED'])
    history.push(`teamAdmin?deleted=false`)
  } catch (error) {
    toastr('error', words['FILED_RESTORE_USER'], error)
    dispatch(restoreUserError(error))
  }
}

export const getTerminationInitiatorOptions = () => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  const words = getData().global.language.words
  try {
    const { data } = await api.get(`${API.TERMINATION_INITIATOR}`)
    const options = data.payload.result.map((initiator: any) => {
      return transformApiResToFabricFormat(words, initiator)
    })
    dispatch(getTerminationInitiatorOptionsSuccess(options))
  } catch (error) {
    dispatch(getTerminationInitiatorOptionsError(error))
  }
}

export const getSelectOptionsForTeamAdmin = () => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words
  try {
    const positions = (await api.get(`${API.URL}${API.POSITIONS}`)).data.payload.result.map(
      (item: any) => transformApiResToFabricFormat(words, item)
    )

    const rank = (await api.get(`${API.URL}${API.RANK}`)).data.payload.result.map((item: any) =>
      transformApiResToFabricFormat(words, item)
    )

    const maritalStatus = (await api.get(
      `${API.URL}${API.MARITIAL_STATUS}`
    )).data.payload.result.map((item: any) => transformApiResToFabricFormat(words, item))

    const englishLevel = (await api.get(`${API.URL}${API.ENGLISH_LEVEL}`)).data.payload.result.map(
      (item: any) => transformApiResToFabricFormat(words, item)
    )

    const department = (await api.get(`${API.URL}${API.DEPARTMENT}`)).data.payload.result.map(
      (item: any) => transformApiResToFabricFormat(words, item)
    )

    const typeOfEmployment = (await api.get(
      `${API.URL}${API.TYPE_OF_EMPLOYMENT}`
    )).data.payload.result.map((item: any) => transformApiResToFabricFormat(words, item))

    dispatch(
      setOptions({
        positions,
        rank,
        maritalStatus,
        englishLevel,
        department,
        typeOfEmployment
      })
    )
  } catch (error) {
    dispatch(setOptionsError(error))
  }
}
