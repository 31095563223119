import React, { FC, useState } from 'react'
import { ReactComponent as IconUpdate } from 'assets/images/update_icon.svg'
import { ReactComponent as IconDelete } from 'assets/images/delete_icon.svg'
import { ConfirmAction } from 'components/ConfirmAction'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export type TStoreTableCell = {
  id: string | number
  onRemove: (id: string | number) => void
  onUpdate: (id: number) => void
}

export const IconsUpdateAndDelete: FC<TStoreTableCell> = ({ id, onRemove, onUpdate }) => {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const toggleConfirm = () => setOpenConfirm(!openConfirm)
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <div>
      <button onClick={() => onUpdate(Number(id))}>
        <IconUpdate />
      </button>
      <button onClick={toggleConfirm}>
        <IconDelete />
      </button>
      {openConfirm && (
        <ConfirmAction
          children={`${words['admin.users.table.buttons.delete']} ?`}
          successLabel={words['user.deleting.successLabel']}
          cancelLabel={words['user.deleting.cancelLabel']}
          onCancel={toggleConfirm}
          onSuccess={() => {
            onRemove(id)
            toggleConfirm()
          }}
        />
      )}
    </div>
  )
}
