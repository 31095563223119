import { IAction } from 'models/index'
import {
  CLEAR_TEAMADMIN_DATA,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  GET_TEAMADMIN_ERROR,
  GET_TEAMADMIN_REQUEST,
  GET_TEAMADMIN_SUCCESS,
  RESTORE_USER_ERROR,
  RESTORE_USER_REQUEST,
  RESTORE_USER_SUCCESS,
  SET_CHECKED_BOX,
  SET_CURRENT_TAB,
  SET_HIDDEN_COLUMNS,
  SET_OPTIONS_FOR_TEAMADMIN,
  SET_OPTIONS_FOR_TEAMADMIN_TERMINATIONINITIATOR,
  TOGGLE_OPEN_DEACTIVATE_MODAL,
  TOGGLE_OPEN_RESTORE_USER_MODAL,
  UPDATE_USER_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS
} from './TeamAdmin.constans'
import {
  TDeleteUserSuccess,
  TGetTeamAdminSuccess,
  TRestoreUserSuccess,
  ITeamAdminInitialState,
  TUpdateUserSuccess
} from './TeamAdmin.model'

export const initialState: ITeamAdminInitialState = {
  results: [],
  total: 0,
  loading: false,
  usersToFilter: [],
  userEdit: {
    loading: false
  },
  openDeactivateModal: false,
  restoreUser: {
    openRestoreUserModal: false,
    loading: false
  },
  hiddenColumns: [],
  currentTab: {
    tab: '',
    lang: ''
  },
  checkedItems: [],
  options: {
    department: [],
    englishLevel: [],
    maritalStatus: [],
    positions: [],
    rank: [],
    typeOfEmployment: [],
    terminationInitiator: []
  }
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_TEAMADMIN_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_TEAMADMIN_SUCCESS:
      const total = action.payload.total
      if (total < 10) {
        return {
          ...state,
          loading: false,
          results: [...(action as TGetTeamAdminSuccess).payload.results],
          total: action.payload.total
        }
      }
      return {
        ...state,
        loading: false,
        results: [...state.results, ...(action as TGetTeamAdminSuccess).payload.results],
        total: action.payload.total
      }

    case GET_TEAMADMIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case UPDATE_USER_REQUEST:
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          loading: true
        }
      }

    case UPDATE_USER_SUCCESS: {
      const userData = (action as TUpdateUserSuccess).payload

      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          loading: false
        },
        results: state.results.map(item => {
          const { id, reviewerId } = item

          if (id === userData.id) {
            return {
              ...item,
              ...userData,
              reviewerId
            }
          }
          return item
        })
      }
    }

    case DELETE_USER_SUCCESS: {
      const { id } = (action as TDeleteUserSuccess).payload
      return {
        ...state,
        results: state.results.filter(item => item.id !== id),
        openDeactivateModal: false
      }
    }

    case TOGGLE_OPEN_DEACTIVATE_MODAL: {
      return {
        ...state,
        openDeactivateModal: !state.openDeactivateModal
      }
    }

    case RESTORE_USER_REQUEST: {
      return {
        ...state,
        restoreUser: {
          ...state.restoreUser,
          loading: true
        }
      }
    }

    case RESTORE_USER_SUCCESS: {
      const { id } = (action as TRestoreUserSuccess).payload
      return {
        ...state,
        results: state.results.filter(item => item.id !== id),
        restoreUser: {
          ...state.restoreUser,
          loading: false,
          openRestoreUserModal: false
        }
      }
    }

    case RESTORE_USER_ERROR:
      return {
        ...state,
        restoreUser: {
          ...state.restoreUser,
          loading: false
        }
      }

    case TOGGLE_OPEN_RESTORE_USER_MODAL: {
      return {
        ...state,
        restoreUser: {
          ...state.restoreUser,
          openRestoreUserModal: !state.restoreUser.openRestoreUserModal
        }
      }
    }

    case UPDATE_USER_ERROR:
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          loading: false
        }
      }

    case DELETE_USER_ERROR:
      return {
        ...state
      }

    case SET_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload
      }
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload
      }
    case SET_CHECKED_BOX:
      return {
        ...state,
        checkedItems: action.payload
      }
    case SET_OPTIONS_FOR_TEAMADMIN_TERMINATIONINITIATOR:
      return {
        ...state,
        options: {
          ...state.options,
          terminationInitiator: action.payload
        }
      }
    case SET_OPTIONS_FOR_TEAMADMIN:
      return {
        ...state,
        options: {
          ...state.options,
          department: action.payload.department,
          englishLevel: action.payload.englishLevel,
          maritalStatus: action.payload.maritalStatus,
          positions: action.payload.positions,
          rank: action.payload.rank,
          typeOfEmployment: action.payload.typeOfEmployment
        }
      }
    case CLEAR_TEAMADMIN_DATA:
      return {
        ...state,
        results: []
      }
    default:
      return state
  }
}
