export const GET_POINTS_SETTINGS_REQUEST = 'GET_POINTS_SETTINGS_REQUEST'
export const GET_POINTS_SETTINGS_SUCCESS = 'GET_POINTS_SETTINGS_SUCCESS'
export const GET_POINTS_SETTINGS_ERROR = 'GET_POINTS_SETTINGS_ERROR'

export const UPDATE_POINTS_SETTINGS_REQUEST = 'UPDATE_POINTS_SETTINGS_REQUEST'
export const UPDATE_POINTS_SETTINGS_SUCCESS = 'UPDATE_POINTS_SETTINGS_SUCCESS'
export const UPDATE_POINTS_SETTINGS_ERROR = 'UPDATE_POINTS_SETTINGS_ERROR'

export const UPDATE_SETTINGS_POINTS_BALANCE_REQUEST = 'UPDATE_SETTINGS_POINTS_BALANCE_REQUEST'
export const UPDATE_SETTINGS_POINTS_BALANCE_SUCCESS = 'UPDATE_SETTINGS_POINTS_BALANCE_SUCCESS'
export const UPDATE_SETTINGS_POINTS_BALANCE_ERROR = 'UPDATE_SETTINGS_POINTS_BALANCE_ERROR'
