import React, { FC } from 'react'
import { withRouter } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { IAccordionItem } from './model'
import styles from './style.module.scss'

const AccordionItem: FC<IAccordionItem> = ({ to, title }) => (
  <li className={styles.accordion_li}>
    <NavLink
      exact={true}
      className={styles.accordion_a}
      activeClassName={styles.accordion_a_active}
      to={to}
    >
      {title}
    </NavLink>
  </li>
)

export default withRouter(AccordionItem)
