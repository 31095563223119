import React from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import cls from 'classnames'
import moment from 'moment'
import { Field } from 'react-final-form'

import styles from '../../EditProfile.module.scss'
import style from './TimeOffBalance.module.scss'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { timeOfBalanceInfoConfig } from './TimeOffBalance.config'
import { Input } from 'components/UiKit/Inputs'
import { DatePicker } from 'components/UiKit/DatePicker'
import { addCurrentTime, formatterDateForDatePicker } from 'components/UiKit/Filter/Filter'
import { config } from 'globalConfigs'

export const TimeOffBalance: any = ({
  form,
  isAdmin,
  trackerStatus,
  setTrackerStatus,
  user
}: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const formState = form.getState()
  const min = -formState.values.dayOffLimit
  return (
    <>
      {isAdmin ? (
        <section className={styles.section}>
          <h3>{words['admin.users.table.Edit.editTime']}</h3>
          <div
            className={cls({
              [style.row]: true,
              [style['mrg-b']]: true
            })}
          >
            <div className={style['left-block']}>
              <span className={style.title}>
                {words['user.dayOffTracker.table.requestType.dayOff']}
              </span>
              <FieldFactory
                form={form}
                config={timeOfBalanceInfoConfig({
                  words,
                  min,
                  nameCurrentBalance: 'dayOffBalance',
                  nameMaxValue: 'dayOffLimit'
                })}
                words={words}
              />
            </div>
            <div className={style['right-block']}>
              <span className={style.title}>
                {words['user.dayOffTracker.table.requestType.remote']}
              </span>
              <FieldFactory
                form={form}
                config={timeOfBalanceInfoConfig({
                  words,
                  min: 0,
                  nameCurrentBalance: 'totalRemote',
                  nameMaxValue: 'remoteLimit'
                })}
                words={words}
              />
            </div>
          </div>
          <div className={style.row}>
            <div className={style['left-block']}>
              <span className={style.title}>
                {words['user.dayOffTracker.table.requestType.sick']}
              </span>
              <FieldFactory
                form={form}
                config={timeOfBalanceInfoConfig({
                  words,
                  min: 0,
                  nameCurrentBalance: 'totalSick',
                  nameMaxValue: 'sickLimit'
                })}
                words={words}
              />
            </div>
            <div className={style['right-block']}>
              <span className={style.title}>{words['admin.users.table.Edit.stopTimerDate']}</span>
              <div className={style.row}>
                <Field name="isTerminating" type="checkbox">
                  {({ input }) => {
                    return (
                      <div className={`${style['div-wrapper']} ${style['checkbox-container']}`}>
                        <div className={style['checkbox-container']}>
                          <Input
                            id="stop-timer-checkbox"
                            variant="outlined"
                            type="checkbox"
                            onClick={() => {
                              setTrackerStatus(!trackerStatus)
                              if (trackerStatus) {
                                form.change('firstTerminatingDay', null)
                              } else if (user && user.firstTerminatingDay) {
                                form.change(
                                  'firstTerminatingDay',
                                  formatterDateForDatePicker(
                                    moment(user.firstTerminatingDay).valueOf()
                                  )
                                )
                              } else {
                                form.change('firstTerminatingDay', moment().valueOf())
                              }
                            }}
                            {...input}
                          />
                          <label htmlFor="stop-timer-checkbox" className={style['checkbox-label']}>
                            <div>{words['admin.users.table.Edit.stopTimer']}</div>
                          </label>
                        </div>
                      </div>
                    )
                  }}
                </Field>
                <Field name="firstTerminatingDay">
                  {({ input }) => {
                    return (
                      <div
                        className={cls({
                          [style['div-wrapper']]: true,
                          [style['time-tracker']]: true
                        })}
                      >
                        <label className={style['field-label']}>
                          {words['admin.users.table.Edit.stopTimerDate']}
                        </label>
                        <DatePicker
                          disabled={!trackerStatus}
                          locale={currentLanguage}
                          name={input.name}
                          autoComplete="off"
                          dateFormat={config.dateFormat}
                          value={trackerStatus ? input.value : ''}
                          selected={input.value && trackerStatus ? new Date(input.value) : null}
                          onChange={event => {
                            if (event) {
                              form.change(
                                input.name,
                                formatterDateForDatePicker(addCurrentTime(event))
                              )
                            }
                          }}
                        />
                      </div>
                    )
                  }}
                </Field>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  )
}
