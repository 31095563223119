import React, { FC } from 'react'
import Modal from 'components/Modal'
import { Button } from 'components/UiKit/Button'
import styles from './CancelModal.module.scss'
import history from 'utils/history'

type TCancelModal = {
  open: boolean
  toggle: () => void
  onHandleSubmit: (values: any) => void
}

export const CancelModal: FC<TCancelModal> = ({ open, toggle, onHandleSubmit }) => {
  return (
    <Modal isShow={open} onClose={toggle} className={styles.modal} withoutCross={true}>
      <div className={styles.container}>
        <header> Сохранить внесенные изменения? </header>
        <p>При выборе “не сохранять” внесенные данные будут утеряны</p>
        <div className={styles['container-btn']}>
          <Button
            outline={true}
            className={styles['btn']}
            onClick={() => history.push('/dashboard/point-system/achievements')}
          >
            Не сохранять
          </Button>
          <Button className={styles['btn']} onClick={onHandleSubmit}>
            Сохранить
          </Button>
        </div>
      </div>
    </Modal>
  )
}
