import React, { FC, useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import styles from './SettingsTimeOfRequestUpdate.module.scss'
import { Select } from 'Select'
import { config, FILTER_SELECT_PRODUCT_CALENDAR_TYPE } from 'globalConfigs'
import { Button } from 'components/UiKit/Button'
import DatePicker from 'react-datepicker'
import { Input } from 'components/UiKit/Inputs'
import { TSelectOption } from '../../../../../components/Select'
import { mapDispatchToProps, mapStateToProps } from './SettingsTimeOfRequestUpdate.container'
import { TSetProductCalendarBody } from './SettingsTimeOfRequestUpdate.thunx'
import { addCurrentTime, formatterDateForDatePicker } from 'components/UiKit/Filter/Filter'
import moment from 'moment'
import validation from '../../../../../utils/validation'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TSettingsTimeOfRequestUpdateProps = {
  calendarId: number | null
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export const SettingsTimeOfRequestUpdate: FC<TSettingsTimeOfRequestUpdateProps> = ({
  calendarId,
  calendarItem,
  getProductCalendarItem,
  updateProductCalendar
}) => {
  useEffect(() => {
    if (calendarId) {
      getProductCalendarItem(calendarId)
    }
  }, [])
  const words = useSelector((state: TState) => state.global.language.words)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const date = moment(calendarItem.date, 'MM-DD-YYYY').valueOf()

  return (
    <Form
      initialValues={{
        type: calendarItem.type,
        description: calendarItem.description,
        date: calendarItem.date ? formatterDateForDatePicker(addCurrentTime(date)) : null
      }}
      onSubmit={(values: any) => {
        const body: TSetProductCalendarBody = {
          type: values.type,
          description: values.description,
          date: values.date
        }
        if (calendarId) {
          updateProductCalendar(calendarId, body)
        }
      }}
    >
      {({ form, handleSubmit }) => {
        return (
          <>
            <header className={styles.header}>
              {words['admin.productionCalendar.edit.header']}
            </header>
            <form onSubmit={handleSubmit} className={styles.container}>
              <div className={styles.row}>
                <div className={styles['input-label']}>
                  {words['admin.productionCalendar.new.type']}
                </div>
                <div className={styles['input-label']}>
                  {words['admin.productionCalendar.new.name']}
                </div>
                <div className={styles['input-label']}>
                  {words['admin.productionCalendar.new.date']}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.type}>
                  <Field name="type">
                    {({ input }) => {
                      return (
                        <Select
                          {...input}
                          value={FILTER_SELECT_PRODUCT_CALENDAR_TYPE(words).find(
                            predicate => input.value === Number(predicate.value)
                          )}
                          options={FILTER_SELECT_PRODUCT_CALENDAR_TYPE(words)}
                          onChange={event => {
                            form.change(input.name, Number((event as TSelectOption).value))
                          }}
                          emptyMessage={words['noOption']}
                        />
                      )
                    }}
                  </Field>
                </div>
                <div className={styles.description}>
                  <Field
                    name="description"
                    validate={validation.composeValidators(
                      validation.required(words['user.requiredMessage'])
                    )}
                  >
                    {({ input, meta }) => (
                      <Input
                        placeholder={words['admin.productionCalendar.new.description']}
                        variant="outlined"
                        isInvalid={meta.error && meta.submitFailed}
                        errorMessage={meta.error}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles.date}>
                  <Field name="date">
                    {({ input }) => (
                      <DatePicker
                        locale={currentLanguage}
                        name={input.name}
                        autoComplete="off"
                        dateFormat={config.dateFormat}
                        selected={input.value ? input.value : null}
                        onChange={event => {
                          if (!event) {
                            return form.change(
                              input.name,
                              formatterDateForDatePicker(addCurrentTime(new Date()))
                            )
                          }
                          form.change(input.name, formatterDateForDatePicker(addCurrentTime(event)))
                        }}
                        startDate={new Date()}
                        minDate={new Date()}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className={styles.buttons}>
                <Button type="submit" className={styles.save}>
                  {words['admin.productionCalendar.new.buttons.save']}
                </Button>
              </div>
            </form>
          </>
        )
      }}
    </Form>
  )
}
