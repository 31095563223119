import { action } from 'store/store.utils'
import { AxiosError } from 'axios'
import {
  CHANGE_USER_CITY_ERROR,
  CHANGE_USER_CITY_REQUEST,
  CHANGE_USER_CITY_SUCCESS,
  CHANGE_USER_DAY_OF_BIRTH_ERROR,
  CHANGE_USER_DAY_OF_BIRTH_REQUEST,
  CHANGE_USER_DAY_OF_BIRTH_SUCCESS,
  CHANGE_USER_POINTS_TRANSFER_SUCCESS,
  CHANGE_USER_POINTS_TRANSFER_ERROR
} from '../../auth/Login/Login.constants'
import { TUpdatePointsBalanceBody } from '../../admin/Users/Users.model'
import {
  CHECK_USER_PROFILE_ERROR,
  CHECK_USER_PROFILE_REQUEST,
  CHECK_USER_PROFILE_SUCCESS,
  CLEAR_HISTORY_DATA,
  CLEAR_TRANSACTIONS_DATA,
  GET_ALL_USERS_LIST,
  GET_HISTORY_TRANSACTIONS_ERROR,
  GET_HISTORY_TRANSACTIONS_REQUEST,
  GET_HISTORY_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TUSER_PROFILE_INFO_ERROR,
  GET_USER_PROFILE_INFO_REQUEST,
  GET_USER_PROFILE_INFO_SUCCESS
} from './Profile.constants'
import { IActionOnlyType } from 'global.model'
import { IAllUsersList, THistoryTransactions } from './Profile.model'

export const requestUserDay0fBirth = (): IActionOnlyType => action(CHANGE_USER_DAY_OF_BIRTH_REQUEST)

export const successUserDay0fBirth = (payload: any) =>
  action(CHANGE_USER_DAY_OF_BIRTH_SUCCESS, payload)

export const errorUserDay0fBirth = (payload: AxiosError) =>
  action(CHANGE_USER_DAY_OF_BIRTH_ERROR, payload)

export const requestUserCity = (): IActionOnlyType => action(CHANGE_USER_CITY_REQUEST)

export const successUserCity = (payload: any) => action(CHANGE_USER_CITY_SUCCESS, payload)

export const errorUserCity = (payload: AxiosError) => action(CHANGE_USER_CITY_ERROR, payload)

export const successUserPointsTransfer = (payload: TUpdatePointsBalanceBody) =>
  action(CHANGE_USER_POINTS_TRANSFER_SUCCESS, payload)
export const errorUserPointsTransfer = (payload: AxiosError) =>
  action(CHANGE_USER_POINTS_TRANSFER_ERROR, payload)

export const getHistoryRequest = (): IActionOnlyType => action(GET_HISTORY_TRANSACTIONS_REQUEST)
export const getHistorySuccess = (payload: THistoryTransactions) =>
  action(GET_HISTORY_TRANSACTIONS_SUCCESS, payload)
export const getHistoryError = (payload: AxiosError) =>
  action(GET_HISTORY_TRANSACTIONS_ERROR, payload)
export const clearHistoryData = (): IActionOnlyType => action(CLEAR_HISTORY_DATA)

export const getTransactionsRequest = (): IActionOnlyType => action(GET_TRANSACTIONS_REQUEST)
export const getTransactionsSuccess = (payload: THistoryTransactions) =>
  action(GET_TRANSACTIONS_SUCCESS, payload)
export const getTransactionsError = (payload: AxiosError) => action(GET_TRANSACTIONS_ERROR, payload)
export const clearTransactionsData = () => action(CLEAR_TRANSACTIONS_DATA)

export const getUserProfileInfoRequest = (): IActionOnlyType =>
  action(GET_USER_PROFILE_INFO_REQUEST)
export const getUserProfileInfoSuccess = (payload: any) =>
  action(GET_USER_PROFILE_INFO_SUCCESS, payload)
export const getUserProfileInfoError = (payload: AxiosError) =>
  action(GET_TUSER_PROFILE_INFO_ERROR, payload)
export const getUsersListSuccess = (payload: IAllUsersList[]) => action(GET_ALL_USERS_LIST, payload)

export const checkUserProfileRequest = (): IActionOnlyType => action(CHECK_USER_PROFILE_REQUEST)
export const checkUserProfileSuccess = (payload: any) => action(CHECK_USER_PROFILE_SUCCESS, payload)
export const checkUserProfileError = (payload: AxiosError) =>
  action(CHECK_USER_PROFILE_ERROR, payload)
