import React, { FC } from 'react'
import { Button } from '../UiKit/Button'
import { Icons } from '../Icons'
import { TButtonFilter } from './ButtonFilter.model'
import styles from './ButtonFilter.module.scss'
import cls from 'classnames'

export const ButtonFilter: FC<TButtonFilter> = ({ isOpen, clickOpen }) => {
  return (
    <Button
      className={cls({
        [styles.container]: true,
        [styles['is-open']]: isOpen
      })}
      onClick={clickOpen}
    >
      <Icons icon="buttonFilter" />
    </Button>
  )
}
