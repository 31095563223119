import React, { FC, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import Header from '../../../Header'
import Sidebar from '../../../SideBar'
import { TDashboard } from './Dashboard.model'
import styles from './Dashboard.module.scss'
import { NotFound } from '../../../../components/NotFound'
import cls from 'classnames'
import storage from '../../../../utils/storage'

const sideBarKey = 'side_bar'

export const Dashboard: FC<TDashboard> = ({ successPath, routes, sideBarMenu, languageObject }) => {
  const [collapsed, setCollapsed] = useState(!!Number(storage.get(sideBarKey)))

  return (
    <div className={cls({ [styles.container]: true, [styles.collapsed]: collapsed })}>
      <Sidebar
        successPath={successPath}
        mainMenu={sideBarMenu}
        languageObject={languageObject}
        onCollapsed={setCollapsed}
      />
      <main className={styles.main}>
        {<Header />}
        <Switch>
          {routes.map((route: any, index: number) => (
            <Route key={index} exact={route.exact} path={route.path} component={route.component} />
          ))}

          {routes.length > 0 && <Route component={NotFound} />}
        </Switch>
      </main>
    </div>
  )
}
