import React, { FC } from 'react'
import cls from 'classnames'
import styles from './DischargeButton.module.scss'
import { TBaseProps } from './BaseButton'
import { Icons } from '../../Icons'

export type TDischargeButton = TBaseProps

export const DischargeButton: FC<TDischargeButton> = ({
  className = '',
  children,
  innerRef,
  disabled = false,
  outline = false,
  type = 'button',
  variant = 'primary',
  ...rest
}) => (
  <button
    {...rest}
    ref={innerRef}
    type={type}
    className={cls({
      [className]: true,
      [styles['btn-primary']]: true,
      [styles['btn-outline']]: outline,
      [styles['btn-disabled']]: disabled
    })}
    disabled={disabled}
  >
    {children} <Icons icon="dischargeOutline" />
  </button>
)
