import React, { FC, useEffect, useState } from 'react'
import styles from './Header.module.scss'
import { IHeader } from './Header.model'
import { OpenBasketPopup } from 'screens/client/Basket/components/OpenBasketPopup/OpenBasketPopup'
import { getTimer } from 'screens/auth/Login/Login.thunx'
import { useUser } from '../../hooks/useUser'
import { HeaderMenu } from './components/HeaderMenu'
import { Icons } from 'components/Icons'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import Modal from 'components/Modal'
import { Button } from 'components/UiKit/Button'
import { isAdmin, isSuperAdmin } from 'utils/user'

export const Header: FC<IHeader> = ({
  user,
  userPhoto,
  totalQuantity,
  totalPrice,
  basket,
  addToCart,
  removeFromCart,
  pulling,
  logout,
  getPointsSettings,
  pointsFundPerMonth,
  pointsFund,
  getUserPhoto,
  profileStatus,
  profileLoading,
  checkUserProfile,
  history
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    checkUserProfile(user.id)
    getPointsSettings()
    pulling()
    return () => {
      clearTimeout(getTimer())
    }
  }, [])

  useEffect(() => {
    getUserPhoto(user.id)
  }, [userPhoto])

  useEffect(() => {
    if (profileStatus && !profileLoading) {
      const key = checkRequiredInfo()
      if (key) setOpenModal(true)
      if (profileStatus === 'completed') setOpenModal(false)
    }
  }, [profileLoading])

  const checkRequiredInfo = () => {
    if (
      history.location.pathname === '/dashboard/profile/editProfile' ||
      isSuperAdmin(user.role) ||
      isAdmin(user.role)
    ) {
      setOpenModal(false)
      return null
    }
    if (profileStatus !== 'completed') {
      return profileStatus
    }
    return null
  }

  const closeRedirectModal = () => {
    const key = checkRequiredInfo()
    if (key) {
      history.push(`/dashboard/profile/editProfile?profileType=${key}&userId=${user.id}`)
    } else {
      setOpenModal(false)
    }
  }

  const { userData } = useUser()
  const isUserSide = userData.manager || userData.user
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <>
      <section className={styles.container}>
        {isUserSide && (
          <>
            <OpenBasketPopup
              totalQuantity={totalQuantity}
              totalPrice={totalPrice}
              basket={basket}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
              balance={user.balance}
            />
            <div className={styles.points}>
              <div className={styles.points_icon}>
                <Icons icon="pointIcon" />
              </div>
              <div className={styles.points_desc}>
                <span className={styles.points_balance}>
                  {words['user.header.balance']}: <span> {user.balance}</span>
                </span>
                <span className={styles.points_currency}>
                  {words['user.header.pointsFund']}: <span> {pointsFundPerMonth - pointsFund}</span>
                </span>
              </div>
            </div>
          </>
        )}
        <HeaderMenu photo={userPhoto || ''} userSide={isUserSide} logout={logout} />
        <Modal isShow={openModal} onClose={closeRedirectModal} className={styles['redirect-modal']}>
          <p>{words['user.popup.infoIsRequired']}</p>
          <div className={styles['required-info-image']} />
          <div className={styles['btn-container']}>
            <Button type="button" className={styles['send-friend']} onClick={closeRedirectModal}>
              {words['user.popup.fillIn']}
            </Button>
          </div>
        </Modal>
      </section>
    </>
  )
}
