export const SET_ORDER_IN_COLUMN_REQUEST = 'SET_ORDER_IN_COLUMN_REQUEST'
export const SET_ORDER_IN_COLUMN_SUCCESS = 'SET_ORDER_IN_COLUMN_SUCCESS'
export const SET_ORDER_IN_COLUMN_ERROR = 'SET_ORDER_IN_COLUMN_ERROR'
export const SET_ORDER_IN_COLUMN_SUCCESS_TIME = 'SET_ORDER_IN_COLUMN_SUCCESS_TIME'
export const SET_PAGE_REQUEST = 'SET_PAGE_REQUEST'
export const SET_PAGE_SUCCESS = 'SET_PAGE_SUCCESS'
export const SET_PAGE_ERROR = 'SET_PAGE_ERROR'

// export const APPROVE_TIME_OFF_REQUEST = 'APPROVE_TIME_OFF_REQUEST'
// export const APPROVE_TIME_OFF_SUCCESS = 'APPROVE_TIME_OFF_SUCCESS'
// export const APPROVE_TIME_OFF_ERROR = 'APPROVE_TIME_OFF_ERROR'
//
// export const REJECT_TIME_OFF_REQUEST = 'REJECT_TIME_OFF_REQUEST'
// export const REJECT_TIME_OFF_SUCCESS = 'REJECT_TIME_OFF_SUCCESS'
// export const REJECT_TIME_OFF_ERROR = 'REJECT_TIME_OFF_ERROR'

export const ACTION_TIME_OFF_REQUEST = 'ACTION_TIME_OFF_REQUEST'
export const ACTION_TIME_OFF_SUCCESS = 'ACTION_TIME_OFF_SUCCESS'
export const ACTION_TIME_OFF_ERROR = 'ACTION_TIME_OFF_ERROR'

export const GET_CURRENT_ROW_REQUEST_SUCCESS = 'GET_CURRENT_ROW_REQUEST_SUCCESS'
export const GET_CURRENT_ROW_REQUEST_ERROR = 'GET_CURRENT_ROW_REQUEST_ERROR'
