import {
  GET_ACHIEVEMENTS_REQUEST,
  GET_ACHIEVEMENTS_SUCCESS,
  GET_ACHIEVEMENTS_ERROR,
  CREATE_REQUEST_ACHIEVEMENTS_SUCCESS,
  CREATE_REQUEST_ACHIEVEMENTS_ERROR,
  DELETE_ACHIEVEMENT_SUCCESS,
  DELETE_ACHIEVEMENT_ERROR,
  RESTORE_ACHIEVEMENT_ERROR,
  RESTORE_ACHIEVEMENT_SUCCESS,
  CREATE_ACHIEVEMENT_REQUEST,
  CREATE_ACHIEVEMENT_SUCCESS,
  CREATE_ACHIEVEMENT_ERROR,
  GET_CURRENT_ACHIEVEMENT_REQUEST,
  GET_CURRENT_ACHIEVEMENT_SUCCESS,
  GET_CURRENT_ACHIEVEMENT_ERROR,
  CLEAR_ACHIEVEMENTS_DATA
  // EDIT_ACHIEVEMENT_REQUEST,
  // CREATE_REQUEST_ACHIEVEMENTS_SUCCESS,
  // CREATE_REQUEST_ACHIEVEMENTS_ERROR
} from './Achievements.constants'
import { action } from 'store/store.utils'
import { AxiosError } from 'axios'
import { TAchievement, TAchievementsPayload } from './Achievements.model'

export const getAchievementsRequest = () => action(GET_ACHIEVEMENTS_REQUEST)

export const getAchievementsSuccess = (payload: TAchievementsPayload) =>
  action(GET_ACHIEVEMENTS_SUCCESS, payload)

export const getAchievementsError = (payload: AxiosError) => action(GET_ACHIEVEMENTS_ERROR, payload)
export const clearAchievementsData = () => action(CLEAR_ACHIEVEMENTS_DATA)

export const createRequestAchievementSuccess = (payload: number) =>
  action(CREATE_REQUEST_ACHIEVEMENTS_SUCCESS, payload)

export const createRequestAchievementError = (payload: AxiosError) =>
  action(CREATE_REQUEST_ACHIEVEMENTS_ERROR, payload)

export const deleteAchievementSuccess = (id: number) => action(DELETE_ACHIEVEMENT_SUCCESS, { id })
export const deleteAchievementError = (payload: AxiosError) =>
  action(DELETE_ACHIEVEMENT_ERROR, payload)

export const restoreAchievementSuccess = (id: number) => action(RESTORE_ACHIEVEMENT_SUCCESS, { id })
export const restoreAchievementError = (payload: AxiosError) =>
  action(RESTORE_ACHIEVEMENT_ERROR, payload)

export const createOrEditAchievementRequest = () => action(CREATE_ACHIEVEMENT_REQUEST)
export const createOrEditAchievementSuccess = (payload: any) =>
  action(CREATE_ACHIEVEMENT_SUCCESS, payload)
export const createOrEditAchievementError = (payload: AxiosError) =>
  action(CREATE_ACHIEVEMENT_ERROR, payload)

export const getCurrentAchievementRequest = () => action(GET_CURRENT_ACHIEVEMENT_REQUEST)
export const getCurrentAchievementSuccess = (payload: TAchievement) =>
  action(GET_CURRENT_ACHIEVEMENT_SUCCESS, payload)
export const getCurrentAchievementError = (payload: AxiosError) =>
  action(GET_CURRENT_ACHIEVEMENT_ERROR, payload)
