import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from '../../../store'
import { Requests as RequestsComponent } from './Requests'
import { actionForRequests, getRequests } from './Requests.thunx'
import { clearRequestsData } from './Requests.actions'
import { getUsersList } from 'screens/client/Profile/Profile.thunx'
export const mapStateToProps = (state: TState) => {
  return {
    data: state.admin.requests.data,
    minFromDate: state.admin.requests.minFromDate,
    maxToDate: state.admin.requests.maxToDate,
    minPrice: state.admin.requests.minPrice,
    maxPrice: state.admin.requests.maxPrice,
    total: state.admin.requests.total,
    usersList: state.client.profile.usersList
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getRequests, actionForRequests, getUsersList, clearRequestsData }, dispatch)

export const Requests = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RequestsComponent))
