import { connect } from 'react-redux'
import { BasketComponent } from './Basket'
import { addToCart, removeFromCart, setOpenThankForPurchase } from './Basket.actions'
import { bindActionCreators, Dispatch } from 'redux'
import { getTotalQuantitySelector, getTotalPriseSelector } from './Basket.reducer'
import { TState } from 'store'
import { createRequestPurchaseOfGoods } from './Basket.thunx'

export const mapStateToProps = (state: TState) => ({
  data: state.client.basket.data,
  loading: state.client.basket.loading,
  totalQuantity: getTotalQuantitySelector(state),
  totalPrice: getTotalPriseSelector(state),
  balance: state.auth.data.balance,
  openThankForPurchase: state.client.basket.openThankForPurchase
})
export const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      addToCart,
      removeFromCart,
      createRequestPurchaseOfGoods,
      setOpenThankForPurchase
    },
    dispatch
  )
})

export const Basket = connect(
  mapStateToProps,
  mapDispatchToProps
)(BasketComponent)
