import React, { FC, useState } from 'react'
import styles from './Reviews.module.scss'
import { ReviewCard } from './components/ReviewCard/ReviewCard'
import { TReviewsItem } from '../../screens/admin/Shop/Shop.model'
import { CreateReview } from './components/CreateReview'
import { useUser } from '../../hooks/useUser'
import { TAutorReview } from './components/CreateReview/CreateReview'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export type TReviewsProps = {
  reviews: TReviewsItem[]
  isRemovable: boolean
  isCreatable: boolean
  onRemove: (id: number) => void
  onSubmit?: (body: { comment: string; rating: number | null }, authorReview: TAutorReview) => void
}

export const Reviews: FC<TReviewsProps> = ({
  reviews,
  isRemovable,
  onRemove,
  isCreatable,
  onSubmit
}) => {
  const { userData } = useUser()
  const [limit, setLimit] = useState(3)
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <div className={styles.container}>
      {isCreatable && onSubmit && <CreateReview onSubmit={onSubmit} />}
      <h1>{words['user.pointsSystem.market.table.rewiewList']}</h1>
      {reviews &&
        reviews.map((item, index) => {
          if (index < limit) {
            return (
              <ReviewCard
                key={index}
                isRemovable={
                  !isRemovable && userData.data && userData.data.id === item.authorId.id
                    ? true
                    : isRemovable
                }
                reviewItem={item}
                onRemove={onRemove}
              />
            )
          } else {
            return null
          }
        })}

      {reviews && reviews.length > limit && (
        <div className={styles['btn-container']}>
          <button className={styles['btn-extra-view']} onClick={() => setLimit(limit + 6)}>
            {words['user.pointsSystem.market.table.showMoreRewiew']}
          </button>
        </div>
      )}
    </div>
  )
}
