import { IAction } from 'models'
import { ActionCreator } from 'redux'
import {
  GET_TIME_OFF_REQUESTS_REQUEST,
  GET_TIME_OFF_REQUESTS_SUCCESS,
  GET_TIME_OFF_REQUESTS_ERROR,
  ACTION_TIME_OFF_REQUEST,
  ACTION_TIME_OFF_SUCCESS,
  ACTION_TIME_OFF_ERROR,
  GET_CURRENT_ROW_REQUEST_SUCCESS,
  GET_CURRENT_ROW_REQUEST_ERROR
} from './TimeOffRequests.constants'
import { action } from 'store/store.utils'
import { TTimeOffRequestsData } from './TimeOffRequests.reducer'
import { AxiosError } from 'axios'

export const requestGetTimeOffRequests = () => action(GET_TIME_OFF_REQUESTS_REQUEST)

export const successGetTimeOffRequests = (payload: TTimeOffRequestsData) =>
  action(GET_TIME_OFF_REQUESTS_SUCCESS, payload)
export const errorGetTimeOffRequests = (err: AxiosError) => action(GET_TIME_OFF_REQUESTS_ERROR, err)

export const requestActionTimeOff: ActionCreator<IAction> = payload => ({
  type: ACTION_TIME_OFF_REQUEST,
  payload
})
export const successActionTimeOff: ActionCreator<IAction> = payload => ({
  type: ACTION_TIME_OFF_SUCCESS,
  payload
})
export const errorActionTimeOff: ActionCreator<IAction> = payload => ({
  type: ACTION_TIME_OFF_ERROR,
  payload
})

export const successGetCurrentRowRequest = (payload: any) =>
  action(GET_CURRENT_ROW_REQUEST_SUCCESS, payload)
// export type TSuccessGetCurrentRowRequest = ReturnType<typeof successGetCurrentRowRequest>

export const errorGetCurrentRowRequest = (error: AxiosError) =>
  action(GET_CURRENT_ROW_REQUEST_ERROR, error)
// export type TErrorGetCurrentRowRequest = ReturnType<typeof errorGetCurrentRowRequest>
