import { Button } from 'components/UiKit/Button'
import React from 'react'
import styles from './UserItem.module.scss'
import history from 'utils/history'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { EProfileHistory, ETransactionHistory } from 'globalConfigs'

export const UserItem = ({ id, photo, fullName, rank, position }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const noData = <span className={styles['noData']}>{words['noData']}</span>

  return (
    <div className={styles['item']}>
      <img src={photo} />
      <h3>{fullName}</h3>
      <p>{rank && position ? `${words[rank.name]} ${words[position.name]}` : noData}</p>
      <Button
        type="button"
        className={styles['open-profile-btn']}
        onClick={() =>
          history.push(
            `/dashboard/foreign-profile?userId=${id}&profileType=${
              EProfileHistory.MAIN_INFO
            }&type=${ETransactionHistory.GOODS}`
          )
        }
      >
        {words['team.userItem.openBtn']}
      </Button>
    </div>
  )
}
