import React, { FC, useMemo, useState } from 'react'
import Modal from 'components/Modal'
import { SettingsTimeOfRequestForm } from '../SettingsTimeOfRequestForm'
import { Button } from 'components/UiKit/Button'
import { Options, Table } from 'components/UiKit/Table'
import { useSelector } from 'react-redux'
import styles from './SettingsTimeOfRequest.module.scss'
import { mapStateToProps, mapDispatchToProps } from './SettingsTimeOfRequest.container'
import { useLoadDateFortTable } from 'hooks/useLoadDateFortTable'
import { Column } from 'react-table'
import moment from 'moment'
import { PRODUCT_CALENDAR_TYPE } from 'globalConfigs'
import { SettingsTimeOfRequestUpdate } from '../SettingsTimeOfRequestUpdate'
import { TState } from 'store'

type TSettingsTimeOfRequestProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export const SettingsTimeOfRequest: FC<TSettingsTimeOfRequestProps> = ({
  setProductCalendar,
  getProductCalendar,
  dataTotal,
  dataTable,
  deleteProductCalendar
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
  const [calendarId, setCalendarId] = useState<number | null>(null)
  const toggleCreateModal = () => setOpenCreateModal(!openCreateModal)
  const toggleUpdateModal = () => setOpenUpdateModal(!openUpdateModal)
  const PRODUCT_CALENDAR = PRODUCT_CALENDAR_TYPE(words)

  const columns: Array<Column<{ date: string; description: string; id: number; type: number }>> = [
    {
      Header: words['admin.productionCalendar.new.date'],
      id: 'date',
      accessor: d => moment(d.date, 'MM-DD-YYYY').format('DD.MM.YYYY')
    },
    {
      Header: words['admin.productionCalendar.new.name'],
      accessor: 'description'
    },
    {
      Header: words['admin.productionCalendar.new.type'],
      id: 'type',
      accessor: d => PRODUCT_CALENDAR[d.type]
    },
    {
      Header: () => (
        <div style={{ textAlign: 'center' }}>{words['user.dayOffTracker.table.action']}</div>
      ),
      id: 'actions',
      accessor: d => (
        <Options
          dotted={false}
          onRemove={() => deleteProductCalendar(d.id)}
          onEdit={() => {
            toggleUpdateModal()
            setCalendarId(d.id)
          }}
        />
      )
    }
  ]
  const columnsMemoized = useMemo(() => columns, [])
  const { setCurrentPage } = useLoadDateFortTable(getProductCalendar)

  const formattingForTable = [
    Array.from({
      length: dataTable.nonWorkingDays.length + dataTable.workingDays.length
    })
  ].reduce<any[]>(init => {
    dataTable.workingDays.forEach((item: any) => {
      init.push({
        type: 1,
        ...item
      })
    })

    dataTable.nonWorkingDays.forEach((item: any) => {
      init.push({
        type: 2,
        ...item
      })
    })

    return init
  }, [])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h4 className={styles.subtitle}>
            {words['admin.settings.dayOffTracker.celebrationList']}
          </h4>
          <Button className={styles.add} onClick={toggleCreateModal}>
            {words['admin.productionCalendar.buttons.add']}
          </Button>
        </div>
        <Table
          columns={columnsMemoized}
          data={formattingForTable}
          noData={words['noData']}
          total={dataTotal}
          getData={({ page }) => {
            setCurrentPage(page)
          }}
        />
      </div>
      <Modal isShow={openCreateModal} onClose={toggleCreateModal} className={styles.modal}>
        <SettingsTimeOfRequestForm save={setProductCalendar} />
      </Modal>
      <Modal isShow={openUpdateModal} onClose={toggleUpdateModal} className={styles.modal}>
        <SettingsTimeOfRequestUpdate calendarId={calendarId} />
      </Modal>
    </>
  )
}
