export const GET_PRODUCT_CALENDAR_REQUEST = 'GET_PRODUCT_CALENDAR_REQUEST'
export const GET_PRODUCT_CALENDAR_SUCCESS = 'GET_PRODUCT_CALENDAR_SUCCESS'
export const GET_PRODUCT_CALENDAR_ERROR = 'GET_PRODUCT_CALENDAR_ERROR'

export const SET_PRODUCT_CALENDAR_REQUEST = 'SET_PRODUCT_CALENDAR_REQUEST'
export const SET_PRODUCT_CALENDAR_SUCCESS = 'SET_PRODUCT_CALENDAR_SUCCESS'
export const SET_PRODUCT_CALENDAR_ERROR = 'SET_PRODUCT_CALENDAR_ERROR'

export const DELETE_PRODUCT_CALENDAR_REQUEST = 'DELETE_PRODUCT_CALENDAR_REQUEST'
export const DELETE_PRODUCT_CALENDAR_SUCCESS = 'DELETE_PRODUCT_CALENDAR_SUCCESS'
export const DELETE_PRODUCT_CALENDAR_ERROR = 'DELETE_PRODUCT_CALENDAR_ERROR'
