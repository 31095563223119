import axios from 'axios'
import { config } from 'globalConfigs'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { store, persistor } from '../store'
import { logOutSuccess, setToken } from '../screens/auth/Login/Login.actions'
import history from '../utils/history'
import { jwtDecode } from '../utils/jwt'
import { TUserDataWithoutTokens } from '../utils/user'

let retryRefreshCount = 2

export const API = {
  URL: `${config.apiUrl}`,
  ADMIN: '/admins',
  ADMIN_UPDATE: '/admin',
  CLIENT: '/users',
  CLIENT_UPDATE: '/user',
  CLIENT_RESTORE: '/users/restore',
  POSTS: '/posts',
  LOGOUT: '/logout',
  REFRESH: '/refresh-token',
  POLLING: '/balance',
  CALENDAR: '/calendar',
  BALANCE_UPDATE: '/balance-update',
  TIME_BALANCE: '/time-balance',
  CALENDAR_USER: '/calendar/user',
  MAIL: '/mail',
  RESTORE: '/restore-password',
  GOODS: '/goods',
  GOODS_RESTORE: '/goods/restore',
  GOODS_CATEGORIES: '/categories',
  GOODS_NAMES: '/goods/name',
  MANAGERS: '/managers',
  MANAGEMENT: '/calendar/management',
  MANAGEMENT_MAIL: '/calendar/management-mail',
  PRODUCT_CALENDAR: '/production-calendar',
  POSITIONS: '/position',
  TRANSACTION: '/transaction',
  TRANSACTION_USER: '/transaction/user',
  TRANSACTION_ADMIN: '/transaction/admin',
  POINTS_CONFIG: '/points-config',
  REQUEST_USER: '/request',
  REQUEST_ADMIN: '/request/management',
  ACHIEVEMENTS: '/initiative',
  ACHIEVEMENTS_RESTORE: '/initiative/restore',
  HISTORY: '/history',
  REVIEW: '/review',
  CATEGORIES: '/categories',
  FAQ: '/faq',
  LOCALIZATION: '/localization',
  RANK: '/rank',
  MARITIAL_STATUS: '/maritalstatus',
  ENGLISH_LEVEL: '/englishlevel',
  DEPARTMENT: '/department',
  ACADEMIC_DEGREE: '/academicdegree',
  TYPE_OF_EMPLOYMENT: '/typeofemployment',
  TEAM: '/team',
  TEAM_ADMIN: '/team-admin',
  TERMINATION_INITIATOR: '/terminationinitiator',
  USER_PHOTO: '/user-photo',
  USER_PROFILE_INFO_FOR_ADMIN: '/admin/user-profile',
  USER_PROFILE_INFO: '/user-profile',
  ALL_USERS: '/users-list',
  FOREIGN_PROFILE: '/foreign-profile',
  CHECK_USER_PROFILE: '/check-profile'
}

// Set config defaults when creating the instance
export const api = axios.create({
  baseURL: API.URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
})

const getToken = (key: 'accessToken' | 'refreshToken'): string | null =>
  store.getState().auth.data && store.getState().auth.data[key]
    ? store.getState().auth.data[key]
    : null

api.interceptors.request.use(
  request => {
    const accessToken = getToken('accessToken')

    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`
    }

    return request
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    const refreshToken = getToken('refreshToken')
    let tokenReloaded = false

    retryRefreshCount -= 1

    if (
      error.response &&
      error.response.status === 401 &&
      retryRefreshCount === 0 &&
      !originalRequest._retry
    ) {
      await logout()
      return Promise.reject(error)
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      !tokenReloaded &&
      refreshToken
    ) {
      store.dispatch({ type: 'REFRESH_TOKEN_REQUEST' })
      try {
        const res = await axios({
          method: 'get',
          url: `${API.URL}${API.REFRESH}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${refreshToken}`
          }
        })
        store.dispatch({ type: 'REFRESH_TOKEN_SUCCESS' })

        const data: any = res.data.payload
        api.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`
        originalRequest.headers.Authorization = `Bearer ${data.accessToken}`
        originalRequest._retry = true

        let newUser = null

        if (data.accessToken && data.refreshToken) {
          newUser = jwtDecode(data.accessToken) as TUserDataWithoutTokens | null
        }

        if (newUser) {
          tokenReloaded = true
          store.dispatch(
            setToken({
              ...newUser,
              accessToken: data.accessToken,
              refreshToken: data.refreshToken
            })
          )
        }
        retryRefreshCount = 2
        return axios(originalRequest)
      } catch (err) {
        if (tokenReloaded) {
          // eslint-disable-next-line no-console
          console.error('Refresh error', err.config)
          await logout()
        }
      }
    }
    return Promise.reject(error)
  }
)

export async function logout(): Promise<void> {
  store.dispatch(logOutSuccess({}))
  // redirect to sign in page
  history.push(window.location.origin)
}
