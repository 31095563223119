import { IAction } from 'models'

import {
  GET_TIME_OFF_REQUESTS_REQUEST,
  GET_TIME_OFF_REQUESTS_SUCCESS,
  GET_TIME_OFF_REQUESTS_ERROR,
  ACTION_TIME_OFF_REQUEST,
  ACTION_TIME_OFF_SUCCESS,
  ACTION_TIME_OFF_ERROR,
  GET_CURRENT_ROW_REQUEST_ERROR,
  GET_CURRENT_ROW_REQUEST_SUCCESS
} from './TimeOffRequests.constants'
import { AxiosError } from 'axios'
import { ACTIONS_FOR_CHANGE_STATUS_OF_REQUESTS_TIME_OFF } from '../../../globalConfigs'

export type TCurrentRequest = {
  id: number
  authorId: { id: number; fullName: string }
  reporterId: { id: number; fullName: string }
  assigneeId: { id: number; fullName: string }
  requestType: number
  fromDate: string
  toDate: string
  hours: number
  details: Array<{
    to: string
    day: string
    from: string
    hours: number
  }>
  status: number
  comment: string
  createdAt: string
}

export type TTimeOffRequestsData = {
  authorId: { id: number; fullName: string }
  comment: string
  creatorId: { id: number }
  details: Array<{
    day: string
    from: string
    hours: string
    to: string
  }>
  fromDate: string
  hours: number
  id: number
  reporterId: { id: number }
  requestType: number
  status: number
  timeZone: string
  toDate: string
}

export type TTimeOffRequestsInitialState = {
  data: TTimeOffRequestsData[]
  currentRequest: TCurrentRequest | {}
  limit: number
  error: AxiosError | null
  loading: boolean
  loadingReject: boolean
  loadingApprove: boolean
  minFromDate: string | number
  maxToDate: string | number
  total: number
}

export const initialState: TTimeOffRequestsInitialState = {
  data: [],
  currentRequest: {},
  error: null,
  loading: false,
  loadingReject: false,
  loadingApprove: false,
  limit: 0,
  minFromDate: new Date().valueOf(),
  maxToDate: new Date().valueOf(),
  total: 0
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_TIME_OFF_REQUESTS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case ACTION_TIME_OFF_REQUEST: {
      return {
        ...state,
        loadingReject: action.payload.type === 'reject',
        loadingApprove: action.payload.type === 'approve'
      }
    }

    case GET_TIME_OFF_REQUESTS_ERROR:
    case GET_CURRENT_ROW_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    }

    case ACTION_TIME_OFF_ERROR: {
      return {
        ...state,
        error: action.payload,
        loadingReject: false,
        loadingApprove: false
      }
    }

    case GET_TIME_OFF_REQUESTS_SUCCESS:
      return {
        ...state,
        data: action.payload.results || [],
        limit: 100,
        minFromDate: action.payload.minDate,
        maxToDate: action.payload.maxDate,
        loading: false,
        total: action.payload.total
      }

    case ACTION_TIME_OFF_SUCCESS: {
      if (action.payload.type === 'cancel') {
        return {
          ...state,
          data: state.data.map(element => {
            if (element.id === action.payload.payload.data.payload[0].id) {
              return {
                ...element,
                status: ACTIONS_FOR_CHANGE_STATUS_OF_REQUESTS_TIME_OFF[action.payload.type]
              }
            }
            return element
          })
        }
      }

      return {
        ...state,
        loadingReject: false,
        loadingApprove: false,
        data: state.data.map(element => {
          for (const ids of action.payload.payload.data.payload) {
            if (element.id === Number(ids.id) && ids.message === 'ok') {
              return {
                ...element,
                status: ACTIONS_FOR_CHANGE_STATUS_OF_REQUESTS_TIME_OFF[action.payload.type]
              }
            }
          }
          return element
        })
      }
    }
    case GET_CURRENT_ROW_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentRequest: action.payload // TODO --- types
      }
    }
    default:
      return state
  }
}
