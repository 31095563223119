import React, { FC, ReactNode, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styles from './style.module.scss'
import cls from 'classnames'
import { ReactComponent as IconBasketClose } from '../../assets/images/close.svg'

export type TModalProps = {
  // TODO --- For isShow crate union type example: type show = boolean | () => boolean
  isShow: any
  title?: string
  onClose: () => void
  onBeforeClose?: () => void
  onAfterClose?: () => void
  children?: ReactNode
  className?: string
  withoutCross?: boolean
}

const ModalComponent: FC<TModalProps> = ({
  title,
  isShow,
  onClose,
  children,
  onAfterClose,
  onBeforeClose,
  className = '',
  withoutCross = false
}) => {
  // tslint:disable: variable-name
  const refBtnClose = useRef(null)
  const _onClose = () => {
    Promise.resolve(onBeforeClose && onBeforeClose())
      .then(() => onClose())
      .then(() =>
        setTimeout(() => {
          onAfterClose && onAfterClose()
        }, 500)
      )
  }

  useEffect((): any => {
    if (isShow) {
      document.body.style.overflow = 'hidden'

      if (refBtnClose) {
        // @ts-ignore
        refBtnClose.current.focus()
      }
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isShow])

  const classes = cls({
    [styles.header]: !withoutCross,
    [styles.hidden]: withoutCross
  })

  return isShow ? (
    <>
      <div onClick={_onClose} className={styles.overlay} />

      <div
        className={cls({
          [styles.container]: true,
          [className]: !!className
        })}
      >
        <header className={classes}>
          <h2 className={styles.title}>{title}</h2>
          <button
            onKeyDown={e => (e.keyCode === 27 || e.which === 27) && _onClose()}
            ref={refBtnClose}
            className={styles.close}
            onClick={_onClose}
          >
            <IconBasketClose />
          </button>
        </header>

        <div className={styles.content}>{children && children}</div>
      </div>
    </>
  ) : null
}

const DOMModal = document.querySelector('#modal')

const Modal: FC<TModalProps> = props => {
  if (DOMModal) {
    return ReactDOM.createPortal(ModalComponent(props), DOMModal)
  }

  return null
}

export default Modal
