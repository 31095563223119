import { IAction } from 'models'
import { ActionCreator } from 'redux'
import {
  SET_TIME_OFF_PERIOD_REQUEST,
  SET_TIME_OFF_PERIOD_SUCCESS,
  SET_TIME_OFF_PERIOD_ERROR,
  GET_MANAGER_ERROR,
  GET_MANAGER_REQUEST,
  GET_MANAGER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  GET_CURRENT_REQUEST_REQUEST,
  GET_CURRENT_REQUEST_SUCCESS,
  GET_CURRENT_REQUEST_ERROR,
  GET_BALANCE_REQUEST,
  GET_BALANCE_SUCCESS,
  GET_BALANCE_ERROR,
  GET_PRODUCT_CALENDAR_DAYS_ERROR,
  GET_PRODUCT_CALENDAR_DAYS_REQUEST,
  GET_PRODUCT_CALENDAR_DAYS_SUCCESS,
  UPDATE_CURRENT_TIME_BALANCE_REQUEST,
  UPDATE_CURRENT_TIME_BALANCE_SUCCESS,
  UPDATE_CURRENT_TIME_BALANCE_ERROR
} from './NewRequestTimeOff.constants'
import { action } from 'store/store.utils'
import { AxiosError } from 'axios'
import { TManagersData } from './NewRequestTimeOff.reducer'
import { TBalance } from '../../admin/Users/components/UsersModalTimeEditForm/UsersModalTimeEditForm.model'

export const requestSetTimeOffPeriod: ActionCreator<IAction> = payload => ({
  type: SET_TIME_OFF_PERIOD_REQUEST,
  payload
})

export const successSetTimeOffPeriod: ActionCreator<IAction> = payload => ({
  type: SET_TIME_OFF_PERIOD_SUCCESS,
  payload
})

export const errorSetTimeOffPeriod: ActionCreator<IAction> = payload => ({
  type: SET_TIME_OFF_PERIOD_ERROR,
  payload
})

export const requestGetManagers = () => action(GET_MANAGER_REQUEST)

export const successGetManagers = ({
  total,
  results
}: {
  total: number
  results: TManagersData[]
}) => action(GET_MANAGER_SUCCESS, { total, results })
export type TSuccessGetManagers = ReturnType<typeof successGetManagers>

export const errorGetManagers = (error: AxiosError) => action(GET_MANAGER_ERROR, error)
export type TErrorGetManagers = ReturnType<typeof errorGetManagers>

export const requestGetUsers = () => action(GET_USER_REQUEST)

export const successGetUsers = ({ total, results }: { total: number; results: TManagersData[] }) =>
  action(GET_USER_SUCCESS, { total, results })
export type TSuccessGetUsers = ReturnType<typeof successGetUsers>

export const errorGetUsers = (error: AxiosError) => action(GET_USER_ERROR, error)
export type TErrorGetUsers = ReturnType<typeof errorGetUsers>

export const requestGetCurrentRequest = () => action(GET_CURRENT_REQUEST_REQUEST)

export const successGetCurrentRequest = (payload: any) =>
  action(GET_CURRENT_REQUEST_SUCCESS, payload)
export type TSuccessGetCurrentRequest = ReturnType<typeof successGetCurrentRequest>

export const errorGetCurrentRequest = (error: AxiosError) =>
  action(GET_CURRENT_REQUEST_ERROR, error)
export type TErrorGetCurrentRequest = ReturnType<typeof errorGetCurrentRequest>

export const requestGetBalance = () => action(GET_BALANCE_REQUEST)

export const successGetBalance = (payload: any) => action(GET_BALANCE_SUCCESS, payload)
export type TSuccessGetBalance = ReturnType<typeof successGetBalance>

export const errorGetBalance = (error: AxiosError) => action(GET_BALANCE_ERROR, error)
export type TErrorGetBalance = ReturnType<typeof errorGetBalance>

export const getProductCalendarDaysRequest = () => action(GET_PRODUCT_CALENDAR_DAYS_REQUEST)
export const getProductCalendarDaysSuccess = (payload: []) =>
  action(GET_PRODUCT_CALENDAR_DAYS_SUCCESS, payload)
export const getProductCalendarDaysError = (payload: AxiosError) =>
  action(GET_PRODUCT_CALENDAR_DAYS_ERROR, payload)

export const updateCurrentTimeBalanceRequest = () => action(UPDATE_CURRENT_TIME_BALANCE_REQUEST)
export const updateCurrentTimeBalanceSuccess = (payload: TBalance) =>
  action(UPDATE_CURRENT_TIME_BALANCE_SUCCESS, payload)
export const updateCurrentTimeBalanceError = (payload: AxiosError) =>
  action(UPDATE_CURRENT_TIME_BALANCE_ERROR, payload)
