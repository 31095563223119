import React, { FC, ReactNode, useEffect, useRef } from 'react'

type TClickAwayWrapper = {
  onOutsideClick: () => void
  children: ReactNode
}

export const ClickAwayWrapper: FC<TClickAwayWrapper> = ({ onOutsideClick, children }) => {
  const commentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (commentRef.current && !commentRef.current.contains(event.target)) {
        onOutsideClick()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return <div ref={commentRef}>{children}</div>
}
