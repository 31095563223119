import {
  GET_LOCALIZATION_REQUEST,
  GET_LOCALIZATION_SUCCESS,
  GET_LOCALIZATION_ERROR
} from './language.constants'
import { IAction } from 'models'
import { ActionCreator } from 'redux'

export const getLocalizationRequest: ActionCreator<IAction> = payload => ({
  type: GET_LOCALIZATION_REQUEST,
  payload
})

export const getLocalizationSuccess: ActionCreator<IAction> = (payload, language) => ({
  type: GET_LOCALIZATION_SUCCESS,
  payload,
  language
})

export const getLocalizationError: ActionCreator<IAction> = payload => ({
  type: GET_LOCALIZATION_ERROR,
  payload
})
