export const GET_INFO_REQUEST: string = 'GET_INFO_REQUEST'
export const GET_INFO_SUCCESS: string = 'GET_INFO_SUCCESS'
export const GET_INFO_ERROR: string = 'GET_INFO_ERROR'

export const DELETE_INFO_REQUEST: string = 'DELETE_INFO_REQUEST'
export const DELETE_INFO_SUCCESS: string = 'DELETE_INFO_SUCCESS'
export const DELETE_INFO_ERROR: string = 'DELETE_INFO_ERROR'

export const CREATE_INFO_REQUEST: string = 'CREATE_INFO_REQUEST'
export const CREATE_INFO_SUCCESS: string = 'CREATE_INFO_SUCCESS'
export const CREATE_INFO_ERROR: string = 'CREATE_INFO_ERROR'

export const UPDATE_INFO_REQUEST: string = 'UPDATE_INFO_REQUEST'
export const UPDATE_INFO_SUCCESS: string = 'UPDATE_INFO_SUCCESS'
export const UPDATE_INFO_ERROR: string = 'UPDATE_INFO_ERROR'
