import { IToggleBarData } from 'components/ToggleBar'
import { TWords } from 'elements/SideBar/SideBar.config'
import { EProfileHistory, ETransactionHistory, getToggleBarOptions } from 'globalConfigs'

export const getPSType = (value: string): string => {
  switch (value) {
    case ETransactionHistory.GOODS:
      return ETransactionHistory.GOODS
    case ETransactionHistory.ACHIEVEMENTS:
      return ETransactionHistory.ACHIEVEMENTS
    case ETransactionHistory.TRANSACTIONS:
      return ETransactionHistory.TRANSACTIONS
    default:
      return ETransactionHistory.GOODS
  }
}

export const getProfileType = (value: string): string => {
  switch (value) {
    case EProfileHistory.MAIN_INFO:
      return EProfileHistory.MAIN_INFO
    case EProfileHistory.CAREER:
      return EProfileHistory.CAREER
    case EProfileHistory.TIME_OFF_BALANCE:
      return EProfileHistory.TIME_OFF_BALANCE
    case EProfileHistory.POINTS_SYSTEM:
      return EProfileHistory.POINTS_SYSTEM
    default:
      return EProfileHistory.MAIN_INFO
  }
}

export const setDefaultMainToggleBarState = (
  profileType: any,
  words: TWords,
  isEdit: boolean,
  isAdmin: boolean
): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptions(words, isEdit, isAdmin)

  switch (profileType) {
    case EProfileHistory.MAIN_INFO:
      return toggleBarOptions && toggleBarOptions[0]
    case EProfileHistory.CAREER:
      return toggleBarOptions && toggleBarOptions[1]
    case EProfileHistory.TIME_OFF_BALANCE:
      return toggleBarOptions && toggleBarOptions[2]
    case EProfileHistory.POINTS_SYSTEM:
      return toggleBarOptions && toggleBarOptions[3]
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}
