import { connect } from 'react-redux'
import { getDataSelector, getLoadingSelector } from './Login.reducer'
import { Login as LoginComponent } from './Login'
import { TState } from 'store'

const mapStateToProps = (state: TState) => ({
  data: getDataSelector(state),
  loading: getLoadingSelector(state)
})

export const Login = connect(mapStateToProps)(LoginComponent)
