import React, { FC, useState, useMemo, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { Table } from 'components/UiKit/Table'
import { TUsersProps, TUsersToFilter } from './Users.model'
import { Filter } from 'components/UiKit/Filter'
import styles from './Users.module.scss'
import { getColumns } from './Users.columns'
import { getFilterUsers } from './Users.filter.config'
import { useLoadDateFortTable } from 'hooks/useLoadDateFortTable'
import { UsersModalEditForm } from './components/UsersModalEditForm'
import { EPageLimits } from '../../../globalConfigs'
import { TSelectOption } from '../../../components/Select'
import { UsersModalTimeEditForm } from './components/UsersModalTimeEditForm'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
import history from 'utils/history'
import queryString from 'query-string'
import { UsersModalPointsEditForm } from './components/UsersModalPoinsEditForm'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { parseSelectedOptions } from 'utils/parseSelectedOptions'
import { TManagersData } from 'screens/client/NewRequestTimeOff/NewRequestTimeOff.reducer'

export const Users: FC<TUsersProps> = ({
  getManagers,
  updateUser,
  users,
  data = [],
  getUsers,
  total,
  dataManagers,
  dataPositions,
  getPosition,
  clearUsersData,
  balance,
  getBalance,
  updateCurrentTimeBalance,
  deleteUser,
  restoreUser,
  updatePointsBalance,
  getUsersToFilter,
  getSelectOptions
}) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const positions = useSelector((state: TState) => state.client.editProfile.positions)
  const rank = useSelector((state: TState) => state.client.editProfile.rank)
  const maritalStatus = useSelector((state: TState) => state.client.editProfile.maritalStatus)
  const englishLevel = useSelector((state: TState) => state.client.editProfile.englishLevel)
  const academicDegree = useSelector((state: TState) => state.client.editProfile.academicDegree)
  const department = useSelector((state: TState) => state.client.editProfile.department)
  const typeOfEmployment = useSelector((state: TState) => state.client.editProfile.typeOfEmployment)

  const parsed = queryString.parse(history.location.search)
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
  const [openPointsEditModal, setOpenPointsEditModal] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openTimeEditModal, setOpenTimeEditModal] = useState<boolean>(false)
  const [parsedData, setParsedData] = useState<TManagersData[]>([])

  useEffect(() => {
    getSelectOptions()
  }, [])

  useEffect(() => {
    setParsedData(
      data.map(item =>
        parseSelectedOptions(item, true, {
          positions,
          rank,
          maritalStatus,
          englishLevel,
          academicDegree,
          department,
          typeOfEmployment
        })
      )
    )
  }, [data, positions])
  //   setParsedData(data.map(item => parseSelectedOptions(item, words, true)))
  // }, [data])

  const togglePointsEditModal = () => setOpenPointsEditModal(!openPointsEditModal)
  const toggleEditModal = () => setOpenEditModal(!openEditModal)
  const toggleTimeEditModal = () => setOpenTimeEditModal(!openTimeEditModal)

  useEffect(() => {
    getManagers({ limit: EPageLimits.MANAGERS })

    return () => {
      clearUsersData()
    }
  }, [])
  useEffect(() => {
    getUsersToFilter()
  }, [])

  useEffect(() => {
    if (selectedUserId) {
      getBalance(selectedUserId.toString())
    }
  }, [selectedUserId])

  useEffect(() => {
    getPosition()
  }, [])

  const toggleBarOptions = [
    {
      value: 'active',
      title: words['admin.users.toggle.active']
    },
    {
      value: 'deleted',
      title: words['admin.users.toggle.inactive']
    }
  ]

  const fullName: TSelectOption[] = useMemo(
    () =>
      users.map((item: TUsersToFilter) => ({
        value: `${item.fullName}`,
        label: item.fullName
      })),
    [users]
  )
  const usersEmail: TSelectOption[] = useMemo(
    () =>
      users.map((item: TUsersToFilter) => {
        return {
          value: `${item.email}`,
          label: item.email
        }
      }),
    [data]
  )

  const columns = history.location.search.match(/deleted=true/)
    ? getColumns(words, {
        onRestore: id => restoreUser(id)
      })
    : getColumns(words, {
        onPointsEdit: id => {
          setSelectedUserId(id)
          togglePointsEditModal()
        },
        onEdit: id => {
          setSelectedUserId(id)
          toggleEditModal()
        },
        onTimeEdit: id => {
          setSelectedUserId(id)
          toggleTimeEditModal()
        },
        onRemove: id => deleteUser(id)
      })

  const columnsMemoized = useMemo(() => columns, [dataPositions])
  const { setCurrentPage } = useLoadDateFortTable(getUsers)
  const [selectedType, setSelectedType] = useState<IToggleBarData>(
    parsed.deleted === 'true' ? toggleBarOptions[1] : toggleBarOptions[0]
  )
  if (!parsed.deleted) {
    return <Redirect to={{ search: 'deleted=false', pathname: history.location.pathname }} />
  }
  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>{words['sidebar.users']}</h1>
        </section>
        <section className={styles.block_transaction}>
          <div className={styles.toggle}>
            <ToggleBar
              name="active-users-no-active"
              data={toggleBarOptions}
              defaultChecked={selectedType}
              onChange={event => {
                if (event.value === 'deleted') {
                  history.push(
                    `${history.location.pathname}?${queryString.stringify({
                      deleted: 'true'
                    })}`
                  )
                }
                if (event.value !== 'deleted') {
                  history.push(
                    `${history.location.pathname}?${queryString.stringify({
                      deleted: 'false'
                    })}`
                  )
                }
                setSelectedType(event)
              }}
            />
          </div>
          <div className={styles.content}>
            <Filter
              config={getFilterUsers(words, {
                fullName,
                usersEmail
              })}
              defaultOpened={true}
              withButton={true}
            />
            <Table
              total={total}
              data={parsedData}
              noData={words['noData']}
              getData={({ page }) => {
                setCurrentPage(page)
              }}
              columns={columnsMemoized}
            />
          </div>
        </section>
      </div>
      <UsersModalEditForm
        dataManagers={dataManagers}
        dataPositions={dataPositions}
        openEditModal={openEditModal}
        toggleEditModal={toggleEditModal}
        updateUser={updateUser}
        id={selectedUserId}
        user={data.find(predicate => predicate.id === selectedUserId)}
      />
      <UsersModalTimeEditForm
        open={openTimeEditModal}
        toggleTimeEditModal={toggleTimeEditModal}
        id={selectedUserId}
        balance={balance}
        updateCurrentTimeBalance={updateCurrentTimeBalance}
        user={data.find(predicate => predicate.id === selectedUserId)}
      />
      <UsersModalPointsEditForm
        open={openPointsEditModal}
        togglePointsEdit={togglePointsEditModal}
        id={selectedUserId}
        user={data.find(predicate => predicate.id === selectedUserId)}
        updatePointsBalance={updatePointsBalance}
      />
    </>
  )
}
