import { Dispatch } from 'redux'
import {
  getInformationRequest,
  getInformationSuccess,
  getInformationError,
  deleteInformationRequest,
  deleteInformationSuccess,
  deleteInformationError,
  createInformationRequest,
  createInformationSuccess,
  createInformationError,
  updateInformationRequest,
  updateInformationSuccess,
  updateInformationError
} from './Information.actions'
import { API, api } from 'services/api'
import toastr from 'utils/toastr'
import { TState } from 'store'

export const fetchInformation: any = (type: number) => async (dispatch: Dispatch) => {
  dispatch(getInformationRequest())

  try {
    const response: any = await api.get(`${API.FAQ}?type=${type}`)
    dispatch(getInformationSuccess(response.data.result))
  } catch (error) {
    await dispatch(getInformationError(error))
  }
}

export const deleteInformation: any = (id: number | string) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(deleteInformationRequest())
  const words = getData().global.language.words

  try {
    await api.delete(`${API.FAQ}/${id}`)
    toastr('success', words['SUCCESS_DELETED'])
    dispatch(deleteInformationSuccess(id))
  } catch (error) {
    await dispatch(deleteInformationError(error))
    toastr('error', words['FILED_PROCESS_REQUEST'])
  }
}

export const createInformation = (data: object) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(createInformationRequest())
  const words = getData().global.language.words

  try {
    const response: any = await api.post(API.FAQ, data)
    toastr('success', words['SUCCESS_CREATED'])
    dispatch(createInformationSuccess(response))
  } catch (error) {
    await dispatch(createInformationError(error))
    toastr('error', words['FILED_PROCESS_REQUEST'])
  }
}

export const updateInformation: any = (id: string | number, data: object) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(updateInformationRequest())
  const words = getData().global.language.words

  try {
    await api.put(`${API.FAQ}/${id}`, data)
    toastr('success', words['SUCCESS_UPDATED'])
    dispatch(updateInformationSuccess({ data, id }))
  } catch (error) {
    await dispatch(updateInformationError(error))
    toastr('error', words['FILED_PROCESS_REQUEST'])
  }
}
