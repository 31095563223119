import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { withRouter } from 'react-router'
import { TState } from 'store'
import { HistoryTransactionComponent } from './HistoryTransaction'
import { getHistory, getTransactions } from '../../Profile.thunx'
import { clearHistoryData, clearTransactionsData } from '../../Profile.actions'

export const mapStateToProps = (state: TState) => ({
  historyTransaction: state.client.profile.history.results,
  transactions: state.client.profile.history.transactions,
  payers: state.client.profile.history.payers,
  receivers: state.client.profile.history.receivers,
  total: state.client.profile.history.total,
  minPrice: state.client.profile.history.minPrice,
  maxPrice: state.client.profile.history.maxPrice,
  minDate: state.client.profile.history.minDate,
  maxDate: state.client.profile.history.maxDate
})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      getHistory,
      getTransactions,
      clearHistoryData,
      clearTransactionsData
    },
    dispatch
  )
})

export const HistoryTransaction = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HistoryTransactionComponent))
