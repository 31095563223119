import { TFieldConfigRow } from 'components/UiKit/FieldFactory/FieldFactory.model'
import { config } from 'globalConfigs'
import { IDeactivateUserFieldsConfigProps } from './DeactivateUserModal.model'
import styles from './DeactivateUserModal.module.scss'

const deactivateUserFieldsConfig = (props: IDeactivateUserFieldsConfigProps): TFieldConfigRow[] => {
  const { words, terminationInitiatorOptions } = props

  const row1: TFieldConfigRow = {
    style: {
      marginBottom: '20px'
    },
    items: [
      {
        name: 'terminationDate',
        label: words['admin.team.terminationDate'],
        required: true,
        inputWrapperClassName: styles['terminating-date'],
        component: ({ input }: any) => {
          return {
            type: 'datePicker',
            props: {
              autoComplete: 'off',
              dateFormat: config.dateFormat,
              selected: input.value ? new Date(input.value) : null
            }
          }
        }
      },
      {
        name: 'terminationInitiator',
        label: words['admin.team.terminationInitiator'],
        required: true,
        inputWrapperClassName: styles['termination-initiator'],
        component: () => {
          return {
            type: 'select',
            props: {
              placeholder: '',
              options: terminationInitiatorOptions || []
            }
          }
        }
      }
    ]
  }

  const row2: TFieldConfigRow = {
    style: {
      marginBottom: '32px'
    },
    items: [
      {
        name: 'deactivationComment',
        label: words['user.profile.table.comment'],
        required: true,
        inputWrapperClassName: styles['comment'],
        component: () => {
          return {
            type: 'textarea',
            props: {}
          }
        }
      }
    ]
  }

  return [row1, row2]
}

export default deactivateUserFieldsConfig
