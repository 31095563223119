import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'

import styles from './RestoreUserModal.module.scss'
import Modal from 'components/Modal'
import { Button } from 'components/UiKit/Button'
import { setOpenRestoreUserModal } from 'screens/admin/Team/TeamAdmin.actions'
import { restoreUser } from 'screens/admin/Team/TeamAdmin.thunx'

interface RestoreUserModalProps {
  userId: number
}

export const RestoreUserModal: FC<RestoreUserModalProps> = ({ userId }) => {
  const dispatch = useDispatch()
  const words = useSelector((state: TState) => state.global.language.words)
  const openRestoreUserModal = useSelector(
    (state: TState) => state.admin.teamAdmin.restoreUser.openRestoreUserModal
  )
  const loading = useSelector((state: TState) => state.admin.teamAdmin.restoreUser.loading)

  const closeModal = () => {
    dispatch(setOpenRestoreUserModal())
  }
  const restoreUserHandler = () => {
    dispatch(restoreUser(userId))
  }
  return (
    <Modal isShow={openRestoreUserModal} onClose={closeModal} className={styles.modal}>
      <h3>{words['admin.team.doYouWantToRestore']}</h3>
      <p>{words['admin.team.userWillBeRestored']}</p>
      <div className={styles.row}>
        <Button
          outline={true}
          type="button"
          className={styles['cancel-btn']}
          onClick={closeModal}
          disabled={loading}
        >
          {words['user.profile.savePopup.cancel']}
        </Button>
        <Button
          type="button"
          className={styles['save-btn']}
          onClick={restoreUserHandler}
          disabled={loading}
        >
          {words['admin.users.table.buttons.restore']}
        </Button>
      </div>
    </Modal>
  )
}
