import moment from 'moment'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import styles from './CareerInfo.module.scss'

export const CareerInfo: FC<any> = ({
  department,
  typeOfEmployment,
  firstWorkingDay,
  englishLevel,
  salary,
  workingHoursPerDay,
  probationEndDate,
  previousJob,
  technologies,
  careerGoal
}) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const [careerInfoHeight, setCareerInfoHeight] = useState(0)
  const careerInfo: any = useRef(null)
  const careerInfoEl: any = careerInfo.current

  useEffect(() => {
    if (careerInfoEl !== null) setCareerInfoHeight(careerInfoEl.clientHeight)
  }, [careerInfoEl && careerInfoEl.clientHeight])

  return (
    <div className={styles['career-wrapper']}>
      <div>
        <div ref={careerInfo} className={`${styles['info-section']} ${styles['career-info']}`}>
          <h2>{words['user.profile.career.info']}</h2>
          <div className={styles['career-container']}>
            <div>
              {department && department.name && (
                <p>
                  <span>{words['user.profile.career.department']}: </span>
                  {words[department.name]}
                </p>
              )}
              {typeOfEmployment && typeOfEmployment.name && (
                <p>
                  <span>{words['user.profile.career.occupation']}: </span>
                  {words[typeOfEmployment.name]}
                </p>
              )}
              {firstWorkingDay && (
                <p>
                  <span>{words['user.profile.career.hiringDate']}: </span>
                  {moment(firstWorkingDay).format('DD.MM.YYYY')}
                </p>
              )}
              {englishLevel && englishLevel.name && (
                <p>
                  <span>{words['user.profile.career.englishLevel']}: </span>
                  {words[englishLevel.name]}
                </p>
              )}
            </div>

            <div>
              {salary && (
                <p>
                  <span>{words['user.profile.career.salary']}: </span>
                  {salary}
                </p>
              )}
              {workingHoursPerDay && (
                <p>
                  <span>{words['user.profile.career.workingHours']}: </span>
                  {workingHoursPerDay}
                </p>
              )}
              {probationEndDate && (
                <p>
                  <span>{words['user.profile.career.probationEndDate']}: </span>
                  {moment(probationEndDate).format('DD.MM.YYYY')}
                </p>
              )}
              {previousJob && (
                <p>
                  <span>{words['user.profile.career.previousJob']}: </span>
                  {previousJob}
                </p>
              )}
            </div>
          </div>
          {technologies && (
            <div>
              <p className={styles['single-field']}>
                <span>{words['user.profile.career.technologies']}: </span>
                {technologies}
              </p>
            </div>
          )}
          {careerGoal && (
            <div>
              <p className={styles['single-field']}>
                <span>{words['user.profile.career.goals']}: </span>
                {careerGoal}
              </p>
            </div>
          )}
        </div>

        <div
          style={{ maxHeight: careerInfoHeight }}
          className={`${styles['info-section']} ${styles['projects-wrapper']}`}
        >
          {/* TODO: add projects view */}
          <h2>{words['user.profile.career.projects']}</h2>
          <div className={styles.projects}>
            <div className={styles['in-progress']}>Раздел &ldquo;Проекты&rdquo; в разработке</div>
          </div>
        </div>
      </div>

      {/* <div className={`${styles['info-section']} ${styles['career-achivements']}`}>
                TODO: add career achinements graph, delete img
                <h2>{words['user.profile.career.achivements']}</h2>
              </div> */}
      {/* <img
                src={require('../../../assets/images/career_achievement_mock_graph.png')}
                alt="career achivements graph"
                className={styles['career-achivements-mock-graph']}
              /> */}
    </div>
  )
}
