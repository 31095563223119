import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import Modal from 'components/Modal'
import styles from './EditProfileModalAccessChanges.module.scss'
import { Button } from 'components/UiKit/Button'
import { TEditProfileModalAccessChanges } from './EditProfileModalAcceptChanges.model'

export const EditProfileModalAcceptChanges: FC<TEditProfileModalAccessChanges> = ({
  open,
  toggleEditProfileAccessChangesModal,
  dontSave,
  save
}) => {
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <Modal isShow={open} onClose={toggleEditProfileAccessChangesModal} className={styles.modal}>
      <header>{words['user.profile.savePopup.question']}</header>
      <p>{words['user.profile.savePopup.description']}</p>
      <div className={styles['row']}>
        <Button
          outline={true}
          onClick={() => {
            dontSave()
          }}
        >
          <span>{words['user.profile.savePopup.dontSave']}</span>
        </Button>

        <Button
          onClick={() => {
            save()
            toggleEditProfileAccessChangesModal()
          }}
        >
          <span>{words['user.profile.savePopup.saveBtn']}</span>
        </Button>
      </div>
    </Modal>
  )
}
