export const GET_ACHIEVEMENTS_REQUEST: string = 'GET_ACHIEVEMENTS_REQUEST'
export const GET_ACHIEVEMENTS_SUCCESS: string = 'GET_ACHIEVEMENTS_SUCCESS'
export const GET_ACHIEVEMENTS_ERROR: string = 'GET_ACHIEVEMENTS_ERROR'
export const CLEAR_ACHIEVEMENTS_DATA: string = 'CLEAR_ACHIEVEMENTS_DATA'

export const CREATE_REQUEST_ACHIEVEMENTS_SUCCESS: string = 'CREATE_REQUEST_ACHIEVEMENTS_SUCCESS'
export const CREATE_REQUEST_ACHIEVEMENTS_ERROR: string = 'CREATE_REQUEST_ACHIEVEMENTS_ERROR'

export const DELETE_ACHIEVEMENT_SUCCESS: string = 'DELETE_ACHIEVEMENT_SUCCESS'
export const DELETE_ACHIEVEMENT_ERROR: string = 'DELETE_ACHIEVEMENT_ERROR'

export const RESTORE_ACHIEVEMENT_SUCCESS: string = 'RESTORE_ACHIEVEMENT_SUCCESS'
export const RESTORE_ACHIEVEMENT_ERROR: string = 'RESTORE_ACHIEVEMENT_ERROR'

export const CREATE_ACHIEVEMENT_REQUEST: string = 'CREATE_ACHIEVEMENT_REQUEST'
export const CREATE_ACHIEVEMENT_SUCCESS: string = 'CREATE_ACHIEVEMENT_SUCCESS'
export const CREATE_ACHIEVEMENT_ERROR: string = 'CREATE_ACHIEVEMENT_ERROR'

export const EDIT_ACHIEVEMENT_REQUEST: string = 'EDIT_ACHIEVEMENT_REQUEST'
export const EDIT_ACHIEVEMENT_SUCCESS: string = 'EDIT_ACHIEVEMENT_SUCCESS'
export const EDIT_ACHIEVEMENT_ERROR: string = 'EDIT_ACHIEVEMENT_ERROR'

export const GET_CURRENT_ACHIEVEMENT_REQUEST: string = 'GET_CURRENT_ACHIEVEMENT_REQUEST'
export const GET_CURRENT_ACHIEVEMENT_SUCCESS: string = 'GET_CURRENT_ACHIEVEMENT_SUCCESS'
export const GET_CURRENT_ACHIEVEMENT_ERROR: string = 'GET_CURRENT_ACHIEVEMENT_ERROR'
