import {
  GET_ACHIEVEMENTS_REQUEST,
  GET_ACHIEVEMENTS_SUCCESS,
  GET_ACHIEVEMENTS_ERROR,
  DELETE_ACHIEVEMENT_SUCCESS,
  DELETE_ACHIEVEMENT_ERROR,
  RESTORE_ACHIEVEMENT_SUCCESS,
  RESTORE_ACHIEVEMENT_ERROR,
  CREATE_ACHIEVEMENT_REQUEST,
  CREATE_ACHIEVEMENT_SUCCESS,
  CREATE_ACHIEVEMENT_ERROR,
  // EDIT_ACHIEVEMENT_REQUEST,
  // CREATE_REQUEST_ACHIEVEMENTS_SUCCESS,
  // CREATE_REQUEST_ACHIEVEMENTS_ERROR,
  GET_CURRENT_ACHIEVEMENT_REQUEST,
  GET_CURRENT_ACHIEVEMENT_SUCCESS,
  GET_CURRENT_ACHIEVEMENT_ERROR,
  CLEAR_ACHIEVEMENTS_DATA
} from './Achievements.constants'
import { IInitialState } from './Achievements.model'
import { IAction } from 'models'

export const initialState: IInitialState = {
  data: [],
  dataAchievements: [],
  currentAchievement: {},
  total: 0,
  minPrice: 0,
  maxPrice: 0,
  error: '',
  loading: false
}

export const getAchievementsSelector = (state: any) => state.achievements.data
export const getAchievementsErrorSelector = (state: any) => state.achievements.error
export const getAchievementsLoadingSelector = (state: any) => state.achievements.loading

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_CURRENT_ACHIEVEMENT_REQUEST:
    case CREATE_ACHIEVEMENT_REQUEST:
    case GET_ACHIEVEMENTS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_ACHIEVEMENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.payload.results,
        dataAchievements: [...state.dataAchievements, ...action.payload.payload.results],
        total: action.payload.payload.total,
        minPrice: action.payload.payload.minPrice,
        maxPrice: action.payload.payload.maxPrice,
        loading: false
      }
    }
    case DELETE_ACHIEVEMENT_SUCCESS: {
      const { id } = action.payload
      return {
        ...state,
        dataAchievements: state.dataAchievements.filter(item => item.id !== id)
      }
    }
    case RESTORE_ACHIEVEMENT_SUCCESS: {
      const { id } = action.payload
      return {
        ...state,
        dataAchievements: state.dataAchievements.filter(item => item.id !== id)
      }
    }
    case GET_CURRENT_ACHIEVEMENT_SUCCESS: {
      const { result } = action.payload
      return {
        ...state,
        currentAchievement: {
          ...result,
          photo: result.photo[0]
        }
      }
    }

    case GET_ACHIEVEMENTS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    case CREATE_ACHIEVEMENT_SUCCESS: {
      const { id } = action.payload
      return {
        ...state,
        data: state.data.filter(item => item.id !== id)
      }
    }

    case CLEAR_ACHIEVEMENTS_DATA: {
      return {
        ...initialState
      }
    }

    case GET_CURRENT_ACHIEVEMENT_ERROR:
    case CREATE_ACHIEVEMENT_ERROR:
    case DELETE_ACHIEVEMENT_ERROR:
    case RESTORE_ACHIEVEMENT_ERROR:
      return {
        ...state
      }

    default:
      return state
  }
}
