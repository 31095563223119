import React, { FC } from 'react'
import styles from './TimeOffBalance.module.scss'
import { currentBalance, TBalance } from '../../../utils/balance'
import { RadialChart } from 'components/RadialChart'
import cls from 'classnames'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TSettingsTimeOffBalanceProps = {
  balance: TBalance
}

export const TimeOffBalance: FC<TSettingsTimeOffBalanceProps> = ({ balance }) => {
  const progressDayoff = (balance.dayOffBalance / balance.dayOffLimit) * 100
  const progressRemote = (balance.totalRemote / balance.remoteLimit) * 100
  const progressSick = (balance.totalSick / balance.sickLimit) * 100
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2>{words['user.header.balance']}</h2>
        <div className={styles['content-wrapper']}>
          <div className={styles.row_withAnotherPadding}>
            <RadialChart color="#149B58" progress={progressDayoff}>
              Day-off
            </RadialChart>
            <div className={styles.info}>
              <div className={styles.title}>
                <div>{words['user.profile.yearBalance']}</div>
              </div>
              <div className={styles.balance}>
                <div>
                  <span
                    className={cls({
                      [styles['balance-red']]: balance.dayOffBalance < 0
                    })}
                  >
                    {currentBalance(2, balance).days}
                  </span>
                  /{currentBalance(2, balance).limitDays} {words['user.dayOffTracker.shortDays']}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <RadialChart color="#3737ED" progress={progressRemote}>
              Remote
            </RadialChart>
            <div className={styles.info}>
              <div className={styles.title}>
                <div>{words['user.profile.monthBalance']}</div>
              </div>
              <div className={styles.balance}>
                <div>
                  {Math.floor(balance.totalRemote)}
                  {` ${words['user.dayOffTracker.shortHours']}`}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.diagram}>
              <RadialChart color="#D12A6C" progress={progressSick}>
                Sick
              </RadialChart>
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                <div>{words['user.profile.yearBalance']}</div>
              </div>
              <div className={styles.balance}>
                <div>
                  {currentBalance(5, balance).days} {words['user.dayOffTracker.shortDays']}
                  {currentBalance(5, balance).hours !== 0 &&
                    `${currentBalance(5, balance).hours} ${words['user.dayOffTracker.shortHours']}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
