import React, { FC } from 'react'
import Modal from 'components/Modal'
import { TThankForPurchaseModal } from './ThankForPurchaseModal.model'
import styles from './ThankForPurchaseModal.module.scss'
import { ReactComponent as ThankForPurchase } from 'assets/images/thank-for-purchase.svg'
import { Button } from '../../../../../components/UiKit/Button'
import history from '../../../../../utils/history'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export const ThankForPurchaseModal: FC<TThankForPurchaseModal> = ({
  open,
  toggleThankForPurchase,
  emptyTrash
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const handleClick = (event: any) => {
    event.preventDefault()
    emptyTrash('all')
    toggleThankForPurchase()
    history.push('/dashboard/point-system/store')
  }
  return (
    <Modal
      isShow={open}
      onClose={toggleThankForPurchase}
      className={styles.modal}
      withoutCross={true}
    >
      <div className={styles.container}>
        <header>{words['user.basket.thank']}</header>
        <p>{words['user.basket.requestSentToAdmin']}</p>
        <div className={styles['thank-for-purchase']}>
          <ThankForPurchase />
        </div>
        <div>
          <Button className={styles['btn-ok']} type="button" onClick={handleClick}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  )
}
