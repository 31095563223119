import { Input } from 'components/UiKit/Inputs'
import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCheckedItems } from 'screens/admin/Team/TeamAdmin.actions'
import { Icons } from '../../../../../components/Icons'
import styles from './SetColumns.module.scss'

export interface IChekbox {
  name: string
  label: string
  value: boolean
}
export interface ISetColumnsProps {
  checkedItems: IChekbox[]
  bottom: string
}

export const SetColumns: FC<ISetColumnsProps> = ({ checkedItems, bottom }) => {
  const [openOptions, setOpenOptions] = useState(false)
  const dispatch = useDispatch()

  const checkboxHandler = (e: any) => {
    const { target } = e
    const { name } = target
    const newCheckedItems = checkedItems.map((checkbox: IChekbox) => {
      if (checkbox.name === name) {
        checkbox.value = target.checked
      }
      return checkbox
    })
    dispatch(setCheckedItems(newCheckedItems))
  }

  return (
    <div className={styles.container}>
      {openOptions && (
        <>
          <div
            className={styles.overlay}
            onClick={() => {
              setOpenOptions(false)
            }}
          />
          <div style={{ bottom }} className={styles.openSetColumnsWrapper}>
            {checkedItems.map((checkbox: IChekbox) => {
              return (
                <div className={styles['checkbox-container']} key={checkbox.name}>
                  <Input
                    type="checkbox"
                    name={checkbox.name}
                    checked={checkbox.value}
                    label={checkbox.label}
                    className={styles['checkbox-label']}
                    onChange={checkboxHandler}
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
      <div onClick={() => setOpenOptions(true)} className={styles['icon-container']}>
        <Icons icon="columns" />
      </div>
    </div>
  )
}
