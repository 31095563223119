import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { SettingsTimeOfRequest as SettingsTimeOfRequestComponent } from './SettingsTimeOfRequest'
import {
  setProductCalendar,
  getProductCalendar,
  deleteProductCalendar
} from './SettingsTimeOfRequest.thunx'
import { TState } from 'store'

export const mapStateToProps = (state: TState) => ({
  modalLoading: state.admin.settings.timeOfRequest.modal.loading,
  modalSuccess: state.admin.settings.timeOfRequest.modal.success,
  dataTable: state.admin.settings.timeOfRequest.table.results,
  dataLoading: state.admin.settings.timeOfRequest.table.loading,
  dataTotal: state.admin.settings.timeOfRequest.table.total
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setProductCalendar,
      getProductCalendar,
      deleteProductCalendar
    },
    dispatch
  )

export const SettingsTimeOfRequest = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsTimeOfRequestComponent)
