export const SET_ORDER_IN_COLUMN_REQUEST = 'SET_ORDER_IN_COLUMN_REQUEST'
export const SET_ORDER_IN_COLUMN_SUCCESS = 'SET_ORDER_IN_COLUMN_SUCCESS'
export const SET_ORDER_IN_COLUMN_ERROR = 'SET_ORDER_IN_COLUMN_ERROR'
export const SET_PAGE_REQUEST = 'SET_PAGE_REQUEST'
export const SET_PAGE_SUCCESS = 'SET_PAGE_SUCCESS'
export const SET_PAGE_ERROR = 'SET_PAGE_ERROR'

export const GET_HISTORY_TRANSACTIONS_REQUEST = 'GET_HISTORY_TRANSACTIONS_REQUEST'
export const GET_HISTORY_TRANSACTIONS_SUCCESS = 'GET_HISTORY_TRANSACTIONS_SUCCESS'
export const GET_HISTORY_TRANSACTIONS_ERROR = 'GET_HISTORY_TRANSACTIONS_ERROR'

export const CLEAR_HISTORY_DATA = 'CLEAR_HISTORY_DATA'

export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST'
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR'

export const CLEAR_TRANSACTIONS_DATA = 'CLEAR_TRANSACTIONS_DATA'

export const GET_USER_PROFILE_INFO_REQUEST = 'GET_USER_PROFILE_INFO_REQUEST'
export const GET_USER_PROFILE_INFO_SUCCESS = 'GET_USER_PROFILE_INFO_SUCCESS'
export const GET_TUSER_PROFILE_INFO_ERROR = 'GET_TUSER_PROFILE_INFO_ERROR'

export const GET_ALL_USERS_LIST = 'GET_ALL_USERS_LIST'

export const CHECK_USER_PROFILE_REQUEST = 'CHECK_USER_PROFILE_REQUEST'
export const CHECK_USER_PROFILE_SUCCESS = 'CHECK_USER_PROFILE_SUCCESS'
export const CHECK_USER_PROFILE_ERROR = 'CHECK_USER_PROFILE_ERROR'
