import { createContext } from 'react'

const superAdmin = 'superAdmin'
const admin = 'admin'
const manager = 'manager'
const user = 'user'

export const userRoles: { [key: number]: string } = {
  1: superAdmin,
  2: admin,
  3: manager,
  4: user
}

export type TUserDataWithoutTokens = {
  id: number
  email: string
  fullName: string
  balance: number
  role: number
  partOfService: string
  photo: string
  iat: number
  exp: number
}

export type TContextValue = {
  data?: TUserDataWithoutTokens
  superAdmin: false
  admin: false
  manager: false
  user: false
}

export const defaultContextValue: TContextValue = {
  superAdmin: false,
  admin: false,
  manager: false,
  user: false
}

export const UserContext = createContext(defaultContextValue)

export const localStorageUserKey = 'user'
export const isSuperAdmin = (role: number) => userRoles[role] === superAdmin
export const isAdmin = (role: number) => userRoles[role] === admin
export const isManager = (role: number) => userRoles[role] === manager
export const isUser = (role: number) => userRoles[role] === user
