import {
  GET_INFO_REQUEST,
  GET_INFO_SUCCESS,
  GET_INFO_ERROR,
  DELETE_INFO_REQUEST,
  DELETE_INFO_SUCCESS,
  DELETE_INFO_ERROR,
  CREATE_INFO_REQUEST,
  CREATE_INFO_SUCCESS,
  CREATE_INFO_ERROR,
  UPDATE_INFO_REQUEST,
  UPDATE_INFO_SUCCESS,
  UPDATE_INFO_ERROR
} from './Information.constants'
import { IAction } from 'models'
import { ActionCreator } from 'redux'

export const getInformationRequest: ActionCreator<IAction> = payload => ({
  type: GET_INFO_REQUEST,
  payload
})

export const getInformationSuccess: ActionCreator<IAction> = payload => ({
  type: GET_INFO_SUCCESS,
  payload
})

export const getInformationError: ActionCreator<IAction> = payload => ({
  type: GET_INFO_ERROR,
  payload
})

export const deleteInformationRequest: ActionCreator<IAction> = payload => ({
  type: DELETE_INFO_REQUEST,
  payload
})

export const deleteInformationSuccess: ActionCreator<IAction> = payload => ({
  type: DELETE_INFO_SUCCESS,
  payload
})

export const deleteInformationError: ActionCreator<IAction> = payload => ({
  type: DELETE_INFO_ERROR,
  payload
})

export const createInformationRequest: ActionCreator<IAction> = payload => ({
  type: CREATE_INFO_REQUEST,
  payload
})

export const createInformationSuccess: ActionCreator<IAction> = payload => ({
  type: CREATE_INFO_SUCCESS,
  payload
})

export const createInformationError: ActionCreator<IAction> = payload => ({
  type: CREATE_INFO_ERROR,
  payload
})

export const updateInformationRequest: ActionCreator<IAction> = payload => ({
  type: UPDATE_INFO_REQUEST,
  payload
})

export const updateInformationSuccess: ActionCreator<IAction> = payload => ({
  type: UPDATE_INFO_SUCCESS,
  payload
})

export const updateInformationError: ActionCreator<IAction> = payload => ({
  type: UPDATE_INFO_ERROR,
  payload
})
