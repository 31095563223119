import React, { FC } from 'react'
import { IProps } from './model'
import styles from './style.module.scss'
import cls from 'classnames'
import ReactTooltip from 'react-tooltip'

export const Textarea: FC<IProps> = ({
  name,
  className = '',
  title,
  placeholder,
  onChange,
  isInvalid,
  errorMessage,
  value,
  readonly,
  required
}) => {
  const inputClass = cls({
    [styles['comments']]: true,
    [className]: true,
    [styles.invalid]: isInvalid
  })

  return (
    <>
      <div data-tip="" html-for={name}>
        <label className={cls(inputClass, { [styles['label-required']]: required })}>
          <span>{title}</span>
          <div data-tip="" data-for="comments">
            <textarea
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              readOnly={readonly}
            />
          </div>
        </label>
      </div>
      {isInvalid && errorMessage && (
        <ReactTooltip
          id="comments"
          place="bottom"
          type="error"
          effect="solid"
          getContent={() => errorMessage}
          globalEventOff="click"
          class={styles.tooltip}
        />
      )}
    </>
  )
}
