import React, { FC } from 'react'
import styles from './ConfirmAction.module.scss'

export type TConfirmAction = {
  onCancel: () => void
  onSuccess: () => void
  successLabel?: string
  cancelLabel?: string
}

export const ConfirmAction: FC<TConfirmAction> = ({
  children = 'Удалить ?',
  successLabel = 'Да',
  cancelLabel = 'Нет',
  onSuccess,
  onCancel
}) => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>{children}</span>
      <div className={styles.actions}>
        <button className={styles.success} onClick={onSuccess}>
          {successLabel}
        </button>
        <span className={styles.separator} />
        <button className={styles.cancel} onClick={onCancel}>
          {cancelLabel}
        </button>
      </div>
    </div>
  )
}
