import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import { AchievementItemComponent } from './AchievementItem'
import {
  deleteGoodsCategory,
  getGoodsCategories,
  setNewCategory
} from '../../../GoodsItem/GoodsItem.thunx'
import {
  createOrEditAchievement,
  getCurrentAchievement
} from '../../../../client/Achievements/Achievements.thunx'

export const mapStateToProps = (state: TState) => ({
  achievement: state.client.achievements.currentAchievement,
  categories: state.admin.goodsItem.achievementCategories
})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      getGoodsCategories,
      createOrEditAchievement,
      getCurrentAchievement,
      deleteGoodsCategory,
      setNewCategory
    },
    dispatch
  )
})
export const AchievementItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AchievementItemComponent))
