import React, { FC } from 'react'
import { withRouter, Route, Redirect } from 'react-router-dom'
import { TOASTR_DEFAULT_MESSAGES } from '../../utils/toastrMessages'
import { TPrivateRouter } from './PrivatRoute.model'
import { IUserData } from 'models'
import { jwtDecode } from 'utils/jwt'
import toastr from 'utils/toastr'

const PrivateRoute: FC<TPrivateRouter> = ({
  isAuthenticated,
  component: Component,
  path,
  signInTokenSuccess,
  signInTokenFailed,
  successPath,
  sideBarMenu,
  routes,
  languageObject,
  ...rest
}) => {
  if (window.location.pathname.match(/success/)) {
    const [accessToken, refreshToken] = window.location.search.split('&')
    const [, accessTokenValue] = accessToken.split('=')
    const [, refreshTokenValue] = refreshToken.split('=')
    const userData: IUserData | any = jwtDecode(accessTokenValue)

    try {
      signInTokenSuccess({
        ...userData,
        accessToken: accessTokenValue,
        refreshToken: refreshTokenValue
      })

      rest.history.push(successPath)
    } catch (error) {
      toastr('error', TOASTR_DEFAULT_MESSAGES.NO_PERMISSIONS, error)
    }
  } else if (window.location.pathname.match(/failure/)) {
    signInTokenFailed()
    rest.history.push('/signin')
    toastr('error', TOASTR_DEFAULT_MESSAGES.NO_PERMISSIONS)
  }

  if (isAuthenticated && path === '/signin') {
    return <Redirect to={successPath} />
  }

  return (
    <Route
      {...rest}
      path={path}
      // @ts-ignore
      component={(props: any) =>
        isAuthenticated ? (
          <Component
            {...props}
            routes={routes}
            successPath={successPath}
            sideBarMenu={sideBarMenu}
            languageObject={languageObject}
          />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}
export default withRouter(PrivateRoute)
