import { AxiosError } from 'axios'
import { action } from 'store/store.utils'
import {
  GET_PRODUCT_CALENDAR_ITEM_ERROR,
  GET_PRODUCT_CALENDAR_ITEM_REQUEST,
  GET_PRODUCT_CALENDAR_ITEM_SUCCESS,
  UPDATE_PRODUCT_CALENDAR_ERROR,
  UPDATE_PRODUCT_CALENDAR_REQUEST,
  UPDATE_PRODUCT_CALENDAR_SUCCESS
} from './SettingsTimeOfRequestUpdate.constants'

export const getProductCalendarItemRequest = () => action(GET_PRODUCT_CALENDAR_ITEM_REQUEST)
export const getProductCalendarItemSuccess = (payload: []) =>
  action(GET_PRODUCT_CALENDAR_ITEM_SUCCESS, payload)
export const getProductCalendarItemError = (payload: AxiosError) =>
  action(GET_PRODUCT_CALENDAR_ITEM_ERROR, payload)

export const updateProductCalendarRequest = () => action(UPDATE_PRODUCT_CALENDAR_REQUEST)
export const updateProductCalendarSuccess = (payload: []) =>
  action(UPDATE_PRODUCT_CALENDAR_SUCCESS, payload)
export const updateProductCalendarError = (payload: AxiosError) =>
  action(UPDATE_PRODUCT_CALENDAR_ERROR, payload)
