export interface IValidationMessages {
  required: string
  email: string
  password: string
  confirmPassword: string
  name: string
  minText: string
  maxText: string
  twoFACode: string
  isNumber: string
  isInteger: string
  isPhoneNumber: string
}

// TODO --- ADD i18 lib
export const messages: IValidationMessages = {
  required: 'Required',
  email: 'Invalid email address',
  password: 'Invalid password',
  confirmPassword: 'Passwords mismatch',
  name: `Incorrect! min 3 max 32 ( - ' )`,
  minText: 'Should be greater than',
  maxText: 'Should be less than',
  twoFACode: 'Code only 6 digits',
  isNumber: 'Must be a number',
  isInteger: 'Must be a integer',
  isPhoneNumber: '+380000000000'
}
