export type TBalance = {
  balance: number
  debtBalance: number
  dayOffBalance: number
  dayOffLimit: number
  totalRemote: number
  remoteLimit: number
  totalSick: number
  sickLimit: number
  workingHoursPerDay: number
}

export const currentBalance = (type: number, balanceValue: TBalance) => {
  const value: { [key: string]: number } = {}
  switch (type) {
    case 2:
      value.days = Math.floor(balanceValue.balance / balanceValue.workingHoursPerDay) || 0
      value.hours = Math.floor(balanceValue.balance % balanceValue.workingHoursPerDay) || 0
      value.limitDays = Math.floor(balanceValue.dayOffLimit / balanceValue.workingHoursPerDay) || 0
      value.limitHours = Math.floor(balanceValue.dayOffLimit % balanceValue.workingHoursPerDay) || 0
      break
    case 5:
      value.days = Math.floor(balanceValue.totalSick / balanceValue.workingHoursPerDay) || 0
      value.hours = Math.floor(balanceValue.totalSick % balanceValue.workingHoursPerDay) || 0
      break
    case 6:
      value.days = Math.floor(balanceValue.totalRemote / balanceValue.workingHoursPerDay) || 0
      value.hours = Math.floor(balanceValue.totalRemote % balanceValue.workingHoursPerDay) || 0
      break
    default:
      value.days = 0
      value.days = 0
      break
  }
  return value
}
