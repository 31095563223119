import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import {
  updateUserRequest,
  updateUserSuccess,
  updateUserError,
  getPositions
} from './EditProfile.actions'
import toastr from '../../../utils/toastr'
import { TState } from 'store'
import { transformApiResToFabricFormat } from 'utils/transformApiResToFabricFormat'

export const updateUser = (body: any, infoType: string, isAdmin = false) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(updateUserRequest())
  const words = getData().global.language.words

  try {
    await api.put(`${API.URL}${isAdmin ? API.ADMIN_UPDATE : API.CLIENT_UPDATE}/${infoType}`, body)
    dispatch(updateUserSuccess())
  } catch (error) {
    dispatch(updateUserError(error.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], error)
  }
}

export const getSelectOptions = () => async (dispatch: Dispatch, getData: () => TState) => {
  const words = getData().global.language.words
  try {
    const positions = (await api.get(`${API.URL}${API.POSITIONS}`)).data.payload.result.map(
      (item: any) => transformApiResToFabricFormat(words, item)
    )

    const rank = (await api.get(`${API.URL}${API.RANK}`)).data.payload.result.map((item: any) =>
      transformApiResToFabricFormat(words, item)
    )

    const maritalStatus = (await api.get(
      `${API.URL}${API.MARITIAL_STATUS}`
    )).data.payload.result.map((item: any) => transformApiResToFabricFormat(words, item))
    maritalStatus.unshift({ value: null, label: 'Не выбрано' })

    const englishLevel = (await api.get(`${API.URL}${API.ENGLISH_LEVEL}`)).data.payload.result.map(
      (item: any) => transformApiResToFabricFormat(words, item)
    )

    const academicDegree = (await api.get(
      `${API.URL}${API.ACADEMIC_DEGREE}`
    )).data.payload.result.map((item: any) => transformApiResToFabricFormat(words, item))
    academicDegree.unshift({ value: null, label: 'Не выбрано' })

    const department = (await api.get(`${API.URL}${API.DEPARTMENT}`)).data.payload.result.map(
      (item: any) => transformApiResToFabricFormat(words, item)
    )

    const typeOfEmployment = (await api.get(
      `${API.URL}${API.TYPE_OF_EMPLOYMENT}`
    )).data.payload.result.map((item: any) => transformApiResToFabricFormat(words, item))
    dispatch(
      getPositions({
        positions,
        rank,
        maritalStatus,
        englishLevel,
        academicDegree,
        department,
        typeOfEmployment
      })
    )
  } catch (error) {
    toastr('error', words['FAILED_TO_UPDATE_USER'], error)
  }
}
