import { Dispatch } from 'redux'
import { API, api } from 'services/api'
import {
  getUsersSuccess,
  getUsersRequest,
  getUsersError,
  updateUserRequest,
  updateUserSuccess,
  updateUserError,
  deleteUsersRequest,
  deleteUsersSuccess,
  deleteUsersError,
  restoreUsersRequest,
  restoreUsersSuccess,
  restoreUsersError,
  getPositionsRequest,
  getPositionsSuccess,
  getPositionsError,
  updatePointsBalanceRequest,
  updatePointsBalanceSuccess,
  updatePointsBalanceError,
  getUsersToFilterSuccess,
  getUsersToFilterError
} from './Users.actions'
import { TUpdatePointsBalanceBody, TUpdateUserPayload } from './Users.model'
import history from 'utils/history'
import toastr from 'utils/toastr'
import { TBalance } from './components/UsersModalTimeEditForm/UsersModalTimeEditForm.model'
import {
  updateCurrentTimeBalanceError,
  updateCurrentTimeBalanceRequest,
  updateCurrentTimeBalanceSuccess
} from '../../client/NewRequestTimeOff/NewRequestTimeOff.actions'
import { TState } from 'store'

export const getUsers = (page: number = 1, search: string = '') => async (dispatch: Dispatch) => {
  dispatch(getUsersRequest())
  try {
    const { data } = await api.get(`${API.CLIENT}${search}${search ? '&' : '?'}page=${page}`)
    dispatch(getUsersSuccess(data.payload))
  } catch (error) {
    dispatch(getUsersError(error))
  }
}

export const updateUser = (body: TUpdateUserPayload) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(updateUserRequest())
  const words = getData().global.language.words
  try {
    await api.put(`${API.CLIENT}/${body.id}`, body)
    dispatch(updateUserSuccess(body))
    toastr('info', words['SUCCESS_UPDATED'])
    history.push('/dashboard/users')
  } catch (error) {
    dispatch(updateUserError(error))
    toastr('error', words['FILED_UPDATE_USER'], error)
    history.push('/dashboard/users')
  }
}

export const updatePointsBalance = (body: TUpdatePointsBalanceBody) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(updatePointsBalanceRequest())
  const words = getData().global.language.words
  try {
    const response = await api.post(`${API.TRANSACTION_ADMIN}`, body)
    dispatch(updatePointsBalanceSuccess(response.data))
    toastr('info', words['SUCCESS_UPDATED'])
    // history.push('/dashboard/users')
  } catch (error) {
    dispatch(updatePointsBalanceError(error))
    toastr('error', words['FILED_UPDATE_BALANCE'], error)
    // history.push('/dashboard/users')
  }
}

export const deleteUser = (id: number) => async (dispatch: Dispatch, getData: () => TState) => {
  dispatch(deleteUsersRequest())
  const words = getData().global.language.words
  try {
    await api.delete(`${API.CLIENT}/${id}`)
    dispatch(deleteUsersSuccess(id))
    toastr('info', words['SUCCESS_DELETED'])
  } catch (error) {
    toastr('error', words['FILED_DELETE_USER'], error)
    dispatch(deleteUsersError(error))
  }
}

export const restoreUser = (id: number) => async (dispatch: Dispatch, getData: () => TState) => {
  dispatch(restoreUsersRequest())
  const words = getData().global.language.words
  try {
    await api.put(`${API.CLIENT_RESTORE}/${id}`)
    dispatch(restoreUsersSuccess(id))
    toastr('info', words['SUCCESS_RESTORED'])
  } catch (error) {
    toastr('error', words['FILED_RESTORE_USER'], error)
    dispatch(restoreUsersError(error))
  }
}

export const updateCurrentTimeBalance = (id: number, body: TBalance) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(updateCurrentTimeBalanceRequest())
  const words = getData().global.language.words
  try {
    const { status } = await api.put(`${API.CALENDAR}${API.BALANCE_UPDATE}/${id}`, body)
    if (status === 200) {
      dispatch(updateCurrentTimeBalanceSuccess(body))
      toastr('info', words['SUCCESS_UPDATED'])
      // history.push('/dashboard/users')
    } else {
      toastr('error', words['FILED_UPDATE_TIME'])
    }
  } catch (error) {
    dispatch(updateCurrentTimeBalanceError(error))
    toastr('error', words['FILED_UPDATE_TIME'], error)
    // history.push('/dashboard/users')
  }
}

export const getPosition = () => async (dispatch: Dispatch) => {
  dispatch(getPositionsRequest())
  try {
    const { data } = await api.get(`${API.POSITIONS}`)
    dispatch(getPositionsSuccess(data.payload))
  } catch (error) {
    dispatch(getPositionsError(error))
  }
}

export const getUsersToFilter = () => async (dispatch: Dispatch) => {
  try {
    const { data } = await api.get(`${API.CLIENT}?limit=2000`)
    dispatch(getUsersToFilterSuccess(data.payload))
  } catch (error) {
    dispatch(getUsersToFilterError(error))
  }
}
