import { AxiosError } from 'axios'
import { action } from 'store/store.utils'
import {
  GET_POINTS_SETTINGS_ERROR,
  GET_POINTS_SETTINGS_REQUEST,
  GET_POINTS_SETTINGS_SUCCESS,
  UPDATE_POINTS_SETTINGS_ERROR,
  UPDATE_POINTS_SETTINGS_REQUEST,
  UPDATE_POINTS_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_POINTS_BALANCE_REQUEST,
  UPDATE_SETTINGS_POINTS_BALANCE_SUCCESS,
  UPDATE_SETTINGS_POINTS_BALANCE_ERROR
} from './SettingsPointsSystem.constants'
import { TUpdatePointsSettingsBody } from './SettingsPointsSystem.thunx'
import { TUpdatePointsBalancePayload } from '../../../Users/Users.model'

export const getPointsSettingsRequest = () => action(GET_POINTS_SETTINGS_REQUEST)
export const getPointsSettingsSuccess = (payload: []) =>
  action(GET_POINTS_SETTINGS_SUCCESS, payload)
export const getPointsSettingsError = (payload: AxiosError) =>
  action(GET_POINTS_SETTINGS_ERROR, payload)

export const updatePointsSettingsRequest = () => action(UPDATE_POINTS_SETTINGS_REQUEST)
export const updatePointsSettingsSuccess = (payload: TUpdatePointsSettingsBody) =>
  action(UPDATE_POINTS_SETTINGS_SUCCESS, payload)
export const updatePointsSettingsError = (payload: AxiosError) =>
  action(UPDATE_POINTS_SETTINGS_ERROR, payload)

export const updateSettingsPointsBalanceRequest = () =>
  action(UPDATE_SETTINGS_POINTS_BALANCE_REQUEST)
export const updateSettingsPointsBalanceSuccess = (payload: TUpdatePointsBalancePayload) =>
  action(UPDATE_SETTINGS_POINTS_BALANCE_SUCCESS, payload)
export const updateSettingsPointsBalanceError = (payload: AxiosError) =>
  action(UPDATE_SETTINGS_POINTS_BALANCE_ERROR, payload)
