export const GET_TIME_OFF_REQUESTS_REQUEST = 'GET_TIME_OFF_REQUESTS_REQUEST'
export const GET_TIME_OFF_REQUESTS_SUCCESS = 'GET_TIME_OFF_REQUESTS_SUCCESS'
export const GET_TIME_OFF_REQUESTS_ERROR = 'GET_TIME_OFF_REQUESTS_ERROR'

export const ACTION_TIME_OFF_REQUEST = 'ACTION_TIME_OFF_REQUEST'
export const ACTION_TIME_OFF_SUCCESS = 'ACTION_TIME_OFF_SUCCESS'
export const ACTION_TIME_OFF_ERROR = 'ACTION_TIME_OFF_ERROR'

export const GET_CURRENT_ROW_REQUEST_SUCCESS = 'GET_CURRENT_ROW_REQUEST_SUCCESS'
export const GET_CURRENT_ROW_REQUEST_ERROR = 'GET_CURRENT_ROW_REQUEST_ERROR'
