import { Dispatch } from 'redux'
import {
  getLocalizationRequest,
  getLocalizationSuccess,
  getLocalizationError
} from './language.actions'
import { API, api } from 'services/api'
import toastr from 'utils/toastr'
import { TOASTR_DEFAULT_MESSAGES } from 'utils/toastrMessages'

export const fetchInformation = (language: string) => async (dispatch: Dispatch) => {
  dispatch(getLocalizationRequest())

  try {
    const response: any = await api.get(`${API.LOCALIZATION}?lang=${language}`)
    const formatter = (arr: [any]) => {
      return arr.reduce((init, item) => {
        init[item.key] = item.text
        return init
      }, {})
    }

    const data = formatter(response.data)
    dispatch(getLocalizationSuccess({ words: data, language }))
  } catch (error) {
    await dispatch(getLocalizationError(error))
    toastr('error', TOASTR_DEFAULT_MESSAGES.UNSUCCESSFUL_WORDS_LOADING)
  }
}
