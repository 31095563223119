import React, { FC, useState } from 'react'
import styles from './Settings.module.scss'
import { SettingsTimeOfRequest } from './components/SettingsTimeOfRequest'
import queryString from 'query-string'
import history from '../../../utils/history'
import { IToggleBarData, ToggleBar } from '../../../components/ToggleBar'
import { SettingsPointsSystem } from './components/SettingsPointsSystem'
import { useSelector } from 'react-redux'
import { TState } from 'store'

type TSettingsProps = {}

export const Settings: FC<TSettingsProps> = () => {
  const words = useSelector((state: TState) => state.global.language.words)
  const parsed = queryString.parse(history.location.search)

  const toggleBarOptions = [
    {
      value: 'calendar',
      title: words['sidebar.dayOffTracker']
    },
    {
      value: 'pointsSystem',
      title: words['sidebar.pointsSystem']
    }
  ]

  const [selectedType, setSelectedType] = useState<IToggleBarData>(
    parsed.points === 'true' ? toggleBarOptions[1] : toggleBarOptions[0]
  )
  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <h1>{words['admin.header.settings']}</h1>
      </section>
      <div className={styles.toggle}>
        <ToggleBar
          name="settings"
          data={toggleBarOptions}
          defaultChecked={selectedType}
          onChange={event => {
            history.push({
              pathname: `${history.location.pathname}`,
              search: `points=${event.value === 'pointsSystem'}`
            })
            setSelectedType(event)
          }}
        />
      </div>
      {selectedType.value === 'pointsSystem' && <SettingsPointsSystem />}
      {selectedType.value === 'calendar' && <SettingsTimeOfRequest />}
    </div>
  )
}
