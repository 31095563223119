import React, { FC } from 'react'
import logo from '../../assets/images/logo-small.svg'
import styles from './style.module.scss'
import cls from 'classnames'

export type TSpanProps = {
  size?: 'default' | 'button'
}

const Spinner: FC<TSpanProps> = ({ size = 'default' }) => {
  return (
    <div className={cls({ [styles.spinner]: true, [styles['spinner-btn']]: size === 'button' })}>
      <img src={logo} alt="logo" />
    </div>
  )
}

export default Spinner
