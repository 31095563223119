import {
  GET_GOODS_REQUEST,
  GET_GOODS_SUCCESS,
  GET_GOODS_ERROR,
  CLEAR_GOODS_DATA
} from './Goods.constants'
import { action } from '../../../store/store.utils'
import { IProduct } from './Goods.model'
import { AxiosError } from 'axios'

export const getGoodsRequest = () => action(GET_GOODS_REQUEST)
export const getGoodsSuccess = (payload: IProduct) => action(GET_GOODS_SUCCESS, payload)
export const getGoodsError = (payload: AxiosError) => action(GET_GOODS_ERROR, payload)

export const clearGoodsData = () => action(CLEAR_GOODS_DATA)
