import { connect } from 'react-redux'
import AchievementsComponent from './Achievements'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from '../../../store'
import { createRequestAchievement, getAchievements } from './Achievements.thunx'
import { getGoodsCategories } from '../../admin/GoodsItem/GoodsItem.thunx'
import { getPointsSettings } from '../../admin/Settings/components/SettingsPointsSystem/SettingsPointsSystem.thunx'

export const mapStateToProps = (state: TState) => ({
  data: state.client.achievements.data,
  total: state.client.achievements.total,
  minPrice: state.client.achievements.minPrice,
  maxPrice: state.client.achievements.maxPrice,
  categories: state.admin.goodsItem.achievementCategories,
  teamBalance: state.admin.settings.pointsSystem.result
})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      getAchievements,
      getGoodsCategories,
      getPointsSettings,
      createRequestAchievement
    },
    dispatch
  )
})

export const Achievements = connect(
  mapStateToProps,
  mapDispatchToProps
)(AchievementsComponent)
