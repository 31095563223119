import React, { FC } from 'react'
import { IProductElement } from './ProductElement.model'
import styles from '../../Basket.module.scss'
import { Counter } from 'components/Counter/counter'
import { Icons } from 'components/Icons'

export const ProductElement: FC<IProductElement> = ({ addToCart, removeFromCart, element }) => {
  const handleDeleteBasketItem = (event: React.SyntheticEvent) => {
    event.preventDefault()
    removeFromCart(element, 'all')
  }

  return (
    <div className={styles['counter-element']}>
      <div className={styles['picture']}>
        <img src={element.photo} alt="" />
      </div>
      <div className={styles['title-and-count']}>
        <div>
          <div>{element.name}</div>
        </div>
        <div>
          <Counter element={element} addToCart={addToCart} removeFromCart={removeFromCart} />
        </div>
      </div>
      <div className={styles['cost']}>
        <div>
          <button onClick={handleDeleteBasketItem}>
            <Icons icon="dischargeOutline" />
          </button>
        </div>
        <div>
          <span>{element.total}</span> <Icons icon="pointIcon" />
        </div>
      </div>
    </div>
  )
}
