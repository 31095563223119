import React, { FC } from 'react'
import styles from './RatingView.module.scss'
import { Icons } from '../../../Icons'

export type TRatingViewProps = {
  rating: number
}

export const RatingView: FC<TRatingViewProps> = ({ rating }) => {
  const renderRating = (rate: number) => {
    const result: JSX.Element[] = []
    for (let i = 0; i < 5; i++) {
      if (i < rate) {
        result.push(
          <div>
            <Icons icon="starSmallActive" />
          </div>
        )
      } else {
        result.push(
          <div>
            <Icons icon="starSmallPassive" />
          </div>
        )
      }
    }
    return result
  }

  return <div className={styles.container}>{renderRating(rating)}</div>
}
