import { connect } from 'react-redux'
import { App } from './App'
import { TState } from 'store'
import { bindActionCreators, Dispatch } from 'redux'
import { fetchInformation } from 'store/global'
import { withRouter } from 'react-router'

export const mapStateToProps = (state: TState) => ({
  accessToken: state.auth.data ? state.auth.data.accessToken : null,
  loading: state.global.language.loading,
  error: state.global.language.error,
  languages: state.global.language.languages,
  currentLanguage: state.global.language.currentLanguage
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ fetchInformation }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(App))
