import React from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'

import styles from '../../EditProfile.module.scss'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { editCareerInfoConfig } from './EditCareer.config'

export const EditCareer: any = ({ form, isAdmin }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const options = useSelector((state: TState) => state.client.editProfile)
  return (
    <>
      <section className={styles.section}>
        <h3>{words['user.profile.career.info']}</h3>
        <FieldFactory
          form={form}
          config={editCareerInfoConfig(words, { options, isAdmin })}
          words={words}
        />
      </section>
    </>
  )
}
