import React, { FC } from 'react'
import styles from './RateStars.module.scss'

export type TRateStarsProps = {
  ratingState: number | null
  onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void
}

export const RateStars: FC<TRateStarsProps> = ({ ratingState, onChange }) => {
  const handlerChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onChange(e)
  }
  // TODO -- need to syplify HTML
  return (
    <>
      <div className={styles.container}>
        <div className={styles['reviewStars-input']}>
          <input
            id={'star-5'}
            type="radio"
            name="reviewStars"
            value="5"
            checked={ratingState === 5}
            onChange={e => handlerChange(e)}
          />
          <label htmlFor="star-5" />

          <input
            id={'star-4'}
            type="radio"
            name="reviewStars"
            value="4"
            checked={ratingState === 4}
            onChange={e => handlerChange(e)}
          />
          <label htmlFor="star-4" />

          <input
            id={'star-3'}
            type="radio"
            name="reviewStars"
            value="3"
            checked={ratingState === 3}
            onChange={e => handlerChange(e)}
          />
          <label htmlFor="star-3" />

          <input
            id={'star-2'}
            type="radio"
            name="reviewStars"
            value="2"
            checked={ratingState === 2}
            onChange={e => handlerChange(e)}
          />
          <label htmlFor="star-2" />

          <input
            id={'star-1'}
            type="radio"
            name="reviewStars"
            value="1"
            checked={ratingState === 1}
            onChange={e => handlerChange(e)}
          />
          <label htmlFor="star-1" />
        </div>
      </div>
    </>
  )
}
