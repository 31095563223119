import React, { FC } from 'react'
import cn from 'classnames'
import IProps from './model'
import styles from './style.module.scss'

const Input: FC<IProps> = ({
  name,
  className,
  title,
  placeholder,
  onChange,
  value,
  isInvalid,
  customRef
}) => (
  <label
    className={cn(
      styles.base,
      styles[`${className}`],
      isInvalid && { [styles.invalid]: isInvalid }
    )}
  >
    <span>{title}</span>
    <input
      ref={customRef}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  </label>
)

export default Input
