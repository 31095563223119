import { connect } from 'react-redux'
import { AchievementsAdm as AchievementsAdmComponent } from './AchievementsAdm'
import { bindActionCreators, Dispatch } from 'redux'
import { getGoodsCategories } from '../GoodsItem/GoodsItem.thunx'
import { TState } from 'store'
import {
  deleteAchievement,
  getAchievements,
  restoreAchievement
} from '../../client/Achievements/Achievements.thunx'
import { clearAchievementsData } from '../../client/Achievements/Achievements.actions'

export const mapStateToProps = (state: TState) => ({
  data: state.client.achievements.dataAchievements,
  total: state.client.achievements.total,
  minPrice: state.client.achievements.minPrice,
  maxPrice: state.client.achievements.maxPrice,
  categories: state.admin.goodsItem.achievementCategories
})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      getAchievements,
      getGoodsCategories,
      deleteAchievement,
      restoreAchievement,
      clearAchievementsData
    },
    dispatch
  )
})

export const AchievementsAdm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AchievementsAdmComponent)
