export type TCurrentItem = { [key: string]: number | string }

export const formattingObjectValuesToNumber = <D extends TCurrentItem>(obj: D) =>
  Object.keys(obj).reduce<any>((init, item) => {
    if (obj[item] === null) {
      return {
        ...init
      }
    }
    if (typeof obj[item] !== 'number') {
      if (typeof obj[item] === 'boolean') {
        return {
          ...init
        }
      }
      const newStr = (obj[item] as string).replace(/,/, '.')
      return {
        ...init,
        [item]: Number(newStr)
      }
    }

    return {
      ...init,
      [item]: obj[item]
    }
  }, {})
