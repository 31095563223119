import { Dispatch } from 'redux'
import { API, api } from 'services/api'
import toastr from '../../../utils/toastr'
import {
  removeGoodError,
  removeGoodRequest,
  removeGoodSuccess,
  restoreGoodError,
  restoreGoodRequest,
  restoreGoodSuccess
} from './Shop.actions'
import { TState } from 'store'

export const removeGood = (id: number) => async (dispatch: Dispatch, getData: () => TState) => {
  dispatch(removeGoodRequest())
  const words = getData().global.language.words

  try {
    await api.delete(`${API.GOODS}/${id}`)
    toastr('success', words['SUCCESS_DELETED'])
    dispatch(removeGoodSuccess(id))
  } catch (error) {
    dispatch(removeGoodError(error))
    toastr('error', words['FILED_DELETE_ITEM'])
  }
}

export const restoreGood = (id: number) => async (dispatch: Dispatch, getData: () => TState) => {
  dispatch(restoreGoodRequest())
  const words = getData().global.language.words

  try {
    await api.put(`${API.GOODS_RESTORE}/${id}`)
    dispatch(restoreGoodSuccess(id))
    toastr('info', words['SUCCESS_RESTORED'])
  } catch (error) {
    toastr('error', words['FILED_RESTORE_ITEM'], error)
    dispatch(restoreGoodError(error))
  }
}
