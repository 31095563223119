import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { SettingsTimeOfRequestUpdate as SettingsTimeOfRequestUpdateComponent } from './SettingsTimeOfRequestUpdate'
import { getProductCalendarItem, updateProductCalendar } from './SettingsTimeOfRequestUpdate.thunx'
import { TState } from 'store'

export const mapStateToProps = (state: TState) => ({
  calendarItem: state.admin.settings.timeOfRequestUpdate.calendarItem.results
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getProductCalendarItem,
      updateProductCalendar
    },
    dispatch
  )

export const SettingsTimeOfRequestUpdate = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsTimeOfRequestUpdateComponent)
