import React, { FC, useState } from 'react'
import styles from './ItemFAQ.module.scss'
import { Icons } from 'components/Icons'
import { IconsUpdateAndDelete } from '../IconsUpdateAndDelete'

export type TModalProps = {
  question: string
  response: string
  isAdmin: boolean
  delete: (id: string | number) => void
  update: (id: number) => void
  elemId: number | string
}

const Item: FC<TModalProps> = props => {
  const [opened, setOpened] = useState(false)
  const isAdmin = props.isAdmin
  return (
    <div
      onClick={() => {
        setOpened(!opened)
      }}
      className={styles.item}
    >
      <div className={styles.openedItem}>
        <div className={isAdmin ? styles.adminOpenedItemQuestion : styles.openedItemQuestion}>
          {props.question}
          <Icons
            icon={opened ? 'iconFaqClose' : 'iconFaqOpen'}
            className={isAdmin ? styles.adminIcon : styles.icon}
          />
          <div
            className={isAdmin ? styles.adminIcons : styles.none}
            onClick={event => {
              event.stopPropagation()
            }}
          >
            <IconsUpdateAndDelete
              id={props.elemId}
              onRemove={(elem: string | number) => props.delete(elem)}
              onUpdate={(id: number) => props.update(id)}
            />
          </div>
        </div>
        <div className={opened ? styles.responce : styles.none}>{props.response}</div>
      </div>
    </div>
  )
}

export default Item
