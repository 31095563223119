import { IAction } from 'models/index'
import { ActionCreator } from 'redux'
import {
  SET_NEW_ITEM_REQUEST,
  SET_NEW_ITEM_SUCCESS,
  SET_NEW_ITEM_ERROR,
  GET_GOODS_CATEGORIES_REQUEST,
  GET_GOODS_CATEGORIES_SUCCESS,
  GET_GOODS_CATEGORIES_ERROR,
  GET_CURRENT_ITEM_REQUEST,
  GET_CURRENT_ITEM_SUCCESS,
  GET_CURRENT_ITEM_ERROR,
  REMOVE_REVIEW_REQUEST,
  REMOVE_REVIEW_SUCCESS,
  REMOVE_REVIEW_ERROR,
  REMOVE_REVIEW_ADMIN_REQUEST,
  REMOVE_REVIEW_ADMIN_ERROR,
  REMOVE_REVIEW_ADMIN_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_ERROR,
  SET_NEW_CATEGORY_REQUEST,
  SET_NEW_CATEGORY_ERROR,
  SET_NEW_CATEGORY_SUCCESS
} from './GoodsItem.constants'
import { action } from 'store/store.utils'
import { AxiosError } from 'axios'
import { TCategories, TGoodData } from '../../client/GoodsItemDetails/GoodsItemDetails.model'

export const requestSetCreateItem: ActionCreator<IAction> = payload => ({
  type: SET_NEW_ITEM_REQUEST,
  payload
})

export const successSetCreateItem: ActionCreator<IAction> = payload => ({
  type: SET_NEW_ITEM_SUCCESS,
  payload
})

export const errorSetCreateItem: ActionCreator<IAction> = payload => ({
  type: SET_NEW_ITEM_ERROR,
  payload
})

export const requestGetGoodsCategories: ActionCreator<IAction> = payload => ({
  type: GET_GOODS_CATEGORIES_REQUEST,
  payload
})

export const successGetGoodsCategories: ActionCreator<IAction> = (payload: TCategories[]) => ({
  type: GET_GOODS_CATEGORIES_SUCCESS,
  payload
})

export const errorGetGoodsCategories: ActionCreator<IAction> = (payload: AxiosError) => ({
  type: GET_GOODS_CATEGORIES_ERROR,
  payload
})

export const getCurrentItemRequest = () => action(GET_CURRENT_ITEM_REQUEST)
export const getCurrentItemSuccess = (payload: TGoodData) =>
  action(GET_CURRENT_ITEM_SUCCESS, payload)
export const getCurrentItemError = (payload: AxiosError) => action(GET_CURRENT_ITEM_ERROR, payload)

export const removeReviewRequest = () => action(REMOVE_REVIEW_REQUEST)
export const removeReviewSuccess = (payload: number | string) =>
  action(REMOVE_REVIEW_SUCCESS, payload)
export const removeReviewError = (payload: AxiosError) => action(REMOVE_REVIEW_ERROR, payload)

export const removeReviewAdminRequest = () => action(REMOVE_REVIEW_ADMIN_REQUEST)
export const removeReviewAdminSuccess = (payload: number | string) =>
  action(REMOVE_REVIEW_ADMIN_SUCCESS, payload)
export const removeReviewAdminError = (payload: AxiosError) =>
  action(REMOVE_REVIEW_ADMIN_ERROR, payload)

export const deleteCategoryRequest = () => action(DELETE_CATEGORY_REQUEST)
export const deleteCategorySuccess = (payload: { id: string | number }) =>
  action(DELETE_CATEGORY_SUCCESS, payload)
export const deleteCategoryError = (payload: AxiosError) => action(DELETE_CATEGORY_ERROR, payload)

export const setNewCategoryRequest = () => action(SET_NEW_CATEGORY_REQUEST)
export const setNewCategorySuccess = (payload: TCategories) =>
  action(SET_NEW_CATEGORY_SUCCESS, payload)
export const setNewCategoryError = (payload: AxiosError) => action(SET_NEW_CATEGORY_ERROR, payload)
