import React, { FC, useEffect, useMemo /*useState*/ } from 'react'
import queryString from 'query-string'
import { THistoryTransactionProps } from './HistoryTransaction.model'
import { getColumns } from './HistoryTransaction.columns'
import { Table } from 'components/UiKit/Table'
import { ETransactionHistory, ERowSize } from 'globalConfigs'
import styles from './HistoryTransaction.module.scss'
// import cls from 'classnames'
import { Filter } from 'components/UiKit/Filter'
// import { ButtonFilter } from 'components/ButtonFilter'
import { getFilterConfig } from './HistoryTransaction.filter.config'
import { useLoadDateFortTable } from 'hooks/useLoadDateFortTable'
import { TSelectOption } from '../../../../../components/Select'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TItemPerson } from '../../Profile.model'

export const HistoryTransactionComponent: FC<THistoryTransactionProps> = ({
  historyTransaction,
  transactions,
  payers,
  receivers,
  total,
  minPrice,
  maxPrice,
  minDate,
  maxDate,
  getHistory,
  getTransactions,
  clearHistoryData,
  clearTransactionsData,
  history
}) => {
  const parsed = queryString.parse(history.location.search)
  const isTransactions = parsed.type === ETransactionHistory.TRANSACTIONS
  const words = useSelector((state: TState) => state.global.language.words)
  const filteredTransactions = transactions.filter(element => element.status !== 3)

  useEffect(() => {
    clearHistoryData()
    clearTransactionsData()
  }, [])

  const { setCurrentPage } = useLoadDateFortTable(!isTransactions ? getHistory : getTransactions)

  // const [openFilter, setOpenFilter] = useState<boolean>(false)
  // todo - need to filter, but now filter is hidden
  // const toggleOpenFilter = () => setOpenFilter(!openFilter)
  // const toggleOpenFilter = () => setOpenFilter(false)

  const columns = getColumns({ parsed, words })

  const payersData: TSelectOption[] = useMemo(
    () =>
      payers.map((item: TItemPerson) => ({
        value: `${item.id}`,
        label: item.fullName
      })),
    [payers]
  )

  const receiversData: TSelectOption[] = useMemo(
    () =>
      receivers.map((item: TItemPerson) => ({
        value: `${item.id}`,
        label: item.fullName
      })),
    [receivers]
  )

  const columnsMemoized = useMemo(() => columns, [])
  return (
    <>
      <div>
        <Filter
          config={getFilterConfig({
            type: parsed.type,
            priceFrom: minPrice,
            priceTo: maxPrice,
            dateFrom: minDate,
            dateTo: maxDate,
            payers: payersData,
            receivers: receiversData,
            words
          })}
          defaultOpened={true}
          withButton={true}
          // withSubmitButton={true}
        />
      </div>
      <div className={styles['table-container']}>
        <Table
          total={total}
          data={!isTransactions ? historyTransaction : filteredTransactions}
          getData={({ page }) => {
            setCurrentPage(page)
          }}
          noData={words['noData']}
          columns={columnsMemoized}
          rowSize={ERowSize.MIDDLE}
        />
      </div>
    </>
  )
}
