import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './Requests.module.scss'
import { TRequestItem, TRequestsDetailsItem, TRequestsProps } from './Requests.model'
import { Button } from 'components/UiKit/Button'
import { Table } from '../../../components/UiKit/Table'
import history from '../../../utils/history'
import { IToggleBarData, ToggleBar } from '../../../components/ToggleBar'
import queryString from 'query-string'
import { Columns } from './Requests.columns'
import { Redirect } from 'react-router'
import { useLoadDateFortTable } from '../../../hooks/useLoadDateFortTable'
import { RegectCommentModal } from './components/RegectCommentModal'
import { RequestDetailsModal } from './components/RequestDetailsModal/RequectDetailsModal'
import { getFilterConfig } from './Requests.filter.config'
import { TSelectOption } from '../../../components/Select'
import { Filter } from '../../../components/UiKit/Filter'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { IUsersList } from 'global.model'

export const Requests: FC<TRequestsProps> = ({
  data,
  getRequests,
  actionForRequests,
  usersList,
  getUsersList,
  minFromDate,
  maxToDate,
  minPrice,
  maxPrice,
  total,
  clearRequestsData
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const parsed = queryString.parse(history.location.search)
  const { setCurrentPage } = useLoadDateFortTable(getRequests)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [openModalRegectComments, setOpenModalRegectComments] = useState(false)
  const toggleModalRegectComments = () => setOpenModalRegectComments(!openModalRegectComments)
  const [openModalRequestDetails, setOpenModalRequestDetails] = useState(false)
  const toggleModalRequestDetails = () => setOpenModalRequestDetails(!openModalRequestDetails)
  const [detailsId, setDetailsId] = useState(null)
  const toggleBarOptions = [
    {
      value: '2',
      title: words['user.profile.table.goodsInitiative']
    },
    {
      value: '1',
      title: words['admin.settings.pointsSystem.achivements']
    }
  ]

  const [selectedType, setSelectedType] = useState<IToggleBarData>(
    parsed.type === '1' ? toggleBarOptions[1] : toggleBarOptions[0]
  )
  const columns = Columns(words, selectedType.value)
  const columnsMemoized = useMemo(() => columns, [])

  useEffect(() => {
    getUsersList()
    clearRequestsData()
  }, [])

  const getDetailsIds = (id: number) => {
    const item = data.find((el: any) => el.id === id)
    const details = item && item.details
    const detailsIds = details && details.map((el: TRequestsDetailsItem) => el.id)
    return detailsIds || []
  }

  const getCurrentDetails = (id: number | null) => {
    if (id === null) {
      return []
    }
    const item = data.find(el => el.id === id)
    const details = item && item.details
    return details || []
  }

  const getCurrentUserName = (id: number | null) => {
    const item = data.find(el => el.id === id)
    const userName = item && item.userId.fullName
    return userName
  }

  const handlerRegectAction = async (comment: string) => {
    if (selectedIds && selectedIds.length > 0) {
      const requestBody: TRequestItem[] = selectedIds.map(item => ({
        requestId: item,
        detailsIds: getDetailsIds(item),
        adminComment: comment
      }))
      await actionForRequests({ management: requestBody, type: 'reject' }, 'reject')
      setSelectedIds([])
    }
    history.push(`${history.location.pathname}${history.location.search}`)
  }

  const handlerRejectItem = (id: number, comment: string) => {
    const requestBody = [
      {
        requestId: detailsId,
        detailsIds: [id],
        adminComment: comment
      }
    ]
    // @ts-ignore
    actionForRequests({ management: requestBody, type: 'reject' }, 'reject')
    setSelectedIds([])
  }
  const handlerApproveItem = (id: number) => {
    const requestBody = [
      {
        requestId: detailsId,
        detailsIds: [id],
        adminComment: ''
      }
    ]
    // @ts-ignore
    actionForRequests({ management: requestBody, type: 'approve' }, 'approve')
    setSelectedIds([])
  }

  const usersData: TSelectOption[] = useMemo(
    () =>
      usersList.map((item: IUsersList) => ({
        value: `${item.fullName}`,
        label: item.fullName
      })),
    [usersList]
  )
  const closeDatailsModal = () => {
    history.push(`${history.location.pathname}${history.location.search}`)
  }
  const closeToggleModalRegectComments = () => {
    toggleModalRegectComments()
    history.push(`${history.location.pathname}${history.location.search}`)
  }

  const approveHandler = async () => {
    if (selectedIds && selectedIds.length > 0) {
      const requestBody: TRequestItem[] = selectedIds.map(item => ({
        requestId: item,
        detailsIds: getDetailsIds(item),
        adminComment: ''
      }))
      await actionForRequests({ management: requestBody, type: 'approve' }, 'approve')
      setSelectedIds([])
    }
    history.push(`${history.location.pathname}${history.location.search}`)
  }

  if (!parsed.type) {
    return <Redirect to={{ search: 'type=2', pathname: history.location.pathname }} />
  }
  const selectedTypeIntoFilter = selectedType.value
  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>{words['sidebar.pointsSystem.requests']}</h1>
          <div className={styles['header-btns']}>
            <div className={styles.toggle}>
              <ToggleBar
                name="active-goods-no-active"
                data={toggleBarOptions}
                defaultChecked={selectedType}
                onChange={event => {
                  history.push({
                    pathname: `${history.location.pathname}`,
                    search: `type=${event.value}`
                  })
                  setSelectedType(event)
                }}
              />
            </div>

            <div className={styles['btns-container']}>
              <Button
                outline={true}
                className={styles.btn}
                disabled={!selectedIds.length}
                // loading={loadingReject}
                onClick={() => toggleModalRegectComments()}
              >
                <span>{words['adminManager.dayOffTracker.buttons.reject']}</span>
              </Button>

              <Button
                className={styles.btnApprove}
                disabled={!selectedIds.length}
                onClick={approveHandler}
              >
                <span>{words['adminManager.dayOffTracker.buttons.approve']}</span>
              </Button>
            </div>
          </div>
        </section>
        <Filter
          config={getFilterConfig(
            words,
            {
              usersData,
              minFromDate,
              maxToDate,
              minPrice,
              maxPrice
            },
            selectedTypeIntoFilter
          )}
          defaultOpened={true}
          withButton={true}
        />
        <Table
          data={data}
          noData={words['noData']}
          columns={columnsMemoized}
          total={total}
          getData={({ page }) => {
            setCurrentPage(page)
          }}
          onSelectedRows={rows => {
            if (rows) {
              setSelectedIds(
                rows.reduce((accumulator: number[], { original }) => {
                  // if (original.requestStatus === 1) {
                  // TODO -- check status
                  accumulator.push(original.id)
                  // }
                  return accumulator
                }, [])
              )
            }
          }}
          onRowClick={d => {
            toggleModalRequestDetails()
            setDetailsId(d.original.id)
          }}
          classNameToRow={parsed.type === '2' ? styles.cursor : undefined}
        />
      </div>

      {openModalRequestDetails && parsed.type === '2' && (
        <RequestDetailsModal
          data={getCurrentDetails(detailsId)}
          userName={getCurrentUserName(detailsId) || ''}
          isShow={openModalRequestDetails}
          // onClose={toggleModalRequestDetails}
          onClose={closeDatailsModal}
          handlerApprove={handlerApproveItem}
          handlerReject={handlerRejectItem}
        />
      )}

      {openModalRegectComments && (
        <RegectCommentModal
          isShow={openModalRegectComments}
          onClose={closeToggleModalRegectComments}
          onSubmit={handlerRegectAction}
        />
      )}
    </>
  )
}
