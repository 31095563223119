import {
  DELETE_GOOD_REQUEST,
  DELETE_GOOD_SUCCESS,
  DELETE_GOOD_ERROR,
  RESTORE_GOOD_REQUEST,
  RESTORE_GOOD_SUCCESS,
  RESTORE_GOOD_ERROR
} from './Shop.constants'
import { AxiosError } from 'axios'
import { action } from 'store/store.utils'

export const removeGoodRequest = () => action(DELETE_GOOD_REQUEST)
export const removeGoodSuccess = (id: number) => action(DELETE_GOOD_SUCCESS, { id })
export const removeGoodError = (payload: AxiosError) => action(DELETE_GOOD_ERROR, payload)

export const restoreGoodRequest = () => action(RESTORE_GOOD_REQUEST)
export const restoreGoodSuccess = (id: number) => action(RESTORE_GOOD_SUCCESS, { id })
export const restoreGoodError = (payload: AxiosError) => action(RESTORE_GOOD_ERROR, payload)
