import { TSelectOption } from 'components/Select'
import { ISelectOptions } from 'screens/client/EditProfile/EditProfile.model'
import { TSocialNetworks } from 'screens/client/NewRequestTimeOff/NewRequestTimeOff.reducer'

export type TParsedUserData = {
  id: number
  fullName: string
  rewieverId: number
  adminName: string
  googleId: string
  hashPassword: string
  email: string
  city: TSelectOption
  balance: number
  photo: string
  roles: string[]
  deletedRoles: string[]
  degree: TSelectOption
  children: any
  dateOfBirth: string
  firstTerminatingDay: string
  firstWorkingDay: string
  hobby: string
  hometown: string
  lifeGoals: string
  phoneNumber: string
  socialNetworks: TSocialNetworks
  files: any
  university: string
  workingHoursPerDay: number
  technologies: string
  careerGoal: string
  previousJob: string
  salary: number
  probationEndDate: number
  academicDegree: ISelectOptions
  rank: ISelectOptions
  lifePriorities: ISelectOptions
  maritalStatus: ISelectOptions
  department: ISelectOptions
  typeOfEmployment: ISelectOptions
  englishLevel: ISelectOptions
  position: ISelectOptions
  terminationInitiator: ISelectOptions
}

export const parseSelectedOptions = (
  userInfo: any,
  getLabel: boolean = false,
  options: any
): TParsedUserData => {
  const {
    positions,
    rank,
    maritalStatus,
    englishLevel,
    academicDegree,
    department,
    typeOfEmployment,
    terminationInitiator
  } = options
  const parsedInfo: any = userInfo
    ? {
        ...userInfo,
        position:
          positions && userInfo.position && userInfo.position.id
            ? positions.find(
                (element: TSelectOption) => Number(element.value) === userInfo.position.id
              )
            : null,
        rank:
          rank && userInfo.rank && userInfo.rank.id
            ? rank.find((element: TSelectOption) => Number(element.value) === userInfo.rank.id)
            : null,
        maritalStatus:
          maritalStatus && userInfo.maritalStatus && userInfo.maritalStatus.id
            ? maritalStatus.find(
                (element: TSelectOption) => Number(element.value) === userInfo.maritalStatus.id
              )
            : null,
        academicDegree:
          academicDegree && userInfo.academicDegree && userInfo.academicDegree.id
            ? academicDegree.find(
                (element: TSelectOption) => Number(element.value) === userInfo.academicDegree.id
              )
            : null,
        // lifePriorities:
        //   userInfo.lifePriorities && userInfo.lifePriorities
        //     ? LIFE_PRIORITIES(words).find(
        //         (element: TSelectOption) => element.value === userInfo.lifePriorities
        //       )
        //     : null,
        englishLevel:
          englishLevel && userInfo.englishLevel && userInfo.englishLevel.id
            ? englishLevel.find(
                (element: TSelectOption) => Number(element.value) === userInfo.englishLevel.id
              )
            : null,
        department:
          department && userInfo.department && userInfo.department.id
            ? department.find(
                (element: TSelectOption) => Number(element.value) === userInfo.department.id
              )
            : null,
        typeOfEmployment:
          typeOfEmployment && userInfo.typeOfEmployment && userInfo.typeOfEmployment.id
            ? typeOfEmployment.find(
                (element: TSelectOption) =>
                  Number(element.value) === Number(userInfo.typeOfEmployment.id)
              )
            : null,
        terminationInitiator:
          terminationInitiator && userInfo.terminationInitiator && userInfo.terminationInitiator.id
            ? terminationInitiator.find(
                (element: TSelectOption) =>
                  Number(element.value) === Number(userInfo.terminationInitiator.id)
              )
            : null
      }
    : null

  if (getLabel && parsedInfo) {
    for (const key in parsedInfo) {
      if (
        parsedInfo[key] !== null &&
        typeof parsedInfo[key] === 'object' &&
        parsedInfo[key].hasOwnProperty('label')
      )
        parsedInfo[key] = parsedInfo[key].label
    }
  }
  return parsedInfo
}
