import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './Goods.module.scss'
import { TGoodsProps } from './Goods.model'
import Product from './Product/product'
import { Filter } from 'components/UiKit/Filter'
import Pagination from 'react-js-pagination'
import { ReactComponent as Prev } from 'assets/images/prev.svg'
import { ReactComponent as Next } from 'assets/images/next.svg'
import { getFilterConfig } from './Goods.filter.config'
import { TSelectOption } from '../../../components/Select'
import { TGoodsCategories } from '../../admin/GoodsItem/GoodsItem.model'
import { EPageLimits } from '../../../globalConfigs'
import history from '../../../utils/history'
import queryString from 'query-string'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export const Goods: FC<TGoodsProps> = ({
  data,
  getGoods,
  getGoodsCategories,
  goodsCategories,
  addToCart,
  location
}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)

  useEffect(() => {
    getGoodsCategories()
  }, [])

  useEffect(() => {
    if (!parsed.deleted) {
      getGoods(pageNumber, `?deleted=false`, EPageLimits.GOODS)
    } else {
      getGoods(pageNumber, `${location.search}`, EPageLimits.GOODS)
    }
  }, [location.search])

  const categories: TSelectOption[] = useMemo(
    () =>
      goodsCategories.map((item: TGoodsCategories) => ({
        value: `${item.id}`,
        label: item.name
      })),
    [goodsCategories]
  )

  const numberOfPeople: TSelectOption[] = useMemo(
    () =>
      // TODO -- need added numberOfPeople from props
      new Array(12).fill(1).map((a, i) => ({
        value: `${a + i}`,
        label: `${a + i}`
      })),
    [data]
  )

  const productArr =
    data &&
    data.results.map((item: any, id: any) => {
      return (
        <Product
          key={id}
          id={item.id}
          name={item.name}
          price={item.price}
          quantity={item.numberOfPeople}
          category={item.category}
          photo={item.croppedPhoto[0]}
          addToCart={addToCart}
        />
      )
    })

  const handlePageChange = (page: number) => {
    setPageNumber(page)
    getGoods(page, `${location.search}`, EPageLimits.GOODS)
  }

  const emptyMessage = words['noOption']

  if (!parsed.deleted) {
    return <Redirect to={{ search: 'deleted=false', pathname: history.location.pathname }} />
  }
  return (
    <section className={styles.container}>
      <section className={styles.header}>
        <h1>{words['sidebar.pointsSystem.market']}</h1>
      </section>
      <section className={styles.section_filter}>
        <Filter
          config={getFilterConfig({
            categories,
            minPrice: data && data.minPrice,
            maxPrice: data && data.maxPrice,
            numberOfPeople,
            words,
            emptyMessage
          })}
          defaultOpened={true}
        />
      </section>
      <section className={styles.main}>{productArr}</section>

      <section className={styles.pagination}>
        <Pagination
          itemsCountPerPage={EPageLimits.GOODS}
          totalItemsCount={data && data.total}
          activePage={pageNumber}
          onChange={handlePageChange}
          hideFirstLastPages={true}
          prevPageText={<Prev />}
          nextPageText={<Next />}
          linkClass={styles['pagination-item']}
          activeLinkClass={styles['pagination-active']}
          disabledClass={styles['pagination-disabled']}
        />
      </section>
    </section>
  )
}
