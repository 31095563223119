import {
  GET_GOODS_REQUEST,
  GET_GOODS_SUCCESS,
  GET_GOODS_ERROR,
  CLEAR_GOODS_DATA
} from './Goods.constants'
import { IInitialState } from './Goods.model'
import { IAction } from 'models'
import { DELETE_GOOD_SUCCESS, RESTORE_GOOD_SUCCESS } from '../../admin/Shop/Shop.constants'

export const initialState: IInitialState = {
  data: [],
  dataGoods: [],
  total: 0,
  minPrice: 0,
  maxPrice: 0,
  error: '',
  loading: false
}

export const getProductlector = (state: any) => state.goods.data
export const getErrorSelector = (state: any) => state.goods.error
export const getLoadingSelector = (state: any) => state.goods.loading

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_GOODS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_GOODS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataGoods: [...state.dataGoods, ...action.payload.payload.results],
        minPrice: action.payload.payload.minPrice,
        maxPrice: action.payload.payload.maxPrice,
        total: action.payload.payload.total,
        loading: false
      }

    case RESTORE_GOOD_SUCCESS:
      return {
        ...state,
        dataGoods: state.dataGoods.filter(element => element.id !== action.payload.id)
      }

    case DELETE_GOOD_SUCCESS:
      return {
        ...state,
        dataGoods: state.dataGoods.filter(element => element.id !== action.payload.id)
      }

    case GET_GOODS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    case CLEAR_GOODS_DATA:
      return {
        ...initialState
      }

    default:
      return state
  }
}
