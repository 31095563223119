import React from 'react'
import styles from './style.module.scss'

export const BounceLoader = () => {
  return (
    <div className={styles['sk-bounce']}>
      <div className={styles['sk-bounce-dot']} />
      <div className={styles['sk-bounce-dot']} />
    </div>
  )
}
