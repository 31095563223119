export const imageToBlob = (url: string, callback: (b: Blob | null) => void) => {
  const img = new Image()
  const c = document.createElement('canvas')
  const ctx = c.getContext('2d')

  img.onload = () => {
    c.width = img.naturalWidth
    c.height = img.naturalHeight
    if (ctx) {
      ctx.drawImage(img, 0, 0)
      c.toBlob(b => callback(b), 'image/jpeg', 1)
    }
  }
  img.crossOrigin = ''
  img.src = url
}
