import { connect } from 'react-redux'
import { Filter as FilterComponent, TOwnProps } from './Filter'
import { TState } from 'store'
import { bindActionCreators, Dispatch } from 'redux'
import { displayAllFilterFields, isOpenFilter } from './Filter.actions'

export const mapStateToProps = (state: TState, ownProps: TOwnProps) => ({
  openFilter: state.client.filter.openFilter,
  multilineFilterFields: state.client.filter.multilineFilterFields,
  ...ownProps
})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      isOpenFilter,
      displayAllFilterFields
    },
    dispatch
  )
})

export const Filter = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterComponent)
