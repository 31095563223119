import React, { FC } from 'react'
import Modal from 'components/Modal'
import { Field, Form } from 'react-final-form'
import styles from './UsersModalPointsEditForm.module.scss'
import { Button } from 'components/UiKit/Button'
import { Input } from 'components/UiKit/Inputs'
import { TUsersModalPointsEditForm } from './UsersModalPointsEditForm.model'
import { Icons } from '../../../../../components/Icons'
import validation from '../../../../../utils/validation'
import { Textarea } from '../../../../../components/Textarea'
import { TUpdatePointsBalanceBody } from '../../Users.model'
import { EPointsEdit } from '../../../../../globalConfigs'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export const UsersModalPointsEditForm: FC<TUsersModalPointsEditForm> = ({
  open,
  togglePointsEdit,
  id,
  user,
  updatePointsBalance
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  return (
    <Modal isShow={open} onClose={togglePointsEdit} className={styles.modal}>
      <Form
        onSubmit={(values: { [key: string]: any }) => {
          if (id !== null) {
            const body: TUpdatePointsBalanceBody = {
              transactions: [
                {
                  receiverID: id,
                  amount: Number(values.points) || 0,
                  comment: values.comments || ''
                }
              ]
            }
            updatePointsBalance(body)
          }
        }}
      >
        {({ form, handleSubmit }) => {
          const formState = form.getState()
          return (
            <form onSubmit={handleSubmit} className={styles.form}>
              <header>{words['admin.settings.pointsSystem.balanceEdit.header']}</header>

              <div className={styles['form-row']}>
                <div className={styles['balance-input-container']}>
                  <div className={styles['input-label']}>
                    {`${words['admin.settings.pointsSystem.balanceEdit.points']}:`}
                    <span className={styles['red-note']}>*</span>
                  </div>
                  <Field
                    name="points"
                    validate={validation.composeValidators(
                      validation.required(words['user.requiredMessage']),
                      validation.isNumber()
                    )}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className={styles['div-wrapper']}>
                          <button
                            onClick={() => {
                              if (
                                !formState.values.points ||
                                typeof formState.values.points !== 'number'
                              ) {
                                form.change('points', -EPointsEdit.STEP)
                              } else {
                                form.change(
                                  'points',
                                  Number(formState.values.points) - EPointsEdit.STEP
                                )
                              }
                            }}
                            type="button"
                          >
                            <Icons icon="remove" />
                          </button>
                          <div className={styles['input-wrapper']}>
                            <Input
                              variant="outlined"
                              type="number"
                              isInvalid={meta.error && meta.submitFailed}
                              errorMessage={meta.error}
                              {...input}
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                !formState.values.points ||
                                typeof formState.values.points !== 'number'
                              ) {
                                form.change('points', EPointsEdit.STEP)
                              } else {
                                form.change(
                                  'points',
                                  Number(formState.values.points) + EPointsEdit.STEP
                                )
                              }
                            }}
                          >
                            <Icons icon="add" />
                          </button>
                        </div>
                      )
                    }}
                  </Field>
                </div>
                <div className={styles['balance-info']}>
                  {`${words['admin.users.table.balanceEdit.currentBalance']}:`}{' '}
                  <span className={styles['current-balance']}>{user ? user.balance : '0'}</span>
                </div>
              </div>

              <div className={styles['comments-wrapper']}>
                <Field
                  name="comments"
                  validate={validation.composeValidators(
                    validation.required(words['user.requiredMessage']),
                    validation.minValue(2)
                  )}
                >
                  {({ input, meta }) => {
                    return (
                      <div>
                        <Textarea
                          {...input}
                          className={styles['points-edit-comments']}
                          title={
                            <span>
                              {words['admin.settings.pointsSystem.balanceEdit.comment']}
                              <span className={styles['red-note']}>*</span>
                            </span>
                          }
                          placeholder={
                            words['admin.settings.pointsSystem.balanceEdit.comment.placeHolder']
                          }
                          isInvalid={meta.error && meta.submitFailed}
                          errorMessage={meta.error}
                        />
                      </div>
                    )
                  }}
                </Field>
              </div>

              <div className={styles['form-row-btn']}>
                <Button type="submit" className={styles['div-btn-wrapper']}>
                  {words['admin.PointsSystem.balanceEdit.buttons.apply']}
                </Button>
              </div>
            </form>
          )
        }}
      </Form>
    </Modal>
  )
}
