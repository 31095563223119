import { TFieldConfigRow } from 'components/UiKit/FieldFactory/FieldFactory.model'
import { TWords } from 'elements/SideBar/SideBar.config'
import style from './EditPointsSystem.module.scss'

type TEditPSConfigProps = {
  words: TWords
}

export const editPSConfig = (props: TEditPSConfigProps): TFieldConfigRow[] => {
  const { words } = props
  const row1: TFieldConfigRow = {
    items: [
      {
        name: 'comments',
        label: words['admin.settings.pointsSystem.balanceEdit.comment'],
        required: true,
        inputWrapperClassName: style['points-edit-comments'],
        component: () => {
          return {
            type: 'textarea',
            props: {
              variant: 'outlined',
              placeholder: words['admin.settings.pointsSystem.balanceEdit.comment.placeHolder']
            }
          }
        }
      }
    ]
  }
  return [row1]
}
