import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import uniq from 'lodash/uniq'
import queryString from 'query-string'

export const useLoadDateFortTable = <C extends (page: number, search: string) => void>(
  callback: C
): any => {
  const location = useLocation()

  const [currentPage, setCurrentPage] = useState<number | null>(null)
  const [loadedPages, setLoadedPages] = useState<number[]>([])

  const parsed = queryString.parse(location.search)
  delete parsed.profileType

  useEffect(() => {
    if (currentPage !== null && !loadedPages.includes(currentPage)) {
      callback(currentPage, '?' + queryString.stringify(parsed))
      setLoadedPages(uniq([...loadedPages, currentPage]))
    }
  }, [queryString.stringify(parsed), currentPage, loadedPages])

  return { currentPage, loadedPages, setCurrentPage }
}
