import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Goods } from './Goods'
import { addToCart } from 'screens/client/Basket/Basket.actions'
import { withRouter } from 'react-router-dom'
import { getGoods } from './Goods.thunx'
import { TState } from '../../../store'
import { getGoodsCategories } from '../../admin/GoodsItem/GoodsItem.thunx'

export const mapStateToProps = (state: TState) => ({
  data: state.client.goods.data.payload,
  goodsCategories: state.admin.goodsItem.goodsCategories
})

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      addToCart,
      getGoods,
      getGoodsCategories
    },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Goods))
