import { IAction } from 'models/index'
import {
  UPDATE_POINTS_SETTINGS_SUCCESS,
  UPDATE_POINTS_SETTINGS_REQUEST,
  UPDATE_POINTS_SETTINGS_ERROR,
  GET_POINTS_SETTINGS_ERROR,
  GET_POINTS_SETTINGS_SUCCESS,
  GET_POINTS_SETTINGS_REQUEST,
  UPDATE_SETTINGS_POINTS_BALANCE_SUCCESS,
  UPDATE_SETTINGS_POINTS_BALANCE_ERROR
} from './SettingsPointsSystem.constants'

export type TSettingsPointsSystemInitialState = {
  result: {
    id: number
    pointsFundPerMonth: number
    achievementsAmount: number
    pointsFund: number
    feeCharger: number
  }
}
export const initialState: TSettingsPointsSystemInitialState = {
  result: {
    id: 0,
    pointsFundPerMonth: 0,
    achievementsAmount: 0,
    pointsFund: 0,
    feeCharger: 1
  }
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case UPDATE_POINTS_SETTINGS_REQUEST:
      return {
        ...state
      }

    case UPDATE_POINTS_SETTINGS_SUCCESS:
      return {
        ...state,
        result: {
          ...state.result,
          achievementsAmount: action.payload.achievementsAmount,
          pointsFundPerMonth: action.payload.pointsFundPerMonth
        }
      }

    case UPDATE_POINTS_SETTINGS_ERROR:
      return {
        ...state
      }

    case GET_POINTS_SETTINGS_REQUEST:
      return {
        ...state,
        result: {
          ...state.result,
          loading: true
        }
      }

    case GET_POINTS_SETTINGS_SUCCESS:
      return {
        ...state,
        result: action.payload.payload.result,
        loading: false
      }

    case UPDATE_SETTINGS_POINTS_BALANCE_SUCCESS: {
      return {
        ...state
      }
    }

    case GET_POINTS_SETTINGS_ERROR:
      return {
        ...state,
        result: {
          ...state.result,
          error: action.payload,
          loading: false
        }
      }

    case UPDATE_SETTINGS_POINTS_BALANCE_ERROR:
      return {
        ...state
      }

    default:
      return state
  }
}
