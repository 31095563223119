import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './RadialChart.module.scss'

export type TRadialChart = {
  progress: number
  color: string
}

const ANIMATION_SPEED = 1

export const RadialChart: FC<TRadialChart> = ({ progress = 0, color = 'none', children }) => {
  const circumference = 2 * 3.14 * 40.5
  const strokeLengthTotal = (circumference / 100) * progress
  const [strokeLength, setStrokeLength] = useState(0)
  const [intervalId, setIntervalId] = useState()

  useEffect(() => {
    const interval = setInterval(() => {
      setStrokeLength(length => length + ANIMATION_SPEED)
    }, 10)
    setIntervalId(interval)
    return () => clearInterval(interval)
  }, [])

  useMemo(() => {
    if (strokeLength >= strokeLengthTotal) {
      clearInterval(intervalId)
      setStrokeLength(strokeLengthTotal)
    }
  }, [strokeLength])

  return (
    <div className={styles.chart}>
      <div className={styles.child}>{children}</div>
      <svg
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {progress <= 0 && (
          <path
            d="M0.25 44C0.25 68.1625 19.8375 87.75 44 87.75C68.1625 87.75 87.75 68.1625 87.75 44C87.75 19.8375 68.1625 0.25 44 0.25C19.8375 0.25 0.25 19.8375 0.25 44ZM44 81.25C23.4274 81.25 6.75 64.5726 6.75 44C6.75 23.4274 23.4274 6.75 44 6.75C64.5726 6.75 81.25 23.4274 81.25 44C81.25 64.5726 64.5726 81.25 44 81.25Z"
            fill="#FCFBFE"
            stroke={color}
            strokeWidth="0.6"
          />
        )}

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44 88C68.3005 88 88 68.3005 88 44C88 19.6995 68.3005 0 44 0C19.6995 0 0 19.6995 0 44C0 68.3005 19.6995 88 44 88ZM44 81C64.4345 81 81 64.4345 81 44C81 23.5655 64.4345 7 44 7C23.5655 7 7 23.5655 7 44C7 64.4345 23.5655 81 44 81Z"
          fill="#FCFBFE"
        />

        {progress > 0 && (
          <circle
            className={styles.circle}
            style={{
              transformOrigin: 'center',
              transform: `rotate(270deg)`
            }}
            cx="44"
            cy="44"
            r="40.5"
            fill="none"
            stroke={progress <= 0 ? 'none' : color}
            strokeWidth="7"
            strokeDasharray={`${strokeLength}, ${circumference}`}
            strokeLinecap="round"
          />
        )}
      </svg>
    </div>
  )
}
