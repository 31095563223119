import { Column } from 'react-table'
import { TableSortHeader } from '../../../components/UiKit/Table'
import React from 'react'
import styles from './Requests.module.scss'
import { TRequestsResponseData } from './Requests.model'
import { IndeterminateCheckbox } from '../../../components/UiKit/Table/components/IndeterminateCheckbox'
import moment from 'moment'
import { REQUEST_STATUS_COLORS, STATUS_OF_REQUESTS_TIME_OFF } from '../../../globalConfigs'
import { CommentCell } from '../../../components/UiKit/Table/components/CommentsCell'
import cls from 'classnames'
import { TWords } from 'elements/SideBar/SideBar.config'
type TColumnsManager = (words: TWords, selectedType: string) => Array<Column<TRequestsResponseData>>

export const Columns: TColumnsManager = (words, selectedType) => {
  if (selectedType === '2') {
    return [
      // TODO -- it is for expand rows from example https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/expanding
      /*{
      // Build our expander column
      id: 'expander', // Make sure it has an ID
      Cell: ({ row }) => (
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        // @ts-ignore
        <span
          // @ts-ignore
          {...row.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row
              // @ts-ignore
              paddingLeft: `${row.depth * 2}rem`
            }
          })}
        >
          // @ts-ignore
          {row.isExpanded ? '👇V' : '>👉'}
        </span>
      )
    },*/
      {
        id: 'selection',
        // @ts-ignore
        Header: ({ getToggleAllRowsSelectedProps }) => {
          const toggleAllRowsSelectedProps = getToggleAllRowsSelectedProps()
          return <IndeterminateCheckbox isHeader={true} {...toggleAllRowsSelectedProps} />
        },
        // @ts-ignore
        Cell: ({ row }) => {
          return (
            <IndeterminateCheckbox
              // @ts-ignore
              {...row.getToggleRowSelectedProps()}
            />
          )
        },
        width: 20
      },
      {
        Header: ({ column }) => (
          <TableSortHeader id={column.id}>{words['roles.user']}</TableSortHeader>
        ),
        id: 'fullName',
        accessor: d => (
          <>
            <img
              style={{ width: '32px', borderRadius: '50%', marginRight: '8px' }}
              src={d.userId.photo}
              alt="photo"
            />
            {d.userId.fullName}
          </>
        ),
        minWidth: 250
      },
      {
        Header: ({ column }) => (
          <TableSortHeader id={column.id}>
            {words['manager.dayOffTracker.details.requestDate']}
          </TableSortHeader>
        ),
        id: 'requestDate',
        accessor: d => moment(d.requestDate).format('DD.MM.YYYY'),
        minWidth: 100
      },
      {
        Header: ({ column }) => (
          <TableSortHeader id={column.id}>
            {words['user.pointsSystem.market.table.price']}
          </TableSortHeader>
        ),
        id: 'totalPrice',
        accessor: d => d.totalPrice,
        minWidth: 100
      },
      {
        Header: words['user.dayOffTracker.table.requestStatus'],
        id: 'requestStatus',
        accessor: ({ requestStatus }) => (
          <span
            className={cls(
              styles.status,
              requestStatus === 1
                ? styles[REQUEST_STATUS_COLORS[requestStatus]]
                : styles[REQUEST_STATUS_COLORS[5]]
            )}
          >
            {requestStatus === 1
              ? STATUS_OF_REQUESTS_TIME_OFF(words)[requestStatus]
              : STATUS_OF_REQUESTS_TIME_OFF(words)[5]}
          </span>
        ),
        width: 120
      },
      {
        Header: words['admin.users.table.balanceEdit.comment'],
        id: 'comment',
        Cell: ({ row }: any) => {
          return <CommentCell comment={row.original.requestUserComment} />
        },
        minWidth: 100
      }
    ]
  } else {
    return [
      {
        id: 'selection',
        // @ts-ignore
        Header: ({ getToggleAllRowsSelectedProps }) => {
          const toggleAllRowsSelectedProps = getToggleAllRowsSelectedProps()
          return <IndeterminateCheckbox isHeader={true} {...toggleAllRowsSelectedProps} />
        },
        // @ts-ignore
        Cell: ({ row }) => {
          return (
            <IndeterminateCheckbox
              // @ts-ignore
              {...row.getToggleRowSelectedProps()}
            />
          )
        },
        width: 20
      },
      {
        Header: ({ column }) => (
          <TableSortHeader id={column.id}>{words['roles.user']}</TableSortHeader>
        ),
        id: 'fullName',
        accessor: d => (
          <>
            <img
              style={{ width: '32px', borderRadius: '50%', marginRight: '8px' }}
              src={d.userId.photo}
              alt="photo"
            />
            {d.userId.fullName}
          </>
        ),
        minWidth: 250
      },
      {
        Header: ({ column }) => (
          <TableSortHeader id={column.id}>{words['user.profile.table.initiative']}</TableSortHeader>
        ),
        id: 'name',
        accessor: d => d.details[0].initiativeId && d.details[0].initiativeId.name,
        width: 120
      },
      {
        Header: ({ column }) => (
          <TableSortHeader id={column.id}>
            {words['manager.dayOffTracker.details.requestDate']}
          </TableSortHeader>
        ),
        id: 'requestDate',
        accessor: d => moment(d.requestDate).format('DD.MM.YYYY'),
        minWidth: 100
      },
      {
        Header: ({ column }) => (
          <TableSortHeader id={column.id}>{words['user.profile.table.reward']}</TableSortHeader>
        ),
        id: 'totalPrice',
        accessor: d => d.totalPrice,
        minWidth: 100
      },
      {
        Header: words['user.dayOffTracker.table.requestStatus'],
        id: 'requestStatus',
        accessor: ({ details }) => (
          <span className={cls(styles.status, styles[REQUEST_STATUS_COLORS[details[0].status]])}>
            {STATUS_OF_REQUESTS_TIME_OFF(words)[details[0].status]}
          </span>
        ),
        width: 120
      },
      {
        Header: words['admin.users.table.balanceEdit.comment'],
        id: 'comment',
        Cell: ({ row }: any) => {
          return <CommentCell comment={row.original.requestUserComment} />
        },
        minWidth: 100
      }
    ]
  }
}
