import {
  GET_INFO_REQUEST,
  GET_INFO_SUCCESS,
  GET_INFO_ERROR,
  DELETE_INFO_REQUEST,
  DELETE_INFO_SUCCESS,
  DELETE_INFO_ERROR,
  CREATE_INFO_REQUEST,
  CREATE_INFO_SUCCESS,
  CREATE_INFO_ERROR,
  UPDATE_INFO_REQUEST,
  UPDATE_INFO_SUCCESS,
  UPDATE_INFO_ERROR
} from './Information.constants'
import { IInitialState, IElemData } from './Information.model'
import { IAction } from 'models'

export const initialState: IInitialState = {
  data: [],
  error: '',
  loading: false
}

export const getDataSelector = (state: any) => state.info.data
export const getErrorSelector = (state: any) => state.info.error
export const getLoadingSelector = (state: any) => state.info.loading

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_INFO_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }

    case GET_INFO_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    case DELETE_INFO_REQUEST:
      return {
        ...state,
        loading: true
      }

    case DELETE_INFO_SUCCESS:
      return {
        ...state,
        data: state.data.filter((elem: IElemData) => elem.id !== action.payload),
        loading: false
      }

    case DELETE_INFO_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    case CREATE_INFO_REQUEST:
      return {
        ...state,
        loading: true
      }

    case CREATE_INFO_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.data.result],
        loading: false
      }

    case CREATE_INFO_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    case UPDATE_INFO_REQUEST:
      return {
        ...state,
        loading: true
      }

    case UPDATE_INFO_SUCCESS:
      return {
        ...state,
        data: state.data.map((elem: IElemData) => {
          if (elem.id !== action.payload.id) {
            return elem
          } else {
            return { ...action.payload.data, id: action.payload.id }
          }
        }),
        loading: false
      }

    case UPDATE_INFO_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    default:
      return state
  }
}
