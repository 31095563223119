import queryString from 'query-string'
import { TBaseFilterConfigData } from '../Filter'

export const newValueForQuery = (
  filterConfig: TBaseFilterConfigData[],
  hasOwnPropertyReverce: (key: string) => boolean,
  oldQuery: queryString.ParsedQuery<string>,
  res: {},
  values: any,
  item: string
) => {
  const arrInputsTypes = filterConfig
    .map(elem => elem)
    .filter(elem => elem.type === 'input' || elem.type === 'inputRange')

  const oldQueryAndRes = { ...oldQuery, ...res }

  arrInputsTypes.map(elem => {
    if (elem.name === 'priceFrom') {
      if (hasOwnPropertyReverce('priceFrom')) {
        res = { ...oldQueryAndRes, priceFrom: undefined }
      } else if (hasOwnPropertyReverce('priceTo')) {
        res = { ...oldQueryAndRes, priceTo: undefined }
      }
    }
    if (elem.name === 'salaryFrom') {
      if (hasOwnPropertyReverce('salaryFrom')) {
        res = { ...oldQueryAndRes, salaryFrom: undefined }
      } else if (hasOwnPropertyReverce('salaryTo')) {
        res = { ...oldQueryAndRes, salaryTo: undefined }
      }
    }
    if (elem.name === 'childrenFrom') {
      if (hasOwnPropertyReverce('childrenFrom')) {
        res = { ...oldQueryAndRes, childrenFrom: undefined }
      } else if (hasOwnPropertyReverce('childrenTo')) {
        res = { ...oldQueryAndRes, childrenTo: undefined }
      }
    }

    if (hasOwnPropertyReverce(elem.name)) {
      res = { ...oldQueryAndRes, [elem.name]: undefined }
    }

    return res
  })

  if (values[item] === '@ALL@') {
    return {
      ...oldQuery,
      ...res,
      [item]: undefined
    }
  }

  if (item === 'englishLevel' && values[item] && values[item].length) {
    return {
      ...oldQuery,
      ...res,
      [item]: JSON.stringify(values[item])
    }
  }

  if (values[item] !== null && values[item] !== undefined) {
    return {
      ...oldQuery,
      ...res,
      [item]: values[item]
    }
  }

  return res
}
