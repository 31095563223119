import React, { FC } from 'react'
import { IBasketElement } from './BasketElement.model'
import { ProductElement } from '../ProductElement/ProductElement'

export const BasketElement: FC<IBasketElement> = ({ basket, addToCart, removeFromCart }) => {
  return (
    <>
      {basket.map(element => (
        <ProductElement
          key={element.id}
          element={element}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
        />
      ))}
    </>
  )
}
