import React, { FC, useEffect, useState } from 'react'
import styles from './GoodsItemDetails.module.scss'
import { TGoodsItemDetails } from './GoodsItemDetails.model'
import { USER_ROLE } from '../../../globalConfigs'
import { Icons } from '../../../components/Icons'
import { useSelector } from 'react-redux'
import Input from '../../../components/Input'
import { Button } from 'components/UiKit/Button'
import { ReactComponent as PeopleIcon } from 'assets/images/profile_icon.svg'
import { ReactComponent as Logo } from 'screens/client/Goods/Product/point_icon.svg'
import { Reviews } from '../../../components/Reviews'
import { TAutorReview } from '../../../components/Reviews/components/CreateReview/CreateReview'
import { TState } from 'store'

export const GoodsItemDetails: FC<TGoodsItemDetails> = ({
  good,
  getGood,
  addToCart,
  createReview,
  removeReview,
  clearGoodData,
  match,
  history
}) => {
  useEffect(() => {
    getGood(match.params.id)
    return () => {
      clearGoodData()
    }
  }, [])
  const [basketCount, setBasketCount] = useState(1)
  const words = useSelector((state: TState) => state.global.language.words)

  const addToBasket = (goodToBasket: object) => () => {
    addToCart(goodToBasket)
  }

  const onRemove = (id: number | string) => {
    removeReview(id, USER_ROLE)
  }

  const handlerSubmitReview = (
    body: { comment: string; rating: number | null },
    authorReview: TAutorReview
  ) => {
    createReview({ ...body, goodsId: good.id }, authorReview)
  }

  return (
    <>
      <div className={styles.container}>
        <section>
          <div className={styles.header}>
            <h1>{words['user.pointsSystem.market.table.goodsPageName']}</h1>
          </div>
        </section>
        <section>
          <div className={styles['block-go-back']}>
            <button
              type="button"
              className={styles['go-back']}
              onClick={event => {
                event.preventDefault()
                history.goBack()
              }}
            >
              <Icons icon="backArrowIcon" />
              <span className={styles['go-back-text']}>{words['back']}</span>
            </button>
          </div>
        </section>
        <section className={styles.details}>
          <div className={styles['details-image']}>
            <div className={styles.people}>
              <span>{good.numberOfPeople && good.numberOfPeople}</span>
              <i className={styles.people_icon}>
                <PeopleIcon />
              </i>
            </div>
            <div className={styles.price}>
              <span>{good.price && good.price}</span>
              <i className={styles.logo}>
                <Logo />
              </i>
            </div>
            <img src={good.originalPhoto && good.originalPhoto} alt="good_photo" />
          </div>
          <div className={styles['details-description']}>
            <h3>{good.name && good.name}</h3>

            <div className={styles['details-description-item']}>
              <span>{`${words['user.pointsSystem.market.table.category']}:`}</span>
              {/*TODO -- add category when back will give it*/}
              <div className={styles['details-description-text']}>
                {good.categoryId && good.categoryId.name}
              </div>
            </div>

            <div className={styles['details-description-item']}>
              <span>{`${words['admin.productionCalendar.new.description']}:`}</span>
              <br />
              <div className={styles['details-description-text']}>
                {good.description && good.description}
              </div>
            </div>

            <div className={styles['details-btn-container']}>
              <div className={styles['basket-counter-wrapper']}>
                <button
                  onClick={() => {
                    if (!basketCount || basketCount === 1 || typeof basketCount !== 'number') {
                      setBasketCount(1)
                    } else {
                      setBasketCount(Number(basketCount) - 1)
                    }
                  }}
                  type="button"
                >
                  <Icons icon="remove" />
                </button>
                <div className={styles['basket-counter']}>{basketCount}</div>
                <div className={styles['input-basket-counter-wrapper']}>
                  <Input name="basketCounter" />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    if (!basketCount || typeof basketCount !== 'number') {
                      setBasketCount(1)
                    } else {
                      setBasketCount(Number(basketCount) + 1)
                    }
                  }}
                >
                  <Icons icon="add" />
                </button>
              </div>

              <Button
                type="button"
                className={styles['basket-btn']}
                onClick={addToBasket({
                  id: good.id,
                  name: good.name,
                  quantity: basketCount,
                  category: good.category,
                  price: good.price,
                  photo: good.croppedPhoto,
                  addType: 'page'
                })}
              >
                {words['user.pointsSystem.market.table.inBasket']}
              </Button>
            </div>
          </div>
        </section>
        <Reviews
          reviews={good && good.reviews}
          isRemovable={false}
          isCreatable={true}
          onRemove={onRemove}
          onSubmit={handlerSubmitReview}
        />
      </div>
    </>
  )
}
