import React, { FC, useEffect } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'

import styles from './DeactivateUserModal.module.scss'
import Modal from 'components/Modal'
import { Button } from 'components/UiKit/Button'
import { TDeactivateUserModalProps } from './DeactivateUserModal.model'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import deactivateUserFieldsConfig from './DeactivateUserFieldsConfig'
import { Input } from 'components/UiKit/Inputs'
import moment from 'moment'
import { deleteUser, getTerminationInitiatorOptions } from 'screens/admin/Team/TeamAdmin.thunx'

export const DeactivateUserModal: FC<TDeactivateUserModalProps> = ({
  toggleOpenDeactivateModal,
  userId
}) => {
  const dispatch = useDispatch()
  const words = useSelector((state: TState) => state.global.language.words)
  const openDeactivateModal = useSelector(
    (state: TState) => state.admin.teamAdmin.openDeactivateModal
  )
  const terminationInitiatorOptions = useSelector(
    (state: TState) => state.admin.teamAdmin.options.terminationInitiator
  )
  useEffect(() => {
    if (openDeactivateModal) {
      dispatch(getTerminationInitiatorOptions())
    }
  }, [openDeactivateModal])

  return (
    <Modal
      isShow={openDeactivateModal}
      onClose={toggleOpenDeactivateModal}
      className={styles.modal}
    >
      <Form
        onSubmit={(values: { [key: string]: any }) => {
          values.terminationDate = moment(values.terminationDate).format('YYYY-MM-DD')
          values.id = userId
          values.blackList = values.blackList || false
          dispatch(deleteUser(values))
        }}
      >
        {({ form, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.form}>
              <header>{words['admin.team.deactivate']}</header>

              <FieldFactory
                form={form}
                config={deactivateUserFieldsConfig({ words, terminationInitiatorOptions })}
                words={words}
              />

              <div className={styles['form-row-btn']}>
                <Input
                  type="checkbox"
                  name="blackList"
                  label={words['admin.team.blackList']}
                  onChange={(value: any) => form.change('blackList', value.target.checked)}
                />
                <Button type="submit" className={styles['div-btn-wrapper']}>
                  {words['admin.team.deactivate']}
                </Button>
              </div>
            </form>
          )
        }}
      </Form>
    </Modal>
  )
}
