import { TWords } from 'elements/SideBar/SideBar.config'
import styles from '../../EditProfile.module.scss'
import { TFieldConfigRow } from 'components/UiKit/FieldFactory/FieldFactory.model'
import { config } from 'globalConfigs'
import { TSelectOption } from '../../EditProfile.model'

type EditCareerInfoConfigOptions = {
  englishLevel: TSelectOption
  department: TSelectOption
  typeOfEmployment: TSelectOption
}

type EditCareerInfoConfigProps = {
  options: EditCareerInfoConfigOptions
  isAdmin: boolean
}

export const editCareerInfoConfig = (
  words: TWords,
  props: EditCareerInfoConfigProps
): TFieldConfigRow[] => {
  const { options, isAdmin } = props
  const englishLevel = options.englishLevel
  const department = options.department
  const typeOfEmployment = options.typeOfEmployment

  const row1: TFieldConfigRow = {
    items: [
      {
        name: 'technologies',
        label: words['user.profile.career.technologies'],
        required: true,
        component: () => {
          return {
            type: 'textarea',
            props: {}
          }
        }
      }
    ]
  }

  const row2: TFieldConfigRow = {
    items: [
      {
        name: 'careerGoal',
        label: words['user.profile.career.goals'],
        required: true,
        component: () => {
          return {
            type: 'textarea',
            props: {}
          }
        }
      }
    ]
  }

  const row3: TFieldConfigRow = {
    items: [
      {
        name: 'englishLevel',
        label: words['user.profile.career.englishLevel'],
        required: true,
        inputWrapperClassName: styles['english-level'],
        component: () => {
          return {
            type: 'select',
            props: {
              placeholder: '',
              options: englishLevel || []
            }
          }
        }
      },
      {
        name: 'previousJob',
        label: words['user.profile.career.previousJob'],
        required: true,
        inputWrapperClassName: styles['previous-job'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined'
            }
          }
        }
      }
    ]
  }

  if (isAdmin)
    row3.style = {
      marginBottom: '32px'
    }

  const row5: any = {
    style: {
      justifyContent: 'space-between',
      marginBottom: '32px'
    },
    items: [
      {
        name: 'department',
        label: words['user.profile.career.department'],
        required: true,
        inputWrapperClassName: styles['department'],
        component: () => {
          return {
            type: 'select',
            props: {
              placeholder: '',
              options: department || []
            }
          }
        }
      },
      {
        name: 'salary',
        label: words['user.profile.career.salary'],
        required: true,
        inputWrapperClassName: styles['salary'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined',
              type: 'number'
            }
          }
        }
      },
      {
        name: 'salaryChangeDate',
        label: words['user.profile.career.salaryChangeDate'],
        required: true,
        inputWrapperClassName: styles['salary-change-date'],
        component: ({ input }: any) => {
          return {
            type: 'datePicker',
            props: {
              autoComplete: 'off',
              dateFormat: config.dateFormat,
              selected: input.value ? new Date(input.value) : null
            }
          }
        }
      }
    ]
  }

  const row6: any = {
    style: {
      justifyContent: 'space-between',
      marginBottom: '32px'
    },
    items: [
      {
        name: 'typeOfEmployment',
        label: words['user.profile.career.occupation'],
        required: true,
        inputWrapperClassName: styles['english-level'],
        component: () => {
          return {
            type: 'select',
            props: {
              placeholder: '',
              options: typeOfEmployment || []
            }
          }
        }
      },
      {
        name: 'workingHoursPerDay',
        label: words['user.profile.career.workingHours'],
        required: true,
        inputWrapperClassName: styles['working-hours-per-day'],
        component: () => {
          return {
            type: 'input',
            props: {
              variant: 'outlined',
              type: 'number'
            }
          }
        }
      },
      {
        name: 'firstWorkingDay',
        label: words['user.profile.career.hiringDate'],
        required: true,
        inputWrapperClassName: styles['first-working-day'],
        component: ({ input }: any) => {
          return {
            type: 'datePicker',
            props: {
              autoComplete: 'off',
              dateFormat: config.dateFormat,
              selected: input.value ? new Date(input.value) : null
            }
          }
        }
      },
      {
        name: 'probationEndDate',
        label: words['user.profile.career.probationEndDate'],
        required: true,
        inputWrapperClassName: styles['probation-end-date'],
        component: ({ input }: any) => {
          return {
            type: 'datePicker',
            props: {
              autoComplete: 'off',
              dateFormat: config.dateFormat,
              selected: input.value ? new Date(input.value) : null
            }
          }
        }
      }
    ]
  }

  if (isAdmin) {
    return [row5, row6, row3, row1, row2]
  }
  return [row1, row2, row3]
}
