import { action } from 'store/store.utils'
import { AxiosError } from 'axios'
import {
  EDIT_USER_ERROR,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  GET_POSITIONS
} from './EditProfile.constants'
import { TUpdateUser } from './EditProfile.model'

export const updateUserRequest = (): TUpdateUser => action(EDIT_USER_REQUEST)

export const updateUserSuccess = (): TUpdateUser => action(EDIT_USER_SUCCESS)

export const updateUserError = (payload: AxiosError): TUpdateUser =>
  action(EDIT_USER_ERROR, payload)

export const getPositions = (payload: any): TUpdateUser => action(GET_POSITIONS, payload)
