import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import App from './elements/App'
import { store, persistor } from './store'
import history from './utils/history'
import './assets/style/_global.scss'
import 'toastr/build/toastr.min.css'
import './utils/locale/i18n'
import Spinner from './components/Spinner'
import uk from 'date-fns/locale/uk'
import ru from 'date-fns/locale/ru'
import { registerLocale } from 'react-datepicker'

registerLocale('ua', uk) // Need for datepicker
registerLocale('ru', ru) // Need for datepicker

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={
        <div style={{ margin: '75px auto' }}>
          <Spinner />
        </div>
      }
      persistor={persistor}
    >
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
