import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { TState } from 'store'
import { TeamAdmin as TeamAdminComponent } from './TeamAdmin'
import {
  getSelectOptionsForTeamAdmin,
  getTeamAdminList,
  getTerminationInitiatorOptions
} from './TeamAdmin.thunx'
import {
  clearTeamAdminData,
  setCheckedItems,
  setCurrentTab,
  setHiddenColumns
} from './TeamAdmin.actions'

export const mapStateToProps = (state: TState) => ({
  data: state.admin.teamAdmin.results,
  total: state.admin.teamAdmin.total,
  loading: state.admin.teamAdmin.loading,
  hiddenColumns: state.admin.teamAdmin.hiddenColumns,
  currentTab: state.admin.teamAdmin.currentTab,
  checkedItems: state.admin.teamAdmin.checkedItems,
  positions: state.admin.teamAdmin.options.positions,
  rank: state.admin.teamAdmin.options.rank,
  maritalStatus: state.admin.teamAdmin.options.maritalStatus,
  englishLevel: state.admin.teamAdmin.options.englishLevel,
  department: state.admin.teamAdmin.options.department,
  typeOfEmployment: state.admin.teamAdmin.options.typeOfEmployment,
  terminationInitiator: state.admin.teamAdmin.options.terminationInitiator,
  displayAllFields: state.client.filter.multilineFilterFields.status
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTeamAdminList,
      getSelectOptionsForTeamAdmin,
      getTerminationInitiatorOptions,
      setHiddenColumns,
      setCurrentTab,
      setCheckedItems,
      clearTeamAdminData
    },
    dispatch
  )

export const TeamAdmin = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamAdminComponent)
